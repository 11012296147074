import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_NOTE } from 'graphql/queries';
import { READ_PATIENT_NOTE } from 'graphql/mutations';

import { loadingVar } from 'graphql/cache';
import ViewNote from 'pages/NoteDetail';

export default () => {
  const { id } = useParams();
  const { data: { note } = {}, loading } = useQuery(FETCH_NOTE, {
    variables: { id },
  });
  const [readNote] = useMutation(READ_PATIENT_NOTE, {
    variables: { note: id },
  });

  useEffect(() => {
    if (note && !note.isDraft && !note.isSeen) {
      readNote();
    }
  }, [note]);

  loadingVar(loading);

  if (loading) {
    return null;
  }

  return <ViewNote note={note} />;
};
