import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  primary: ({ theme }) => `
    color: ${theme.colors.primary};
    font-weight: ${theme.fontWeights.bold};
  `,
  secondaryColor: ({ theme }) => `
    color: ${theme.colors.secondary};
  `,
  secondary: ({ theme }) => `
    color: ${theme.colors.textSecondary};
  `,
  info: ({ theme }) => `
    color: ${theme.colors.info};
  `,
  success: ({ theme }) => `
    color: ${theme.colors.success};
  `,
  danger: ({ theme }) => `
    color: ${theme.colors.danger};
  `,
  warning_2: ({ theme }) => `
    color: ${theme.colors.warning_2};
  `,
  bold: ({ theme }) => `
    font-weight: ${theme.fontWeights.bold};
  `,
  inline: ({ theme }) => `
    display: inline-block;
  `,
  noPadding: ({ theme }) => `
    padding: 0;
  `,
  noMargin: () => `
    margin: 0;
  `,
  block: ({ theme }) => `
    display: block;
    margin: ${px2rem(5)};
    padding: 0;
  `,
  white: ({ theme }) => `
    color: ${theme.colors.white};
  `,
  orange: ({ theme }) => `
    color: ${theme.colors.orange};
  `,
  xSmall: ({ theme }) => `
    font-size: ${px2rem(12)};
  `,
  small: ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeSmall};
  `,
  medium: ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeMedium};
  `,
  large: ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeLarge};
  `,
  xLarge: ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeXLarge};
  `,
  center: () => `
    text-align: center;
  `,
  start: () => `
    text-align: left;
  `,
  capitalize: () => `
    text-transform: capitalize;
  `,
  preserveLineBreak: () => `
    white-space: pre-line;
  `,
  light: ({ theme }) => `
    font-weight: ${theme.fontWeights.light};
  `,
  mediumWeight: ({ theme }) => `
    font-weight: ${theme.fontWeights.medium};
  `,
  secondaryFont: ({ theme }) => `
    font-family: ${theme.fonts.secondary} !important;
  `,
  topPadding: ({ theme }) => `
    padding-top: ${theme.dimensions.paragraphPadding};
  `,
  muted: ({ theme }) => `
    color: ${theme.colors.gray5};
  `,
  underline: () => `
    text-decoration: underline;
  `,
  right: () => `
    text-align: right;
    justify-content: flex-end;
  `,
  keepWord: () => `
    word-break: normal;
  `,
  pageTitle: ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeLarge};
  `,
  heavy: ({ theme }) => `
    font-weight: ${theme.fontWeights.bold};
  `,
  h2: ({ theme }) => `
    font-weight: ${theme.fontWeights.semiBold};
    font-size: ${theme.dimensions.fontSizeMedium};
  `,
  h3: ({ theme }) => `
    font-weight: ${theme.fontWeights.medium};
    font-size: ${theme.dimensions.fontSizeMedium};
    color: ${theme.colors.text};
  `,
  body: ({ theme }) => `
    font-weight: ${theme.fontWeights.normal};
    color: ${theme.colors.text};
    font-size: ${theme.dimensions.fontSizeNormal};
  `,
  subtitle: ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeNormal};
    font-weight: ${theme.fontWeights.medium};
  `,
  semiBold: ({ theme }) => `
    font-weight: ${theme.fontWeights.semiBold} !important;
  `,
  darkGrey: ({ theme }) => `
    color: ${theme.colors.darkGrey};
  `,
};

const styles = ({ theme }) => `
  padding: 0 ${px2rem(5)};
  color: ${theme.colors.text};
  word-break: break-all;
  font-size: ${theme.dimensions.fontSize};
`;

export const Text = buildStyledComponent('Text', styled.span, styles, {
  modifierConfig,
});

export default Text;
