import styled from 'styled-components';

import { Row } from '@gaz/gaz-components.public';
import { buildStyledComponent, px2rem } from 'style';

export const FOOTER_HEIGHT = px2rem(60);

const styles = ({ theme }) => `
  background-color: ${theme.colors.white};
  max-height: ${FOOTER_HEIGHT};
  box-shadow: 0px -1px 2px rgba(46, 41, 78, 0.02), 0px -4px 8px rgba(46, 41, 78, 0.08);
`;

export default buildStyledComponent('MainLayout_Footer', styled(Row), styles);
