import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'style';

const styles = ({ theme, width = 32, height = 32 }) => `
  width: ${px2rem(width)};
  height: ${px2rem(height)};
  border-radius: ${px2rem(8)};
  background: #F3F3F4;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconWrapper = buildStyledComponent(
  'IconWrapper',
  styled.div,
  styles,
);
