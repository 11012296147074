import React from 'react';

import Checkbox from 'common/Checkbox';

import Label from '../FormLabel';
import Error from '../FormError';

export const FormCheckbox = (props) => {
  const {
    field: { name, value },
    form: { errors, touched, setFieldValue },
    label,
    dark,
    required,
    ...restProps
  } = props;

  const hasError = errors[name] && touched[name];

  return (
    <div>
      <Checkbox
        dark={dark}
        hasError={hasError}
        label={
          <Label modifiers={[dark && 'dark']}>
            {label} {required && <span>*</span>}
          </Label>
        }
        checked={value}
        onChange={() => setFieldValue(name, !value)}
        {...restProps}
      />

      {hasError && <Error>{errors[name]}</Error>}
    </div>
  );
};

export default FormCheckbox;
