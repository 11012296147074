import React, { createContext, useContext } from 'react';

import { AuthContext } from '../auth';
import useAppointments from './appointments';
import useCareplan from './careplan';
import useProgress from './progress';
import useResponse from './response';
import useUnreadItems from './unreadItems';

export const PatientContext = createContext();

export const PatientContextProvider = ({ children }) => {
  const { me } = useContext(AuthContext);
  const { appointments, loadAppointments } = useAppointments();
  const { tasks, updateTasks, careplan, isCPExpired, loadTasks } =
    useCareplan();
  const { progress, fetchProgress } = useProgress();
  const { responses, addResponse, fetchResponses } = useResponse();
  const {
    inviteCount,
    reportCount,
    noteCount,
    careplanCount,
    coordinateCount,
    alertCount,
    callCount,
    prescribeCount,
  } = useUnreadItems();

  return (
    <PatientContext.Provider
      value={{
        patient: me,
        appointments: appointments,
        loadAppointments,
        tasks,
        loadTasks,
        updateTasks,
        careplan,
        isCPExpired,
        progress,
        fetchProgress,
        responses,
        fetchResponses,
        addResponse,
        inviteCount,
        reportCount,
        noteCount,
        careplanCount,
        coordinateCount,
        alertCount,
        callCount,
        prescribeCount,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};

export default PatientContextProvider;
