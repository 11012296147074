import { useMemo } from 'react';

import { PROGRESS_ITEMS } from 'utils/constants';
import MeasureDetail from './MeasureDetail';
import { getItemContent } from './ListItem';
import { filterCareplanSchedulesByItemTime } from 'utils/careplan';
import { toLocalTime } from 'utils/time';

const Physiologics = ({ responses, careplans }) => {
  const responseData = useMemo(() => {
    const vitalData = {
      icon: PROGRESS_ITEMS['vital'].icon,
      title: 'Vitals',
      positiveTitle: 'Positive',
      readings: 0,
      positivePct: 'N/A',
      responses: [],
    };
    // responses;
    let totals = 0,
      positives = 0;
    responses?.forEach((dailyResponse) => {
      let answered = false;
      const careplan = careplans[dailyResponse.careplan];
      if (!careplan) {
        return;
      }
      const cpContent = filterCareplanSchedulesByItemTime(
        careplan,
        ['vital'],
        toLocalTime(dailyResponse.date)
      );
      dailyResponse.responses?.forEach((response) => {
        if (response.measure !== 'vital') {
          return;
        }
        if (response.isPositive === true || response.isPositive === false) {
          answered = true;
        }
        totals++;
        if (response.isPositive === true) {
          positives++;
        }
        const responseContent = {
          ...response,
          content: getItemContent(cpContent['vital'], response),
        };
        vitalData.responses.push(responseContent);
      });

      if (answered) {
        vitalData.readings++;
      }
    });

    if (totals > 0) {
      vitalData.positivePct = Math.round(((positives || 0) / totals) * 100);
    }

    return vitalData;
  }, [responses]);

  return <MeasureDetail data={responseData} />;
};

export default Physiologics;
