import logoLight from './logo_light.png';
import mainLogo from './main_logo.png';
import imgBigLogo from './ImgBigLogo.png';
import imgBrandTitle from './BrandTitle.png';

import samplePatient1 from './sample_patient1.jpg';
import patientAndDoc from './patient_and_doc.png';
import emptyAvatar from './EmptyAvatar.svg';

import bloodPressure from './blood_pressure.svg';
import bloodPressure2 from './blood_pressure2.svg';

import weightScale from './weight_scale.svg';
import weightScale2 from './weight_scale2.svg';

const images = {
  logoLight,
  samplePatient1,
  bloodPressure,
  bloodPressure2,
  weightScale,
  weightScale2,
  mainLogo,
  patientAndDoc,
  emptyAvatar,
  imgBigLogo,
  imgBrandTitle,
};

export default images;
