import moment from 'moment';
import {
  hourToDate,
  toGMT,
  getStartDate,
  toLocalTime,
  displayTime,
} from './time';

export const filterCareplanSchedulesByItemTime = (
  careplan,
  itemTypes,
  taskDate
) => {
  if (!careplan?.content) {
    return [];
  }
  const content = careplan ? JSON.parse(JSON.stringify(careplan.content)) : {};
  const measureItems = itemTypes || [
    'medication',
    'vital',
    'activity',
    'diet',
    'wellness',
  ];
  measureItems.forEach((key) => {
    const item = content[key];
    if (!item) {
      return;
    }
    if (item.frequency) {
      item.hours = scheduleTimesFromFrequency(
        item.frequency,
        careplan,
        taskDate
      );
    } else {
      Object.values(item).forEach((subItem) => {
        subItem.hours = scheduleTimesFromFrequency(
          subItem.modification?.frequency || subItem.frequency,
          careplan,
          taskDate
        );
      });
    }
  });
  const filteredItems = {};
  Object.entries(content).forEach(([key, value]) => {
    if (!value) {
      return;
    }
    const measureItems = {};
    let items = [];
    if (key === 'activity') {
      items = [value];
    } else {
      items = value;
    }
    Object.entries(items).forEach(([index, item]) => {
      if (item.hours?.length > 0) {
        item.hours.forEach((hour) => {
          if (!measureItems[hour]) {
            measureItems[hour] = [];
          }
          measureItems[hour].push({
            id: index,
            ...item,
          });
        });
      }
    });
    const sortedResult = {};
    Object.keys(measureItems)
      .sort((a, b) => hourToDate(a) - hourToDate(b))
      .forEach((hour) => {
        sortedResult[hour] = measureItems[hour];
      });
    filteredItems[key] = sortedResult;
  });
  return filteredItems;
};

export const scheduleTimesFromFrequency = (frequency, careplan, taskDate) => {
  let times = [];
  const date = taskDate || new moment();
  if (frequency?.type === 'preset') {
    times = frequency.value.hours;
  } else if (frequency?.type === 'custom') {
    const value = frequency.value;
    if (!value) {
      return [];
    }
    if (value.frequency === 'Weekly') {
      const careplanStartDate = toLocalTime(careplan.startDate);
      if (date.isBefore(careplanStartDate)) {
        return [];
      }
      const startDateWeekDay = careplanStartDate.weekday();
      const weekDay = date.weekday();
      const daysDiff = date.diff(careplanStartDate, 'days');
      let weekDiff;
      if (startDateWeekDay <= weekDay) {
        weekDiff = Math.floor(daysDiff / 7);
      } else {
        weekDiff = Math.floor(daysDiff / 7) + 1;
      }
      if (weekDiff % value.everyWeeks !== 0) {
        return [];
      }
      if (value.weekDays.includes(weekDay)) {
        return value.weeklyTimes;
      }
    } else {
      const scheduleStartDate = toLocalTime(value.startDate, 'object', true);
      if (date.isBefore(scheduleStartDate)) {
        return [];
      }
      const daysDiff = date.diff(scheduleStartDate, 'days');
      if (daysDiff > 0 && daysDiff % parseInt(value.everyDays, 10) === 0) {
        return value.dailyTimes;
      }
    }
    return [];
  }
  return times.map((time) =>
    (time.length === 7 ? `0${time}` : time).toUpperCase()
  );
};

export const getMeasureProgress = (progress, measureType) => {
  const values = progress[measureType];
  if (!values) {
    return null;
  }
  if (values.totalCount >= 0) {
    if (values.totalCount === 0) {
      return null;
    }
    return (100.0 * values.positiveCount) / values.totalCount;
  }
  let total = 0,
    positives = 0;
  Object.values(values).map((subValue) => {
    total += subValue.totalCount;
    positives += subValue.positiveCount;
  });
  if (total === 0) {
    return null;
  }
  return (100.0 * positives) / total;
};

export const filterPeriodResponses = (responses, period, date = new Date()) => {
  if (!responses) {
    return [];
  }
  let startDate, endDate;
  switch (period) {
    case 0:
      startDate = toGMT(date, true, true);
      endDate = moment(startDate).add(1, 'day');
      break;
    case 1:
    case 'w':
      startDate = toGMT(getStartDate('w', date), true, true);
      endDate = moment(startDate).add(7, 'days');
      break;
    case 2:
    case 'm':
      startDate = toGMT(getStartDate('m', date), true, true);
      endDate = moment(startDate).add(1, 'month');
      break;
    case 3:
    case 'y':
      startDate = toGMT(getStartDate('y', date), true, true);
      endDate = moment(startDate).add(1, 'year');
      break;
  }
  return responses.filter((response) => {
    const responseStamp = new Date(response.date || response.time).getTime();
    return (
      responseStamp >= startDate.unix() * 1000 &&
      responseStamp < endDate.unix() * 1000
    );
  });
};

export const hasTaskResponse = (responses, time, type, subtype) => {
  if (!responses || responses.length === 0) {
    return false;
  }
  const hour =
    typeof time === 'string'
      ? new Date(hourToDate(time)).getTime() / 1000
      : time;
  for (let response of responses) {
    if (
      !response.response ||
      response.measure !== type ||
      toLocalTime(response.time).unix() !== hour
    ) {
      continue;
    }
    if (subtype && response.response.type !== subtype) {
      continue;
    }
    if (
      response.response.didTake === true ||
      response.response.didTake === false
    ) {
      return true;
    }
    if (parseInt(response.response.value, 10) > 0) {
      return true;
    }
  }
  return false;
};
