import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import assets from 'assets';
import { useHistory } from 'react-router';
import qs from 'query-string';
import urls from 'routes/urls';

import {
  Container,
  Row,
  Column,
  Avatar,
  Text,
  ContentCard,
} from '@gaz/gaz-components.public';
import { buildStyledComponent, px2rem } from 'style';

const wrapperStyles = ({ theme }) => `
  background-color: ${theme.colors.white};
  border-radius: ${px2rem(8)};
  box-shadow: ${theme.dimensions.boxShadow_1};
`;

const Wrapper = buildStyledComponent(
  'Messaging_LastMessage_Wrapper',
  styled(Container),
  wrapperStyles
);

export default function LastMessage({ contact = {}, message }) {
  const history = useHistory();

  const handleClick = () => {
    history.push({
      pathname: urls.CHAT,
      search: qs.stringify({
        memberIds: [contact._id],
      }),
    });
  };
  return (
    <ContentCard modifiers={['withGutters']} onClick={handleClick}>
      <Row>
        <Column modifiers={['col_2']}>
          <Avatar
            modifiers={['round']}
            image={assets.images.emptyAvatar}
            width={42}
            height={42}
          />
        </Column>
        <Column modifiers={['col_10', 'fluid', 'noVerticalPadding']}>
          <Row>
            <Column modifiers={['col_5']}>
              <Text modifiers={['subtitle', 'mediumWeight', 'darkGrey']}>
                Dr. David Lowes
              </Text>
            </Column>
            <Column modifiers={['col_4', 'col_offset_3']}>
              <Text modifiers={['small', 'darkGrey']}>
                {moment().format('hh:mm a')}
              </Text>
            </Column>

            <Column style={{ paddingTop: 0 }} modifiers={['col_12']}>
              <Text modifiers={['small', 'darkGrey']}>
                Eiusmod ipsum anim sunt nisi consectetur cupidatat velit eu
                aliquip.
              </Text>
            </Column>
          </Row>
        </Column>
      </Row>
      <Row></Row>
    </ContentCard>
  );
}
