import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const styles = () => `
  flex: 1;
  margin: 0 ${px2rem(10)};
  text-align: center;
`;

const Center = buildStyledComponent(
  'MainLayout_Header_Center',
  styled.div,
  styles,
);

export default Center;
