import { useState } from 'react';

import {
  Button,
  Row,
  Column,
  Text,
  ScrollView,
} from '@gaz/gaz-components.public';
import { Container, PeriodPicker } from 'common';
import { AlertsCategoryItem } from './Item';
import Book from './BookAppointment';
import assets from 'assets';

export default ({
  handleBook,
  handleCategoryClick,
  categories,
  goBack,
  me,
  period,
  setPeriod,
}) => {
  const [showBookModal, setShowBookModal] = useState(false);

  return (
    <>
      <Container
        modifiers={['fluid', 'fullHeight', 'flexBox', 'backgroundWhite']}
      >
        <Container modifiers={['backgroundGray']}>
          <Row modifiers={['spaceBetween', 'middle']}>
            <Text modifiers={['large', 'bold']}>Alerts</Text>
            <Column>
              <PeriodPicker value={period} handleSelect={setPeriod} />
            </Column>
          </Row>
        </Container>
        <ScrollView>
          <Container>
            {categories.map((category) => (
              <AlertsCategoryItem
                category={category}
                handleClick={() => handleCategoryClick(category)}
              />
            ))}
          </Container>
        </ScrollView>
        <Container modifiers={['flexFixed', 'footer']}>
          <Row modifiers={['spaceBetween']}>
            <Button
              modifiers={['icon', 'transparent']}
              image={assets.icons.icCloseGray}
              onClick={goBack}
            />
            <Button
              modifiers={['primary', 'roundCorner']}
              onClick={() => setShowBookModal(true)}
            >
              Book Appointment
            </Button>
          </Row>
        </Container>
      </Container>
      {showBookModal && (
        <Book
          patient={me}
          handleClose={() => setShowBookModal(false)}
          handleDone={handleBook}
        />
      )}
    </>
  );
};
