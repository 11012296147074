import styled from 'styled-components';
import { buildStyledComponent } from 'style';

const modifierConfig = {
  shown: () => `
    display: block;
  `,
};

const styles = () => `
  background: rgba(0, 0, 0, 0.5);;
  width: 100%;
  height: 100%;
  z-index: 98;
  display: none;
  position: absolute;
`;

export const Overlay = buildStyledComponent(
  'SideMenu_Overlay',
  styled.div,
  styles,
  { modifierConfig }
);
