import styled from 'styled-components';
import { buildStyledComponent } from 'style';

const styles = () => `
  width: 80px;
  height: 36px;
  padding: 8px;
  background: rgba(0, 64, 137, 0.7);
  backdrop-filter: blur(6px);
  border-radius: 8px 0px 0px 8px;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 10000;
`;

export default buildStyledComponent('OngoingCall_Wrapper', styled.div, styles);
