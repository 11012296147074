import React from 'react';

import Wrapper from './Wrapper';

export function Checkbox({
  checked,
  dark,
  disabled = false,
  hasError,
  id,
  label,
  onChange,
  onClick,
  readOnly = false,
  ...rest
}) {
  return (
    <Wrapper
      modifiers={[
        disabled && 'disabled',
        readOnly && 'readOnly',
        dark && 'dark',
        hasError && 'hasError',
      ]}
    >
      <Wrapper.Input
        {...rest}
        checked={checked}
        disabled={disabled}
        id={id}
        onChange={!disabled && !readOnly && onChange}
        onClick={!disabled && !readOnly && onClick}
        readOnly={readOnly}
      />
      <Wrapper.Label htmlFor={id}>
        {label && <Wrapper.Text>{label}</Wrapper.Text>}
      </Wrapper.Label>
    </Wrapper>
  );
}

export default Checkbox;
