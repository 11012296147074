import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import { MainLayout } from 'layouts';
import { BackButton, Text, Row, Column } from '@gaz/gaz-components.public';
import { Container, Avatar, Tabs } from 'common';

import RecordsProvider from './Context';
import Notes from './Notes';
import NoteDetails from './Notes/NoteDetails';
import Diagnosis from './Diagnosis';
import CarePlans from './CarePlans';
import CarePlan from './CarePlans/CareplanDetail';
import TitleWrapper from './TitleWrapper';
import Medications from './Medications';
import OrderDetail from './Medications/Detail';
import { AuthContext } from 'contexts/auth';
import { getFullName } from 'utils/string';

const { Header, Content } = MainLayout;

const PATHS = ['/note', '/diagnosis', '/care-plans', '/medications'];

export default () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [tab, setTab] = useState(() => {
    for (let i = 0; i < PATHS.length; i++) {
      if (history.location.pathname.startsWith(`/records${PATHS[i]}`)) {
        return i;
      }
    }
    return null;
  });
  const { me } = useContext(AuthContext);

  const updateTab = (tab) => {
    setTab(tab);
    const route = getPathByTab(tab);
    history.push(route);
  };

  useEffect(() => {
    if (tab === null) {
      updateTab(0);
    }
  }, []);

  const getPathByTab = useCallback(
    (tab) => {
      return `${path}${PATHS[tab]}`;
    },
    [tab, path]
  );

  const goBack = () => {
    const pathName = history.location.pathname;
    const urlParts = pathName.split('/');
    urlParts.pop();
    const newUrl = urlParts.join('/');
    if (newUrl === '/records' || newUrl === '/records/') {
      history.replace('/');
    } else {
      history.replace(urlParts.join('/'));
    }
  };

  const isShowingItem = () => {
    const pathName = history.location.pathname;
    const urlParts = pathName.split('/');
    urlParts.pop();
    const newUrl = urlParts.join('/');
    return !(newUrl === '/records' || newUrl === '/records/');
  };

  if (!me) {
    return null;
  }

  return (
    <RecordsProvider>
      <MainLayout>
        <Header>
          <Header.Left>
            <BackButton onClick={goBack} />
          </Header.Left>
          <Header.Center>
            <Row modifiers={['middle']}>
              <Avatar
                user={me}
                width={40}
                height={40}
                modifiers={['squared']}
              />
              <Column modifiers={['col_8', 'flexBox']}>
                <Text modifiers={['semiBold', 'start']}>{getFullName(me)}</Text>
              </Column>
            </Row>
          </Header.Center>
        </Header>
        <Content>
          <Container modifiers={['fluid', 'fullHeight', 'flexBox']}>
            <TitleWrapper modifiers={[isShowingItem() && 'noDisplay']}>
              <Container>
                <Row modifiers={['withGutters']}>
                  <Text modifiers={['medium', 'bold', 'darkGrey']}>
                    Records
                  </Text>
                </Row>
              </Container>
              <Tabs current={tab} onChange={updateTab} flatTabs>
                <Tabs.Tab title="Notes" />
                <Tabs.Tab title="Diagnosis" />
                <Tabs.Tab title="Care Plans" />
                <Tabs.Tab title="Medications" />
              </Tabs>
            </TitleWrapper>
            <Container
              modifiers={['backgroundWhite', 'fullHeight', 'fluid', 'flexBox']}
            >
              <Switch>
                <Route exact path={`${path}/note`} component={Notes} />
                <Route
                  exact
                  path={`${path}/note/:id`}
                  component={NoteDetails}
                />
                <Route exact path={`${path}/diagnosis`} component={Diagnosis} />
                <Route
                  exact
                  path={`${path}/care-plans`}
                  component={CarePlans}
                />
                <Route
                  exact
                  path={`${path}/care-plans/:id`}
                  component={CarePlan}
                />
                <Route
                  exact
                  path={`${path}/medications`}
                  component={Medications}
                />
                <Route
                  exact
                  path={`${path}/medications/:id`}
                  component={OrderDetail}
                />
              </Switch>
            </Container>
          </Container>
        </Content>
      </MainLayout>
    </RecordsProvider>
  );
};
