import React from 'react';

import Wrapper from './Wrapper';
import { Container, Row } from '@gaz/gaz-components.public';

export const DefaultLayout = ({ title, children }) => {
  return (
    <Wrapper>
      <Container>
        <Row modifiers={['center']}>
          <h2>{title}</h2>
        </Row>
      </Container>
      <Container>{children}</Container>
    </Wrapper>
  );
};

export default DefaultLayout;
