import styled from 'styled-components';

import { buildStyledComponent } from 'style';
import fonts from 'assets/fonts';
import Notifications from './Notifications';
import { DESKTOP_VIEWPORT_WIDTH } from 'utils/constants';

const styles = ({ theme }) => `
  // @font-face {
  //   font-family: 'Gotham-Book';
  //   src: url(${fonts.GothamBookEot}) format('embedded-opentype'), url(${fonts.GothamBookWoff}) format('woff'),
  //     url(${fonts.GothamBookWoff2}) format('woff2'), url(${fonts.GothamBookTtf}) format('truetype'), url(${fonts.GothamBookSvg}) format('svg');
  //   font-weight: normal;
  //   font-style: normal;
  // }

  @font-face {
    font-family: 'Inter';
    src: url(${fonts.Inter}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  color: ${theme.colors.text};
  font-family: ${theme.fonts.primary};
  font-weight: ${theme.fontWeights.medium};
  height: 100%;
  position: relative;

  @media only screen and (min-width: 769px) {
    max-width: ${DESKTOP_VIEWPORT_WIDTH};
    margin: 0 auto;
  }

  button, input, * {
    font-family: ${theme.fonts.primary};
  }

  * {
    box-sizing: border-box;
  }
`;

const Wrapper = buildStyledComponent('Main', styled.div, styles);
Wrapper.Notifications = Notifications;

export default Wrapper;
