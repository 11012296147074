import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const modifierConfig = {
  hidden: () => `
    display: none;
    transition: 0.3s;
  `,
};

const styles = () => `
  z-index: 9997;
  height: 100%;
  width: 100%;
  background: rgba(59, 76, 106, 0.6);
  backdrop-filter: blur(10px);
  position: absolute;
  left: 0;
  right: 0;
  transition: 0.3s;
`;

export default buildStyledComponent('Overlay_SlidingPane', styled.div, styles, {
  modifierConfig,
});
