import React from 'react';
import { noop } from 'lodash';

import { buildStyledComponent, px2rem } from 'style';

import Wrapper from './Wrapper';
import Overlay from './Overlay';
import Content from './Content';
import Header from './Header';

export const SlidingPane = ({
  isOpen,
  handleClose = noop,
  children,
  ...props
}) => {
  return (
    <>
      <Overlay modifiers={[!isOpen && 'hidden']} onClick={handleClose} />
      <Wrapper modifiers={[isOpen && 'open']}>{children}</Wrapper>
    </>
  );
};

SlidingPane.Content = Content;
SlidingPane.Header = Header;
