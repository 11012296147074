import React from 'react';
import { Button, Text, Avatar, Row, Column } from '@gaz/gaz-components.public';

import assets from 'assets';
import { Badge } from 'common';
import { formatTitleAndName, getFullName } from 'utils/string';

import Wrapper from './Wrapper';

export const InvitationCard = ({
  invite,
  received,
  isAdminGroup,
  onAcceptOrDecline,
  onCancel,
  onRowClick,
}) => {
  let avatar;
  let name;
  let user;

  if (invite.practice) {
    avatar = invite.practice.photo?.url;
    name = invite.practice.name;
  } else if (invite.group) {
    avatar = invite.group.photo?.url;
    name = invite.group.name;
  } else {
    user = received ? invite.inviter : invite.invitee;

    if (user.role === 'provider') {
      name = formatTitleAndName(user);
    } else {
      name = getFullName(user);
    }
  }

  const handleReject = () => {
    if (received) {
      onAcceptOrDecline(invite._id, false);
    } else {
      onCancel(invite._id);
    }
  };

  return (
    <Wrapper modifiers={isAdminGroup && 'fixedGroup'} onClick={onRowClick}>
      <Row>
        <Column modifiers={['col_2']}>
          <Avatar
            image={!user && (avatar || assets.images.emptyAvatar)}
            user={user}
            width={40}
            height={40}
            modifiers={['round']}
          />
        </Column>
        <Column modifiers={['col_5']}>
          <Text>{name}</Text>
        </Column>
        {isAdminGroup && (
          <Column
            modifiers={[
              'col_2',
              'col_offset_3',
              'flexBox',
              'center',
              'verticalContentCenter',
            ]}
          >
            <Badge>{received ? invite.totalReceived : invite.totalSent}</Badge>
          </Column>
        )}
        {!isAdminGroup && (
          <Column modifiers={['col_5', 'flexBox', 'end']}>
            <Column modifiers="col">
              {received && (
                <Button
                  image={assets.icons.icAcceptGreen}
                  imageWidth={40}
                  imageHeight={40}
                  onClick={() => onAcceptOrDecline(invite._id, true)}
                  modifiers={['icon', 'light', 'round', 'transparent']}
                />
              )}
            </Column>
            <Column modifiers="col">
              <Button
                image={assets.icons.icCloseRed}
                imageWidth={40}
                imageHeight={40}
                onClick={handleReject}
                modifiers={['icon', 'light', 'round', 'transparent']}
              />
            </Column>
          </Column>
        )}
      </Row>
    </Wrapper>
  );
};
