import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';

import {
  Text,
  Avatar,
  ContentCard,
  Row,
  Column,
  Button,
} from '@gaz/gaz-components.public';

import { Container } from 'common';
import { AuthContext } from 'contexts/auth';
import { useFab } from 'contexts/fab';
import { MainLayout } from 'layouts';
import urls from 'routes/urls';

import assets from 'assets';

import SectionTitle from './SectionTitle';
import Appointment from './Appointment';
import Tasks from './Tasks';
import Shortcuts from './Shortcuts';
import { useNavMenu } from 'contexts/navMenu';
import { PatientContext } from 'contexts/patient';

const Dashboard = () => {
  const { me } = useContext(AuthContext);
  const { fetchProgress } = useContext(PatientContext);
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const fab = useFab();
  const navMenu = useNavMenu();
  const history = useHistory();

  useEffect(() => {
    fab.hideFab();
    navMenu.hideMenu();
    fetchProgress();
  }, []);

  const goProgress = () => {
    history.push(urls.PROGRESS);
  };

  const goAppointments = () => {
    history.push(urls.APPOINTMENTS);
  };

  return (
    <MainLayout>
      <MainLayout.SideMenu
        closeSideMenu={() => setSideMenuOpen(false)}
        isOpen={sideMenuOpen}
      />
      <MainLayout.Header>
        <MainLayout.Header.Left>
          <MainLayout.Header.MenuButton
            handleClick={() => setSideMenuOpen(true)}
          />
        </MainLayout.Header.Left>
        <MainLayout.Header.Center>
          <Text modifiers={['subtitle', 'semiBold']}>
            {moment().format('dddd, D MMMM YYYY')}
          </Text>
        </MainLayout.Header.Center>
        <MainLayout.Header.Right>
          <Avatar user={me} modifiers={['round']} width={36} height={36} />
        </MainLayout.Header.Right>
      </MainLayout.Header>
      <MainLayout.Content>
        <SectionTitle handleAction={goAppointments}>
          Today's Appointment
        </SectionTitle>
        <Container modifiers={['fluid', 'backgroundWhite']}>
          <Appointment />
        </Container>
        <SectionTitle noExpand>Today's tasks</SectionTitle>
        <Container modifiers={['fluid', 'backgroundWhite']}>
          <Tasks />
        </Container>
        <Container modifiers={['padding_2']}>
          <ContentCard modifiers={['withGutters', 'fluid']}>
            <Row onClick={goProgress}>
              <Column modifiers={['flexBox', 'verticalContentCenter']}>
                <Text modifiers={['darkGrey', 'subtitle', 'semiBold']}>
                  Progress
                </Text>
              </Column>
              <Column
                style={{ marginLeft: 'auto' }}
                modifiers={['flexBox', 'verticalContentCenter']}
              >
                {/* <Text modifiers={['success', 'subtitle', 'semiBold']}>61%</Text> */}
                <Button
                  modifiers={['icon', 'transparent']}
                  image={assets.icons.icGoNextBig}
                  width={32}
                  height={32}
                />
              </Column>
            </Row>
          </ContentCard>
        </Container>
        <Container>
          <Shortcuts />
        </Container>
      </MainLayout.Content>
    </MainLayout>
  );
};

export default Dashboard;
