import { useEffect, useState, useRef, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { startCase } from 'lodash';
import styled from 'styled-components';
import moment from 'moment';

import { Row, Column, Text, HR } from '@gaz/gaz-components.public';
import { buildStyledComponent, px2rem } from 'style';
import { hourToDate, displayTime } from 'utils/time';
import { hasTaskResponse } from 'utils/careplan';
import { Button } from 'common';
import { PatientContext } from 'contexts/patient';

const TaskItem = ({ hour, type }) => {
  const timeoutRef = useRef();
  const [taskDue, updateTaskDue] = useState(false);
  const { responses } = useContext(PatientContext);
  const taskAnswered = hasTaskResponse(responses, hour, type);
  const history = useHistory();
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    const startTime = hourToDate(hour);
    const secondsToStart = startTime.diff(moment(), 'seconds');
    if (secondsToStart <= 0) {
      updateTaskDue(true);
    } else {
      timeoutRef.current = setTimeout(() => {
        updateTaskDue(true);
        timeoutRef.current = null;
      }, secondsToStart * 1000);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [hour]);
  const hourUrlParam = useMemo(() => {
    return displayTime(hourToDate(hour), 'HHmm');
  }, [hour]);

  return (
    <Button
      onClick={() => history.push(`/tasks/${type}/${hourUrlParam}`)}
      modifiers={[
        taskAnswered ? '' : taskDue ? 'success' : 'outlineSecondary',
        'small',
        'smallText',
      ]}
    >
      {hour}
    </Button>
  );
};

const Measure = ({ taskDomain = 'vital', task, ...props }) => {
  const hours = task ? Object.keys(task) : [];

  return (
    <Row {...props}>
      <Column modifiers={['noPadding', 'flexBox', 'verticalContentCenter']}>
        <Text
          modifiers={['primary', 'subtitle', 'normalWeight', 'paddingLeft']}
        >
          {startCase(taskDomain)}
        </Text>
      </Column>
      <Column modifiers={['fluid', 'col', 'fluid', 'noPadding']}>
        <Row>
          {hours.map((hour, i) => (
            <TaskItem key={i} hour={hour} type={taskDomain} />
          ))}
        </Row>
      </Column>
      {taskDomain !== 'diet' && (
        <Column modifiers={['col_12', 'noVerticalPadding']}>
          <HR style={{ padding: 0 }} modifiers={['colored']} />
        </Column>
      )}
    </Row>
  );
};

export default buildStyledComponent(
  'Measure',
  styled(Measure),
  ({ theme }) => `
    > :first-child {
      padding-left: ${px2rem(10)};
      width: ${px2rem(85)};
    }
    > :nth-child(2) {
      > :first-child > * {
        margin-right: ${px2rem(5)};
        margin-top: ${px2rem(5)};
        font-weight: ${theme.fontWeights.normal};
        padding: ${px2rem(4)} ${px2rem(6)};
        border: none;
        box-shadow: ${theme.dimensions.boxShadow_5};
        border-radius: ${px2rem(4)};
        text-transform: lowercase;
      }
    }
  `
);
