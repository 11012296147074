import styled, { css } from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const styles = ({ theme }) => css`

  & + label::before {
    background-color: ${theme.colors.white};
    border-radius: 50%;
    border: 1px solid ${theme.colors.primary};
    cursor: pointer;
    display: block;
    content: '';
    height: ${px2rem(25)};
    transition: all 200ms ease;
    width: ${px2rem(25)};
  }

  & + label {
    color: ${theme.colors.text};

    &:hover {
      color: ${theme.colors.text};
    }
  }

  &:checked + label::before {
    background-color: ${theme.colors.primary};
  }

  &:checked + label {
    color: ${theme.colors.text};
  }

  &:hover + label::before {
    border: 1px solid ${theme.colors.primary};
  }
`;

const modifierConfig = {
  small: ({ theme }) => `
    & + label::before {
      width: ${px2rem(16)};
      height: ${px2rem(16)};
    }
  `,
  margin_1: () => `
    & + label::before {
      margin-top: ${px2rem(12)};
    }
  `,
};

const Input = buildStyledComponent(
  'Checkbox__Input',
  styled.input.attrs({ type: 'checkbox' }),
  styles,
  { modifierConfig }
);

export default Input;
