import React, { useCallback, useRef } from 'react';
import { noop } from 'lodash';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';
import assets from 'assets';

const itemWrapperStyles = ({ theme }) => `
  padding: 0;
  border-radius: ${theme.dimensions.borderRadius_2};
  box-shadow: ${theme.dimensions.boxShadow_3};
  position: relative;
  display: flex;
  align-items: center;
`;

const ItemWrapper = buildStyledComponent(
  'MediaGrid_ItemWrapper',
  styled.div,
  itemWrapperStyles
);

const mediaTypeIconStyles = ({ theme }) => `
  position: absolute;
  width: ${px2rem(15)};
  height: ${px2rem(15)};
  left: ${px2rem(3)};
  right: 0;
  bottom: 0;
  top: ${px2rem(3)};
`;

const MediaTypeIcon = buildStyledComponent(
  'MessageItem_MediaTypeIcon',
  styled.img,
  mediaTypeIconStyles
);

export function MediaItem({
  mediaType = 'image',
  mediaSrc = '',
  onClickNote = noop,
}) {
  const mediaRef = useRef(null);

  const getMediaTypeIcon = useCallback(() => {
    switch (mediaType) {
      case 'video':
        return assets.icons.videoPlayer;
      case 'note':
        return assets.icons.iconNote;
      default:
        return assets.icons.iconRecord;
    }
  }, [mediaType]);

  const onClickMedia = () => {
    // TODO: Instead of full screen, we might need to create a MediaViewer
    //        which takes type and url props
    if (mediaRef.current) {
      const element = mediaRef.current;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      }
    }
  };

  const renderMediaElement = (mediaType) => {
    switch (mediaType) {
      case 'video':
        return (
          <video
            style={{
              maxWidth: '100%',
              objectFit: 'cover',
              width: '100%',
              height: px2rem(100),
              margin: 'auto',
              borderRadius: px2rem(12),
            }}
          >
            <source src={mediaSrc} />
          </video>
        );
      case 'image':
        return (
          <img
            alt=""
            src={mediaSrc}
            style={{
              maxWidth: '100%',
              objectFit: 'cover',
              width: '100%',
              height: px2rem(100),
              margin: 'auto',
              borderRadius: px2rem(12),
            }}
          />
        );
      case 'note':
        return <div />;
      default:
        return null;
    }
  };

  return (
    <ItemWrapper
      ref={mediaRef}
      // onClick={mediaType !== 'note' ? onClickMedia : onClickNote}
    >
      <MediaTypeIcon src={getMediaTypeIcon()} />
      {renderMediaElement(mediaType)}
    </ItemWrapper>
  );
}
