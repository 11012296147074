import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ScrollView,
  BackButton,
  Button,
  Checkbox,
} from '@gaz/gaz-components.public';
// import { cloneDeep } from 'lodash';

import { FETCH_PAYMENT_METHODS } from 'graphql/queries';
import {
  ADD_PAYMENT_METHOD,
  SET_DEFAULT_PAYMENT_METHOD,
  REMOVE_PAYMENT_METHOD,
} from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';
import { Row, Column, Text, Container, CreditCardInput } from 'common';
import { MainLayout } from 'layouts';

const { Content, Header } = MainLayout;

export default ({}) => {
  const [fetchPaymentMethods] = useLazyQuery(FETCH_PAYMENT_METHODS, {
    fetchPolicy: 'no-cache',
  });
  const [addPaymentMethodApi] = useMutation(ADD_PAYMENT_METHOD);
  const [setDefaultPaymentMethodApi] = useMutation(SET_DEFAULT_PAYMENT_METHOD);
  const [removePaymentMethodApi] = useMutation(REMOVE_PAYMENT_METHOD);
  const [selectedPayment, updateSelectedPayment] = useState();
  const [paymentMethods, updatePaymentMethods] = useState([]);
  const [showAddModal, updateShowAddModal] = useState(false);

  const handleAddClick = () => {
    updateShowAddModal(true);
  };

  const handleRemoveClick = () => {};

  const handleAdd = async (data) => {
    loadingVar(true);
    await addPaymentMethodApi({
      variables: {
        type: data.type,
        number: data.number,
        exp: data.exp,
        cvc: data.cvc,
      },
      onCompleted: ({ addPaymentMethod: newCard }) => {
        updatePaymentMethods([newCard]);
      },
    });
    updateShowAddModal(false);
    loadingVar(false);
  };
  const handleRemoveConfirm = () => {};
  const handleSetDefault = () => {};

  useEffect(() => {
    loadingVar(true);
    fetchPaymentMethods({
      onCompleted: (data) => {
        loadingVar(false);
        updatePaymentMethods(data.paymentMethods || []);
      },
    });
  }, []);

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton />
        </Header.Left>
        <Header.Center>
          <Header.Title modifiers={['secondaryColor']}>
            Payment Methods
          </Header.Title>
        </Header.Center>
        <Header.Right>
          <Button onClick={handleAddClick} modifiers={['primary', 'noShadow']}>
            {paymentMethods?.length > 0 ? 'Replace' : 'Add'}
          </Button>
        </Header.Right>
      </Header>
      <Content>
        <Container modifiers={['backgroundWhite', 'fullHeight', 'flexBox']}>
          <ScrollView>
            {paymentMethods?.length > 0 ? (
              <Container>
                {paymentMethods.map((method) => (
                  <Row>
                    <Column modifiers={['flexBox', 'verticalContentCenter']}>
                      <Checkbox checked={true} onChange={() => {}} />
                    </Column>
                    <Column modifiers={['col']}>
                      <Text>
                        {method.cardType.toUpperCase()} card ends with{' '}
                        {method.last4Digits}
                      </Text>
                    </Column>
                  </Row>
                ))}
              </Container>
            ) : (
              <Container>
                <Text modifiers={['large']}>
                  Please add payment method to be used on medication orders.
                </Text>
              </Container>
            )}
          </ScrollView>
        </Container>
        {showAddModal && (
          <CreditCardInput
            handleAdd={handleAdd}
            handleClose={() => updateShowAddModal(false)}
          />
        )}
      </Content>
    </MainLayout>
  );
};
