import React, { useState } from 'react';
import { noop } from 'lodash';
import {
  // Modal,
  SearchBar,
  Container,
  Row,
  Column,
  Button,
  Ribbon,
  Avatar,
  Text,
  ListView,
} from '@gaz/gaz-components.public';

import assets from 'assets';
import { getFullName } from 'utils/string';
import { Modal } from 'common';

import Invite from './Invite';

export function SelectContact({
  open,
  showManualAdd,
  users,
  onSearch,
  handleClose,
  handleDone = noop,
}) {
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [showInviteForm, setShowInviteForm] = useState(false);

  const handleSearch = (query) => {
    if (query?.length) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
    onSearch(query);
  };

  const handleClickItem = (record) => {
    if (selectedParticipants.find((selected) => selected._id === record._id)) {
      setSelectedParticipants((alreadySelected) => {
        const newState = alreadySelected.filter(
          (selected) => selected._id !== record._id
        );
        return newState;
      });
    } else {
      setSelectedParticipants((alreadySelected) => [
        ...alreadySelected,
        record,
      ]);
    }
  };

  const handleAdd = () => {
    handleClose();
    handleDone(selectedParticipants);
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <>
      <Modal open={open}>
        {showInviteForm && (
          <Invite handleClose={() => setShowInviteForm(false)} />
        )}
        <Modal.Body>
          <Container modifiers={['fluid']}>
            <Row modifiers={['withGutters', 'center']}>
              <SearchBar onChange={handleSearch} />
            </Row>
            {(!isSearching || !users?.length) && showManualAdd && (
              <Row modifiers={['withGutters', 'center', 'flexColumn']}>
                <Column modifiers={['col_12']}>
                  <Text modifiers={['xSmall']}>
                    Send invite to join Gazuntite
                  </Text>
                </Column>
                <Column modifiers={['col_6', 'col_offset_3']}>
                  <Button
                    onClick={() => setShowInviteForm(true)}
                    image={assets.icons.iconPlusMain}
                    modifiers={[
                      'textIcon',
                      'outlinePrimary',
                      'roundCorner',
                      'fullWidth',
                    ]}
                  >
                    Invite
                  </Button>
                </Column>
              </Row>
            )}
            <Row modifiers={['withGutters']}>
              {selectedParticipants.map((participant, i) => (
                <Column
                  key={`selected-participants-${i}`}
                  modifiers={['col_5', 'flexBox']}
                >
                  <Avatar
                    modifiers={['round']}
                    width={40}
                    height={40}
                    user={participant}
                  />
                  <Ribbon top={-15} right={-15}>
                    <Button
                      onClick={() => handleClickItem(participant)}
                      image={assets.icons.close}
                      modifiers={['icon', 'noPadding', 'transparent']}
                    />
                  </Ribbon>
                  <Text modifiers={['xxSmall', 'light', 'muted']}>
                    {getFullName(participant)}
                  </Text>
                </Column>
              ))}
            </Row>
            {isSearching && (
              <Row modifiers={['withGutters', 'center']}>
                <Column modifiers={['col_12']}>
                  <ListView
                    selectedItems={selectedParticipants}
                    records={users}
                    onClickItem={handleClickItem}
                  />
                </Column>
              </Row>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Column modifiers={['col_6']}>
              <Button
                modifiers={['light', 'widthSmall']}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Column>
            <Column modifiers={['col_6', 'flexBox', 'center']}>
              <Button modifiers={['primary', 'widthSmall']} onClick={handleAdd}>
                Add
              </Button>
            </Column>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
}
