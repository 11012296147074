import styled from 'styled-components';

import { buildStyledComponent } from 'style';

import Input from './Input';
import Label from './Label';
import Text from './Text';

const modifierConfig = {
  disabled: () => ({
    styles: `
      opacity: 0.5;
      pointer-events: none;
    `,
  }),

  readOnly: ({ theme }) => ({
    styles: `
      opacity: 1;
      cursor: not-allowed;

      ${/* sc-sel */ Input} {
        & + label::before {
          cursor: not-allowed;
          background: ${theme.colors.primary};
        }

        & + label {
          color: ${theme.colors.text};

          &:hover {
            color: ${theme.colors.text};
          }
        }

        &:checked + label {
          color: ${theme.colors.text};

          &:hover {
            color: ${theme.colors.text};
          }
        }

        &:hover + label::before {
          border: 1px solid ${theme.colors.primary};
        }
      }
    `,
  }),
  dark: () => ({
    styles: `
    `,
  }),
  hasError: ({ theme }) => ({
    styles: `
      ${Text} {
        border: 1px solid ${theme.colors.danger};
      }
    `,
  }),
};

const styles = ({ theme }) => `
  display: inline-block;
`;

const Checkbox = buildStyledComponent('Checkbox', styled.div, styles, {
  modifierConfig,
});

Checkbox.Input = Input;
Checkbox.Label = Label;
Checkbox.Text = Text;

export default Checkbox;
