import { gql } from '@apollo/client';

import fragments from './fragments';

export const FETCH_ME = gql`
  query Me {
    me {
      ...AuthUserFragment
    }
  }
  ${fragments.authUserFragment}
`;

export const GET_LOADING = gql`
  query GetLoading {
    loading @client
  }
`;

export const GET_ERROR = gql`
  query GetError {
    error @client
  }
`;

export const GET_SPLASH_LOADING = gql`
  query GetSplashLoading {
    splashLoading @client
  }
`;

export const FETCH_USER = gql`
  query fetchUser($id: String!) {
    user(id: $id) {
      ...UserFragment
    }
  }
  ${fragments.userFragment}
`;

export const FETCH_CHAT = gql`
  query fetchChat($id: String!) {
    chat(id: $id) {
      ...ChatFragment
    }
  }
  ${fragments.chatFragment}
`;

export const FETCH_CONTACTS = gql`
  query fetchContacts(
    $query: String
    $category: String
    $page: Int
    $pageSize: Int
  ) {
    contacts(
      query: $query
      category: $category
      page: $page
      pageSize: $pageSize
    ) {
      ... on User {
        ...UserFragment
      }
      ... on Group {
        ...GroupFragment
      }
    }
  }
  ${fragments.userFragment}
  ${fragments.groupFragment}
`;

export const FETCH_LEADS = gql`
  query fetchLeads(
    $query: String
    $category: String
    $page: Int
    $pageSize: Int
  ) {
    leads(
      query: $query
      category: $category
      page: $page
      pageSize: $pageSize
    ) {
      ...LeadFragment
    }
  }
  ${fragments.leadFragment}
`;

export const FETCH_INDIVIDUAL_INVITES = gql`
  query fetchIndividualInvites(
    $received: Boolean!
    $category: String!
    $page: Int
    $pageSize: Int
  ) {
    individualInvites(
      received: $received
      category: $category
      page: $page
      pageSize: $pageSize
    ) {
      received
      category
      invites {
        ...InviteFragment
      }
      total
    }
  }
  ${fragments.inviteFragment}
`;

export const FETCH_ALL_INVITES = gql`
  query fetchAllInvites($page: Int, $pageSize: Int) {
    allInvites(page: $page, pageSize: $pageSize) {
      received
      category
      invites {
        ...InviteFragment
      }
      total
    }
  }
  ${fragments.inviteFragment}
`;

export const FETCH_ADMIN_GROUP_INVITES = gql`
  query fetchAdminGroupInvites {
    adminGroupInvites {
      group {
        ...GroupFragment
      }
      totalReceived
      totalSent
    }
  }
  ${fragments.groupFragment}
`;

export const FETCH_GROUP_DETAILS = gql`
  query fetchGroupDetails($id: ID!) {
    groupDetails(id: $id) {
      ...GroupDetailsFragment
    }
  }
  ${fragments.groupDetailsFragment}
`;

export const FETCH_GROUP_MEMBER_LEADS = gql`
  query groupMemberLeads($id: ID!, $isPractice: Boolean!, $query: String) {
    groupMemberLeads(id: $id, isPractice: $isPractice, query: $query) {
      ...UserProfileFragment
    }
  }
  ${fragments.userProfileFragment}
`;

export const FETCH_APPOINTMENTS = gql`
  query fetchAppointments($from: Date!, $to: Date!) {
    appointments(from: $from, to: $to) {
      ...AppointmentFragment
    }
  }
  ${fragments.appointmentFragment}
`;

export const FETCH_NOTE = gql`
  query fetchNote($id: ID!) {
    note(id: $id) {
      ...NoteFragment
    }
  }
  ${fragments.noteFragment}
`;

export const FETCH_NOTES = gql`
  query fetchNotes($period: String!) {
    notes(period: $period) {
      ...NoteFragment
    }
  }
  ${fragments.noteFragment}
`;

export const FETCH_PATIENT_DIAGNOSES = gql`
  query fetchPatientDiagnoses($patient: ID, $period: String) {
    patientDiagnoses(patient: $patient, period: $period) {
      ...DiagnosisFragment
    }
  }
  ${fragments.diagnosisFragment}
`;

export const FETCH_ACTIVE_CAREPLAN = gql`
  query fetchPatientActiveCareplan {
    activeCareplan {
      ...CareplanFragment
    }
  }
  ${fragments.careplanFragment}
`;

export const FETCH_CAREPLANS = gql`
  query fetchPatientCareplans($period: String, $status: String) {
    patientCareplans(period: $period, status: $status) {
      ...CareplanFragment
    }
  }
  ${fragments.careplanFragment}
`;

export const FETCH_CAREPLAN = gql`
  query fetchPatientCareplan($id: ID!) {
    careplan(id: $id) {
      ...CareplanFragment
    }
  }
  ${fragments.careplanFragment}
`;

export const FETCH_CAREPLAN_RESPONSE = gql`
  query fetchPatientCareplanResponse($date: Date) {
    todayResponse(date: $date) {
      ...CareplanResponseFragment
    }
  }
  ${fragments.careplanResponseFragment}
`;

export const FETCH_PATIENT_PROGRESS = gql`
  query fetchPatientProgress {
    patientProgress {
      ...ProgressFragment
    }
  }
  ${fragments.progressFragment}
`;

export const FETCH_PATIENT_RESPONSES = gql`
  query fetchPatientResponses($from: Date, $to: Date) {
    patientResponses(from: $from, to: $to) {
      responses {
        ...CareplanResponseFragment
      }
      careplans {
        ...CareplanFragment
      }
    }
  }
  ${fragments.careplanResponseFragment}
  ${fragments.careplanFragment}
`;

export const INSTANT_CALL = gql`
  query instantCall($user: String!, $referredPatient: String) {
    instantCall(user: $user, referredPatient: $referredPatient) {
      _id
      status
      callType
      isPSTN
      token
    }
  }
`;

export const APPOINTMENT_CALL = gql`
  query appointmentCall($appointment: ID!) {
    appointmentCall(appointment: $appointment) {
      _id
      status
      callType
      isPSTN
      token
      appointment
    }
  }
`;

export const FETCH_CALL_FROM_TOKEN = gql`
  query callFromToken($token: String!) {
    callFromToken(token: $token) {
      me {
        ...AuthFragment
      }
      appointment {
        ...AppointmentFragment
      }
      call {
        _id
        status
        callType
        isPSTN
        token
      }
    }
  }
  ${fragments.authFragment}
  ${fragments.appointmentFragment}
`;

export const FETCH_PATIENT_ORDERS = gql`
  query fetchPatientMedicationOrders($period: String) {
    patientMedicationOrders(period: $period) {
      ...MedicationOrderFragment
    }
  }
  ${fragments.medicationOrderFragment}
`;

export const FETCH_ORDER = gql`
  query fetchMedicationOrder($id: ID) {
    medicationOrder(id: $id) {
      ...MedicationOrderFragment
    }
  }
  ${fragments.medicationOrderFragment}
`;

export const FETCH_RECENT_CHATS = gql`
  query fetchRecentChats($query: String!, $offset: Int, $limit: Int) {
    recentChats(query: $query, offset: $offset, limit: $limit) {
      _id
      chat {
        _id
        members {
          _id
          firstName
          lastName
          role
          memberDesignation
          photo {
            url
          }
        }
        unreadCount
      }
      text
      updatedAt
    }
  }
`;

export const FETCH_UNREAD_MESSAGE_COUNT = gql`
  query fetchUnreadMessageCount {
    unreadMessageCount
  }
`;

export const FETCH_AVAILABLE_TIMES = gql`
  query fetchProviderAvailableTimes(
    $provider: ID!
    $from: Date!
    $to: Date!
    $offset: Int
  ) {
    availableTimes(provider: $provider, from: $from, to: $to, offset: $offset) {
      start
      end
    }
  }
`;

export const FETCH_PATIENT_REPORT = gql`
  query patientReport($year: Int!, $month: Int!, $practice: ID) {
    patientReport(year: $year, month: $month, practice: $practice) {
      total
      records {
        _id
        totalTime
        isManual
        dscription
        startedAt
        endedAt
        provider
        patient
        items {
          _id
          startedAt
          endedAt
          duration
          entityId
          type
          participants {
            ...UserFragment
          }
          referredPatient {
            ...UserFragment
          }
        }
      }
    }
  }
  ${fragments.userFragment}
`;

export const FETCH_MONTHLY_PATIENT_READINGS = gql`
  query fetchMonthlyPatientReadings($year: Int!, $month: Int!) {
    monthlyPatientReadings(year: $year, month: $month) {
      time
      physiologic
      therapeutic
    }
  }
`;

export const FETCH_PATIENT_ALERTS = gql`
  query fetchPatientAlerts($patient: ID!, $period: String) {
    patientAlerts(patient: $patient, period: $period) {
      _id
      user {
        ...UserProfileFragment
      }
      alerts
      measure
      subType
      triggerTime
      isSeen
    }
  }
  ${fragments.userProfileFragment}
`;

export const FETCH_ALL_NEW_ITEMS_COUNT = gql`
  query fetchAllNewItemsCount {
    allNewItemsCount {
      note
      careplan
      coordinate
      prescribe
      alert
      call
      invite
      report
    }
  }
`;

export const FETCH_NEW_INVITE_COUNT = gql`
  query fetchNewInviteCount {
    inviteCount
  }
`;

export const FETCH_NEW_REPORT_COUNT = gql`
  query fetchNewReportCount {
    reportCount
  }
`;

export const FETCH_NEW_CAREPLAN_COUNT = gql`
  query fetchNewCareplanCount {
    careplanCount
  }
`;

export const FETCH_NEW_COORDINATE_COUNT = gql`
  query fetchNewCoordinateCount {
    coordinateCount
  }
`;

export const FETCH_NEW_NOTE_COUNT = gql`
  query fetchNewNoteCount {
    noteCount
  }
`;

export const FETCH_NEW_ALERT_COUNT = gql`
  query fetchNewAlertCount {
    alertCount
  }
`;

export const FETCH_NEW_CALL_COUNT = gql`
  query fetchNewCallCount {
    callCount
  }
`;

export const FETCH_NEW_PRESCRIBE_COUNT = gql`
  query fetchNewPrescribeCount {
    prescribeCount
  }
`;

export const FETCH_PAYMENT_METHODS = gql`
  query fetchPaymentMethods {
    paymentMethods {
      isDefault
      cardType
      last4Digits
    }
  }
`;
