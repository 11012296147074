import React from 'react';
import ReactDOM from 'react-dom';
import { noop } from 'lodash';
import { ThemeProvider } from 'styled-components';
import theme from 'style/theme';
import { Row, Button, Text } from '@gaz/gaz-components.public';

import Content from './Content';
import Body from './Body';
import Header from './Header';
import Footer from './Footer';
import Overlay from './Overlay';
import Wrapper from './Wrapper';

export const Modal = ({
  open,
  children,
  contained = false,
  large = false,
  onClose = noop,
}) => {
  if (!open) {
    return null;
  }
  return (
    <Wrapper>
      <Overlay onClick={onClose} />
      <Content modifiers={[contained && 'contained', large && 'large']}>
        {children}
      </Content>
    </Wrapper>
  );
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;
Modal.info = ({ text, render }) => {
  const el = document.createElement('div');
  const root = document.getElementById('root');
  root.appendChild(el);

  function destroy() {
    const unmountResult = ReactDOM.unmountComponentAtNode(el);
    if (unmountResult && el.parentNode) {
      el.parentNode.removeChild(el);
    }
  }

  setTimeout(destroy, 1800);

  setTimeout(() => {
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Overlay modifiers={['shortLived']} />
          <Content modifiers={['contained']}>
            {render ? render() : text}
          </Content>
        </Wrapper>
      </ThemeProvider>,
      el
    );
  });
};

Modal.confirm = ({
  text,
  render,
  onOk,
  onCancel,
  okText = 'Confirm',
  cancelText = 'Cancel',
}) => {
  const el = document.createElement('div');
  const root = document.getElementById('root');
  root.appendChild(el);

  function destroy() {
    const unmountResult = ReactDOM.unmountComponentAtNode(el);
    if (unmountResult && el.parentNode) {
      el.parentNode.removeChild(el);
    }
  }

  function handleOk() {
    if (onOk) {
      onOk();
    }
    destroy();
  }

  function handleCancel() {
    if (onCancel) {
      onCancel();
    }
    destroy();
  }

  setTimeout(() => {
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Overlay modifiers={['shortLived']} />
          <Content modifiers={['contained', 'medium']}>
            {render ? render() : text}
            <Row modifiers={['middle', 'spaceBetween']}>
              <Button modifiers={['light']} onClick={handleCancel}>
                <Text
                  modifiers={['center', 'darkGrey', 'subtitle', 'semiBold']}
                >
                  {cancelText}
                </Text>
              </Button>
              <Button onClick={handleOk} modifiers={['primary']}>
                <Text modifiers={['center', 'white', 'subtitle', 'semiBold']}>
                  {okText}
                </Text>
              </Button>
            </Row>
          </Content>
        </Wrapper>
      </ThemeProvider>,
      el
    );
  }, 0);
};

export default Modal;
