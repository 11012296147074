import { useEffect, useState, useCallback, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';

import { FETCH_ACTIVE_CAREPLAN } from 'graphql/queries';
import { AuthContext } from '../auth';
import { filterCareplanSchedulesByItemTime } from 'utils/careplan';

const useCareplan = () => {
  const { me } = useContext(AuthContext);
  const [careplan, updateCareplan] = useState(null);
  const [isCPExpired, setIsCPExpired] = useState();
  const [tasks, updateTasks] = useState(null);
  const [fetchCareplanQuery] = useLazyQuery(FETCH_ACTIVE_CAREPLAN, {
    onCompleted: (data) => {
      const careplan = data.activeCareplan?.isActive
        ? data.activeCareplan
        : null;
      setIsCPExpired(data.activeCareplan && !data.activeCareplan.isActive);
      updateCareplan(careplan);
      updateTasks(filterCareplanSchedulesByItemTime(careplan));
    },
    onError: () => {
      setIsCPExpired(null);
      updateCareplan(null);
    },
  });

  const fetchCareplan = useCallback(async () => {
    await fetchCareplanQuery();
  }, []);

  const loadTasks = useCallback(async () => {}, []);

  useEffect(() => {
    if (!!me?._id) {
      fetchCareplan();
    } else {
      updateCareplan(null);
    }
  }, [me?._id]);

  return { careplan, isCPExpired, tasks, updateTasks, loadTasks };
};

export default useCareplan;
