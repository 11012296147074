import { gql } from '@apollo/client';

const addressFragment = gql`
  fragment AddressFragment on Address {
    addressLine1
    addressLine2
    city
    state
    stateCode
    country
    countryCode
    zipcode
  }
`;

const practiceFragment = gql`
  fragment PracticeFragment on Practice {
    _id
    name
    npi
    fax
    email
    phone
    address {
      ...AddressFragment
    }
    image {
      originalName
      type
      url
    }
  }
  ${addressFragment}
`;

const groupFragment = gql`
  fragment GroupFragment on Group {
    _id
    name
    description
    image {
      originalName
      type
      url
    }
  }
`;

const userProfileFragment = gql`
  fragment UserProfileFragment on User {
    _id
    firstName
    lastName
    middleName
    email
    photo {
      originalName
      type
      url
    }
    phones {
      mobile
      home
      work
      preference
    }
    role
    status
    dob
    ssn
    gender
    maritalStatus
    memberDesignation
    npi
    bpmIMEI
    scaleIMEI
    address {
      ...AddressFragment
    }
    insurances {
      company
      id
      groupNumber
      card {
        originalName
        type
        url
      }
    }
    notifications
  }
  ${addressFragment}
`;

const authUserProfileFragment = gql`
  fragment AuthUserProfileFragment on AuthUser {
    _id
    firstName
    lastName
    middleName
    email
    photo {
      originalName
      type
      url
    }
    phones {
      mobile
      home
      work
      preference
    }
    role
    status
    dob
    ssn
    gender
    maritalStatus
    memberDesignation
    npi
    bpmIMEI
    scaleIMEI
    address {
      ...AddressFragment
    }
    insurances {
      company
      id
      groupNumber
      card {
        originalName
        type
        url
      }
    }
    notifications
  }
  ${addressFragment}
`;

const authUserFragment = gql`
  fragment AuthUserFragment on AuthUser {
    ...AuthUserProfileFragment
    activeProviderPractice {
      practice {
        _id
        name
        npi
        fax
        email
        phone
        address {
          ...AddressFragment
        }
        image {
          originalName
          type
          url
        }
      }
      isAdmin
      title
      specialty
    }
    isTempAuth
    patientPractice {
      ...PracticeFragment
    }
  }
  ${authUserProfileFragment}
  ${practiceFragment}
`;

const userFragment = gql`
  fragment UserFragment on User {
    ...UserProfileFragment
    activeProviderPractice {
      practice {
        ...PracticeFragment
      }
      isAdmin
      title
      specialty
    }
  }
  ${practiceFragment}
  ${userProfileFragment}
`;

const leadFragment = gql`
  fragment LeadFragment on Lead {
    contact {
      ... on User {
        ...UserFragment
      }
      ... on Practice {
        ...PracticeFragment
      }
      ... on Group {
        ...GroupFragment
      }
    }
    inviteId
    outgoing
  }
  ${userFragment}
  ${practiceFragment}
  ${groupFragment}
`;

const inviteFragment = gql`
  fragment InviteFragment on Invite {
    _id
    practice {
      ...PracticeFragment
    }
    group {
      ...GroupFragment
    }
    inviter {
      ...UserFragment
    }
    invitee {
      ...UserFragment
    }
  }
  ${userFragment}
  ${practiceFragment}
  ${groupFragment}
`;

const groupDetailsFragment = gql`
  fragment GroupDetailsFragment on GroupDetails {
    group {
      ...GroupFragment
    }
    members {
      user {
        ...UserProfileFragment
      }
      isAdmin
    }
    invites {
      ...InviteFragment
    }
    requests {
      ...InviteFragment
    }
    isAdmin
  }
  ${groupFragment}
  ${userProfileFragment}
  ${inviteFragment}
`;

const providerFragment = gql`
  fragment ProviderFragment on ProviderPractice {
    user {
      ...UserFragment
    }
    practice {
      ...PracticeFragment
    }
    isAdmin
  }
  ${userFragment}
  ${practiceFragment}
`;

const authFragment = gql`
  fragment AuthFragment on Auth {
    error
    token
    user {
      ...AuthUserFragment
    }
  }
  ${authUserFragment}
`;

const chatFragment = gql`
  fragment ChatFragment on Chat {
    _id
    members {
      ...UserProfileFragment
    }
    referredPatient {
      ...UserProfileFragment
    }
    group
    messages {
      _id
      sender {
        ...UserProfileFragment
      }
      text
      createdAt
      attachment {
        url
        originalName
        type
      }
    }
    createdAt
  }
  ${userProfileFragment}
`;

const diagnosisFragment = gql`
  fragment DiagnosisFragment on Diagnosis {
    code
    description
    date
  }
`;

const noteContentFragment = gql`
  fragment NoteContentFragment on NoteContent {
    subjective
    objective {
      heartRate
      glucose
      weight
      bloodPressure
      bloodPressure2
      bloodOxygen
      respiratory
      temperature
    }
    assessment
    plan
    diagnosis {
      ...DiagnosisFragment
    }
    attachments {
      category
      url
      originalName
      type
      chatId
      messageIds
      note
      createdAt
    }
  }
  ${diagnosisFragment}
`;

const noteFragment = gql`
  fragment NoteFragment on Note {
    _id
    signDate
    createdAt
    updatedAt
    isDraft
    user {
      ...UserFragment
    }
    creator {
      ...ProviderFragment
    }
    content {
      ...NoteContentFragment
    }
    isSeen
  }
  ${userFragment}
  ${providerFragment}
  ${noteContentFragment}
`;

const careTeamFragment = gql`
  fragment CareTeamFragment on CareTeam {
    user {
      ...UserProfileFragment
    }
    appointments
    alerts
  }
`;

const careplanContentFragment = gql`
  fragment CareplanContentFragment on CareplanContent {
    medication
    vital
    activity
    wellness
    diet
    careTeam {
      ...CareTeamFragment
    }
  }
  ${careTeamFragment}
`;

const careplanFragment = gql`
  fragment CareplanFragment on Careplan {
    _id
    startDate
    signDate
    duration
    isDraft
    isActive
    createdAt
    updatedAt
    user {
      ...UserFragment
    }
    creator {
      ...ProviderFragment
    }
    content {
      ...CareplanContentFragment
    }
    isSeen
  }
  ${providerFragment}
  ${careplanContentFragment}
`;

const appointmentFragment = gql`
  fragment AppointmentFragment on Appointment {
    _id
    creator {
      ...UserProfileFragment
    }
    patient {
      ...UserProfileFragment
    }
    provider {
      ...UserProfileFragment
    }
    time
    reason
    status
    joined
  }
  ${userProfileFragment}
`;

const medicationOrderFragment = gql`
  fragment MedicationOrderFragment on MedicationOrder {
    _id
    orderNumber
    status
    patient {
      ...UserFragment
    }
    provider {
      ...UserFragment
    }
    medications
    createdAt
    updatedAt
  }
  ${userFragment}
`;

const careplanResponseFragment = gql`
  fragment CareplanResponseFragment on CareplanResponse {
    date
    careplan
    responses
  }
`;

const progressFragment = gql`
  fragment ProgressFragment on Progress {
    vital
    medication
    wellness
    diet
    activity
    appointment
  }
`;

const fragments = {
  addressFragment,
  practiceFragment,
  groupFragment,
  groupDetailsFragment,
  authUserFragment,
  userFragment,
  providerFragment,
  userProfileFragment,
  leadFragment,
  authFragment,
  chatFragment,
  diagnosisFragment,
  noteContentFragment,
  noteFragment,
  careplanFragment,
  careplanResponseFragment,
  progressFragment,
  inviteFragment,
  appointmentFragment,
  medicationOrderFragment,
};

export default fragments;
