export const SHARE_OPTIONS = {
  ADD_PARTICIPANTS: 'ADD_PARTICIPANTS',
  ADD_NOTE: 'ADD_NOTE',
  TAKE_VIDEO: 'TAKE_VIDEO',
  TAKE_PHOTO: 'TAKE_PHOTO',
  MEDIA_LIBRARY: 'MEDIA_LIBRARY',
  BOOK_APPOINTMENT: 'BOOK_APPOINTMENT',
  ADD_ATTACHMENT: 'ADD_ATTACHMENT',
};

export const MEDIA_TYPES = {
  VIDEO: 'video/*',
  IMAGE: 'image/*',
};

export const S3_VIDEO_FOLDER = 'chats/video';
export const S3_IMAGE_FOLDER = 'chats/image';
