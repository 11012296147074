import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PatientContext } from 'contexts/patient';
import { ProgressContext } from '../Context';
import { getMeasureProgress } from 'utils/careplan';
import Detail from './Detail';
import List from './List';
import { VITALS } from 'utils/constants';

export default ({}) => {
  const { progress, fetchProgress } = useContext(PatientContext);
  const { responses, setBackHandler } = useContext(ProgressContext);
  const measureProgress = useMemo(() => {
    if (!!progress) {
      return getMeasureProgress(progress, 'vital');
    }
    return null;
  }, [progress]);
  const history = useHistory();

  const vitalResponses = useMemo(() => {
    const values = {};
    Object.keys(VITALS).forEach((key) => (values[key] = []));
    responses?.forEach((dailyResponse) => {
      dailyResponse.responses
        .filter((response) => response.measure === 'vital')
        .sort((a, b) => {
          if (new Date(a.time) > new Date(b.time)) {
            return -1;
          }
          return 1;
        })
        .forEach((response) => {
          values[response.response.type].push(response);
        });
    });
    return values;
  }, [responses]);

  const finalResponses = useMemo(() => {
    const values = {};
    Object.keys(VITALS).forEach((key) => {
      if (vitalResponses[key].length === 0) {
        values[key] = null;
      }
      for (let i = 0; i < vitalResponses[key].length; i++) {
        if (
          vitalResponses[key][i].response.value !== undefined &&
          vitalResponses[key][i].response.value !== null
        ) {
          values[key] = vitalResponses[key][i];
          return;
        }
      }
      values[key] = null;
    });
    return values;
  }, [vitalResponses]);

  useEffect(() => {
    if (progress === null || progress === undefined) {
      fetchProgress();
    }
    setBackHandler(() => {
      history.replace('/progress');
    });
  }, []);

  const [selectedVital, setSelectedVital] = useState(null);

  const handleSelectVital = (vital) => {
    setSelectedVital(vital);
  };

  useEffect(() => {
    if (selectedVital) {
      setBackHandler(() => {
        setSelectedVital(null);
      });
    }
  }, [selectedVital]);

  return (
    <>
      {selectedVital ? (
        <Detail
          vitalType={selectedVital}
          finalResponse={finalResponses[selectedVital]}
          responses={vitalResponses[selectedVital]}
        />
      ) : (
        <List
          progress={measureProgress}
          finalResponses={finalResponses}
          handleSelectVital={handleSelectVital}
        />
      )}
    </>
  );
};
