import React from 'react';
import styled from 'styled-components';
import { noop } from 'lodash';

import { Row, Column, Text } from '@gaz/gaz-components.public';
import { buildStyledComponent, px2rem } from 'style';

const wrapperStyles = ({ theme }) => `
  border-bottom: ${px2rem(0.5)} solid ${theme.colors.gray5};
`;

export const Wrapper = buildStyledComponent(
  'SlidingPaneContent_Wrapper',
  styled(Row),
  wrapperStyles,
);

export function SlidingPaneContent({
  actionTitle = '',
  actionDescription,
  actionIcon = '',
  onClickAction = noop,
}) {
  return (
    <Row modifiers={['fluid']} onClick={onClickAction}>
      <Column
        modifiers={['col_2', 'flexBox', 'center', 'verticalContentCenter']}
      >
        <img src={actionIcon} alt="" width="35" height="35" />
      </Column>
      <Column modifiers={['col_10', 'fluid']}>
        <Row modifiers={['flexColumn']}>
          <Column modifiers={['col_12', 'noVerticalPadding']}>
            <Text modifiers={['primary', 'medium']}>{actionTitle}</Text>
          </Column>
          {actionDescription && (
            <Column modifiers={['col_12', 'noVerticalPadding']}>
              <Text modifiers={['muted', 'xSmall']}>{actionDescription}</Text>
            </Column>
          )}
        </Row>
      </Column>
    </Row>
  );
}
