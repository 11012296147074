import { Column, Button } from '@gaz/gaz-components.public';

import { Container, Row, Popover, Text } from 'common';
import { displayTime } from 'utils/time';
import { formatTitleAndName } from 'utils/string';
import assets from 'assets';

export default ({
  appointment,
  canStart,
  joinCall,
  clickCancel,
  handleClose,
  clickReschedule,
  isOngoingCall,
}) => (
  <Popover open>
    <Row modifiers={['spaceBetween', 'withGutters', 'middle']}>
      <Text modifiers={['bold', 'large']}>Appointment</Text>
      {/* <Text modifiers={['danger', 'small']} onClick={clickCancel}>
        Cancel Appointment
      </Text> */}
    </Row>
    <Row modifiers={['smallGutters']}>
      <Column modifiers={['col_4', 'noPadding']}>
        <Text>Provider:</Text>
      </Column>
      <Column modifiers={['col_8', 'noPadding']}>
        <Text modifiers={['primary']}>
          {formatTitleAndName(appointment.provider)}
        </Text>
      </Column>
    </Row>
    <Row modifiers={['smallGutters']}>
      <Column modifiers={['col_4', 'noPadding']}>
        <Text>Patient:</Text>
      </Column>
      <Column modifiers={['col_8', 'noPadding']}>
        <Text modifiers={['primary']}>
          {formatTitleAndName(appointment.patient)}
        </Text>
      </Column>
    </Row>
    <Row modifiers={['middle']}>
      <Column modifiers={['noPadding', 'col_7']}>
        <Row modifiers={['smallGutters']}>
          <Column modifiers={['col_4', 'noPadding']}>
            <Text>Date:</Text>
          </Column>
          <Column modifiers={['col_8', 'noPadding']}>
            <Text modifiers={['primary']}>
              {displayTime(appointment.time, 'M/D/YY')}
            </Text>
          </Column>
        </Row>
        <Row modifiers={['smallGutters']}>
          <Column modifiers={['col_4', 'noPadding']}>
            <Text>Time:</Text>
          </Column>
          <Column modifiers={['col_8', 'noPadding']}>
            <Text modifiers={['primary']}>
              {displayTime(appointment.time, 'hh:mm a')}
            </Text>
          </Column>
        </Row>
      </Column>
      <Column modifiers={['noPadding', 'col_5']}>
        {isOngoingCall ? (
          <Button modifiers={['info', 'roundCorner', 'widthXSmall']}>
            On Call
          </Button>
        ) : (
          <>
            {appointment.status === 'scheduled' && canStart && (
              <Button
                modifiers={['success', 'roundCorner', 'widthXSmall']}
                onClick={joinCall}
              >
                Start
              </Button>
            )}
            {appointment.status === 'active' && (
              <Button
                modifiers={['primary', 'roundCorner', 'widthXSmall']}
                onClick={joinCall}
              >
                Join
              </Button>
            )}
          </>
        )}
      </Column>
    </Row>
    <Row modifiers={['withGutters']}>
      <Column modifiers={['col_4', 'noPadding']}>
        <Text>Reason:</Text>
      </Column>
      <Column modifiers={['col_8', 'noPadding']}>
        <Text modifiers={['primary']}>{appointment.reason}</Text>
      </Column>
    </Row>
    <Row modifiers={['spaceBetween', 'withGutters', 'middle']}>
      <Button
        image={assets.icons.icCloseGrey}
        modifiers={['icon', 'transparent']}
        onClick={handleClose}
      />
      {/* <Button
        modifiers={['widthMedium', 'roundCorner']}
        onClick={clickReschedule}
      >
        Reschedule
      </Button> */}
    </Row>
  </Popover>
);
