import React from 'react';
import { noop } from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Modal } from '@gaz/gaz-components.public';

import Video from './Video';
import Image from './Image';
import ViewerControl from './ViewerControl';

import assets from 'assets';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

export function MediaViewer({
  gallery = [],
  onClose = noop,
  initialMedia = 0,
}) {
  return (
    <Modal open modifiers={['fullScreen', 'centerContent', 'noBorder']}>
      <ViewerControl
        onClick={onClose}
        icon={assets.icons.close}
        modifiers={['right', 'top']}
      />
      <Swiper slidesPerView={1}>
        {gallery.map((media, i) => (
          <SwiperSlide key={media.url}>
            {media.type === 'video' && (
              <Video controls playsInline autoPlay>
                <source src={media.url} />
              </Video>
            )}
            {media.type === 'image' && (
              <Image backgroundImage={media.url} alt="" />
            )}
          </SwiperSlide>
        ))}

        <span style={{ position: 'relative' }} slot="container-start"></span>
      </Swiper>
    </Modal>
  );
}
