import React from 'react';
import Draggable from 'react-draggable';
import { Text, Button } from '@gaz/gaz-components.public';

import assets from 'assets';

import Wrapper from './Wrapper';
import { useHistory } from 'react-router';

export default function OngoingCall() {
  const history = useHistory();
  return (
    <Draggable
      axis="y"
      bounds="parent"
      cancel="img"
      grid={[10, 10]}
      allowAnyClick
      defaultPosition={{
        x: window.innerWidth - 80,
        y: -200,
      }}
    >
      <div>
        <Wrapper>
          <img
            src={assets.icons.icCallExpand}
            width={26}
            height={26}
            modifiers={['icon', 'transparent', 'noPadding']}
            alt=""
            onClick={() => history.replace('/messaging')}
          />
          <Text modifiers={['subtitle', 'white']}>5:32</Text>
        </Wrapper>
      </div>
    </Draggable>
  );
}
