import React, { useRef, forwardRef } from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';

import {
  FormInput,
  Row,
  Column,
  FormPhoneInput,
} from '@gaz/gaz-components.public';
import { FormPicker } from 'common';
import schema from 'utils/schema';
import { STATES } from 'utils/constants';

const contactSchema = Yup.object().shape({
  phones: schema.phones,
  email: schema.email,
  address: schema.address,
});

const Contact = forwardRef(({ me, isEditing, show }, ref) => {
  const initialized = useRef(false);
  if (show) initialized.current = true;

  const initialValues = {
    address: me.address,
    email: me.email,
    phones: me.phones,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={contactSchema}
      innerRef={ref}
      onSubmit={() => {}}
    >
      {initialized.current && (
        <Form style={{ display: show ? null : 'none' }}>
          <Row>
            <Column modifiers="col_12">
              <Field
                disabled={!isEditing}
                hasLabel
                required
                name="email"
                placeholder="Email"
                component={FormInput}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers="col_6">
              <Field
                disabled={!isEditing}
                hasLabel
                required
                name="phones.mobile"
                placeholder="Mobile number"
                component={FormPhoneInput}
              />
            </Column>
            <Column modifiers="col_6">
              <Field
                disabled={!isEditing}
                hasLabel
                name="phones.home"
                placeholder="Home number"
                component={FormPhoneInput}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers="col_6">
              <Field
                disabled={!isEditing}
                hasLabel
                name="phones.work"
                placeholder="Work number"
                component={FormPhoneInput}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers="col_12">
              <Field
                disabled={!isEditing}
                hasLabel
                name="address.addressLine1"
                placeholder="Address"
                component={FormInput}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers="col_12">
              <Field
                disabled={!isEditing}
                hasLabel
                name="address.addressLine2"
                placeholder="Address Line 2"
                component={FormInput}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers="col_6">
              <Field
                disabled={!isEditing}
                hasLabel
                name="address.city"
                placeholder="City"
                component={FormInput}
              />
            </Column>
            <Column modifiers="col_6">
              <Field
                disabled={!isEditing}
                hasLabel
                name="address.state"
                placeholder="State"
                options={STATES}
                idField="abbreviation"
                nameField="name"
                component={FormPicker}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers="col_6">
              <Field
                disabled={!isEditing}
                hasLabel
                name="address.zipcode"
                placeholder="Zip"
                component={FormInput}
                pattern="\d*"
                maxLength="5"
              />
            </Column>
          </Row>
        </Form>
      )}
    </Formik>
  );
});

export default Contact;
