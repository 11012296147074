import React from 'react';
import styled from 'styled-components';
import { Container } from '@gaz/gaz-components.public';

import { buildStyledComponent, px2rem } from 'style';
import Header, { HEADER_HEIGHT } from './Header';
import Footer, { FOOTER_HEIGHT } from './Footer';

const wrapperStyles = () => `
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ChatLayoutWrapper = buildStyledComponent(
  'ChatLayoutWrapper',
  styled.div,
  wrapperStyles
);

const contentWrapperConfig = {
  legacy: ({ theme }) => `
    background-color: ${theme.colors.white};
  `,
};

const styles = () => `
  overflow-y: scroll;
  position: relative;
  flex: 1;
  background-color: #F3F3F4;
  padding-bottom: ${px2rem(FOOTER_HEIGHT)};
  padding-top: ${px2rem(HEADER_HEIGHT)};
`;

const ContentWrapper = buildStyledComponent(
  'ContentWrapper_ChatLayout',
  styled(Container),
  styles,
  { modifierConfig: contentWrapperConfig }
);

export function ChatLayout({
  formInputProps,
  messageInputProps,
  onClickAddAttatchment,
  footerContent,
  members,
  children,
}) {
  return (
    <ChatLayoutWrapper>
      {children}
      {/* <Footer
        content={footerContent}
        formInputProps={formInputProps}
        messageInputProps={messageInputProps}
        onClickAddAttatchment={onClickAddAttatchment}
      /> */}
    </ChatLayoutWrapper>
  );
}

ChatLayout.Footer = Footer;
ChatLayout.Header = Header;
ChatLayout.ContentWrapper = ContentWrapper;
