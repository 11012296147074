import { Container, Text } from '@gaz/gaz-components.public';
import styled from 'styled-components';

import { Row } from 'common';
import { buildStyledComponent } from 'style';
import { frequencyTitle, alertsTitle, getVitalTypeAttr } from 'utils/helper';
import { VITALS } from 'utils/constants';
import MeasureItem from './MeasureItem';
import assets from 'assets';

const Vital = buildStyledComponent(
  'CareplanVital',
  styled(({ vitals, ...props }) => {
    const vitalItems = Object.entries(vitals).map(([name, vital]) => {
      const detail = { Schedule: frequencyTitle(vital.frequency) };
      if (vital.alerts) {
        detail.Triggers = alertsTitle(vital.alerts, name, true);
        detail.Allowed = alertsTitle(vital.alerts);
        detail.Action = 'Book Appointments';
      }

      return {
        name: VITALS[name],
        goal: '',
        detail,
        icon: getVitalTypeAttr(name)?.icon,
      };
    });
    return (
      <Container {...props}>
        <Text modifiers={['bold']}>VITAL</Text>
        {vitalItems.map((vital, index) => (
          <Container modifiers={['fluid']} key={index}>
            <Row modifiers={['smallGutters', 'middle']}>
              <img src={vital.icon} />
              <Text>{vital.name}</Text>
            </Row>
            <MeasureItem details={vital.detail} />
          </Container>
        ))}
      </Container>
    );
  }),
  ({ theme }) => `
    >:not(:first-child) {
      margin-left: ${theme.dimensions.padding_2};
    }
  `
);

export default Vital;
