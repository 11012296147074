import { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';

import assets from 'assets';
import { MainLayout } from 'layouts';
import {
  Row,
  Column,
  BackButton,
  Text,
  Button,
} from '@gaz/gaz-components.public';

import { Container, TimeLabel } from 'common';
import Tabs from '../Tabs';
import IconWrapper from '../IconWrapper';
import { PatientContext } from 'contexts/patient';
import { checkTaskDue, hourToDate, toLocalTime, displayTime } from 'utils/time';
import { loadingVar } from 'graphql/cache';

const { Header, Content, Footer } = MainLayout;

export default ({ match }) => {
  const history = useHistory();
  const [tab, setTab] = useState(null);
  const { tasks, addResponse, responses } = useContext(PatientContext);
  const hours = tasks ? Object.keys(tasks.activity) : [];
  const isTaskDue =
    tab !== null && hours.length > 0 ? checkTaskDue(hours[tab]) : null;
  const activity =
    tab !== null && tasks?.activity && hours.length > 0
      ? tasks.activity[hours[tab]]
      : [];
  const [pastResponse, updatePastResponse] = useState(null);
  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    if (!tasks || tab !== null) {
      return;
    }
    const urlTimeParam = match.params.time;
    if (!urlTimeParam) {
      setTab(0);
      return;
    }
    for (let i = 0; i < hours.length; i++) {
      const hourString = displayTime(hourToDate(hours[i]), 'HHmm');
      if (hourString === urlTimeParam) {
        setTab(i);
        return;
      }
    }
    setTab(0);
  }, [tasks]);

  const addAnswer = async (answer) => {
    if (!tasks) {
      return;
    }
    loadingVar(true);
    await addResponse('activity', hourToDate(hours[tab]), {
      didTake: answer,
    });
    loadingVar(false);
    history.replace('/');
  };

  useEffect(() => {
    if (!tasks || !responses) {
      updatePastResponse(null);
      setCanEdit(false);
      return;
    }
    const hour = new Date(hourToDate(hours[tab])).getTime() / 1000;
    const medResponse = responses.find(
      (response) =>
        response.measure === 'activity' &&
        toLocalTime(response.time).unix() === hour
    );
    if (!medResponse?.response) {
      updatePastResponse(null);
      setCanEdit(true);
      return;
    }
    updatePastResponse(medResponse.response.didTake);
    if (isTaskDue && !medResponse.response) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
  }, [responses, tab, tasks]);

  const handleEdit = () => {
    setCanEdit(true);
  };

  return (
    <MainLayout modifiers={['whiteBg']}>
      <Header>
        <Header.Left>
          <Row modifiers={['middle']}>
            <BackButton />
            <Text modifiers={['subtitle', 'subtitle', 'semiBold']}>
              Activity
            </Text>
          </Row>
        </Header.Left>
        <Header.Center />
        <Header.Right>
          <Text modifiers={['primary', 'subtitle', 'semiBold']}>
            {isTaskDue === false ? (
              <Text modifiers={['danger', 'subtitle', 'semiBold']}>
                Not Due - {hours[tab]}, {moment().format('MM/DD/YYYY')}
              </Text>
            ) : (
              <TimeLabel />
            )}
          </Text>
        </Header.Right>
      </Header>
      <Content>
        <Container>
          <Tabs>
            <Tabs.Header>
              <Text modifiers={['white', 'subtitle']}>Activity</Text>
            </Tabs.Header>
            <Row>
              {hours.map((hour, index) => (
                <Tabs.Tab
                  key={index}
                  onClick={() => setTab(index)}
                  modifiers={[tab === index && 'active']}
                >
                  {hour}
                </Tabs.Tab>
              ))}
            </Row>
          </Tabs>
        </Container>
        <Container>
          <Row modifiers={['center']}>
            <Text modifiers={['darkGrey', 'subtitle']}>
              Please let us know if you have completed the following activity
            </Text>
          </Row>
        </Container>
        {activity.map((act, i) => (
          <Row modifiers={['middle']} key={i}>
            <Column
              modifiers={[
                'col_2',
                'flexBox',
                'verticalContentCenter',
                'center',
              ]}
            >
              <IconWrapper>
                <img src={assets.icons.icWalk} width="18" height="18" alt="" />
              </IconWrapper>
            </Column>
            <Column
              modifiers={[
                'col_10',
                'fluid',
                'flexBox',
                'verticalContentCenter',
              ]}
            >
              <Text modifiers={['subtitle', 'center']}>
                {act.activity}
                {act.frequency?.duration
                  ? ` (${act.frequency.duration} mins)`
                  : ''}
              </Text>
            </Column>
          </Row>
        ))}
      </Content>
      {!canEdit && (
        <Container modifiers={['flexFixed', 'fluid']}>
          <Row modifiers={['withGutters']}>
            <Container
              modifiers={[
                pastResponse === true
                  ? 'backgroundSuccess'
                  : 'backgroundDanger',
              ]}
            >
              <Text modifiers={['block', 'center', 'medium', 'white']}>
                {pastResponse === true
                  ? 'YES - Above Activity was completed'
                  : 'NO - Above Activity was not completed'}
              </Text>
            </Container>
          </Row>
        </Container>
      )}
      <Footer>
        <Column modifiers={['col_2', 'flexBox', 'verticalContentCenter']}>
          <Button
            image={assets.icons.icCloseGrey}
            width={22}
            height={22}
            modifiers={['icon', 'transparent']}
            onClick={() => history.replace('/')}
          />
        </Column>
        {!isTaskDue || canEdit ? (
          <>
            <Column modifiers={['col_5', 'flexBox', 'center']}>
              <Button
                modifiers={[
                  isTaskDue ? 'danger' : 'disabled',
                  'widthSmall',
                  'roundCorner',
                ]}
                onClick={() => addAnswer(false)}
              >
                <Text modifiers={['subtitle', 'white', 'semiBold']}>No</Text>
              </Button>
            </Column>
            <Column modifiers={['col_5', 'flexBox', 'center']}>
              <Button
                modifiers={[
                  isTaskDue ? 'success' : 'disabled',
                  'widthSmall',
                  'roundCorner',
                ]}
                onClick={() => addAnswer(true)}
              >
                <Text modifiers={['subtitle', 'white', 'semiBold']}>Yes</Text>
              </Button>
            </Column>
          </>
        ) : (
          <Column modifiers={['col_5', 'col_offset_5', 'flexBox', 'center']}>
            <Button
              modifiers={['primary', 'widthSmall', 'roundCorner']}
              onClick={handleEdit}
            >
              <Text modifiers={['subtitle', 'white', 'semiBold']}>Edit</Text>
            </Button>
          </Column>
        )}
      </Footer>
    </MainLayout>
  );
};
