import {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { AuthContext } from 'contexts/auth';
import { FETCH_PATIENT_RESPONSES } from 'graphql/queries';

export const ProgressContext = createContext();

export const ProgressContextProvider = ({ children }) => {
  const { me } = useContext(AuthContext);
  const [loadResponsesApi] = useLazyQuery(FETCH_PATIENT_RESPONSES, {
    onCompleted: (data) => {
      if (!data.patientResponses) {
        return;
      }
      const responses = data.patientResponses.responses;
      updateResponses(responses);
      const cpDict = {};
      data.patientResponses?.careplans?.forEach(
        (careplan) => (cpDict[careplan._id] = careplan)
      );
      updatePastCPs(cpDict);
    },
    fetchPolicy: 'no-cache',
  });
  const [responses, updateResponses] = useState([]);
  const [pastCPs, updatePastCPs] = useState({});
  const [backHandler, updateBackHandler] = useState([]);
  const history = useHistory();

  const fetchResponses = async () => {
    await loadResponsesApi();
  };

  const goBack = useCallback(async () => {
    if (backHandler.length === 0) {
      history.replace('/');
    } else {
      const handler = backHandler.pop();
      await handler();
      updateBackHandler([...backHandler]);
    }
  }, [backHandler]);

  const setBackHandler = useCallback(
    (callback) => {
      updateBackHandler([...backHandler, callback]);
    },
    [backHandler]
  );

  useEffect(() => {
    if (!me?._id) {
      updateResponses(null);
    } else {
      fetchResponses();
    }
  }, [me?._id]);

  return (
    <ProgressContext.Provider
      value={{
        responses,
        pastCPs,
        fetchResponses,
        goBack,
        setBackHandler,
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
};

export default ProgressContextProvider;
