import React, { useContext } from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';

import {
  Container,
  Column,
  Row,
  Button,
  Text,
} from '@gaz/gaz-components.public';
import { FormPinInput, FormPasswordInput } from 'common';
import { AuthLayoutV2 } from 'layouts';

import schema from 'utils/schema';
import { useMutation } from '@apollo/client';
import { REGISTER_PATIENT } from 'graphql/mutations';
import { AuthContext } from 'contexts/auth';

const { Title, Content } = AuthLayoutV2;

const createPasswordSchema = Yup.object().shape({
  password: schema.password,
  confirmPassword: schema.confirmPassword,
  pin: schema.pin,
  confirmPin: schema.confirmPin,
});

const initialValues = {
  password: '',
  confirmPassword: '',
  pin: '',
  confirmPin: '',
};

export default function Onboard({ history }) {
  const { updateAuth } = useContext(AuthContext);
  const [registerPatient] = useMutation(REGISTER_PATIENT, {
    onCompleted: async (data) => {
      await updateAuth(data.registerPatient.user, data.registerPatient.token);
      history.push('/');
    },
  });

  const handleSubmit = async (values, { resetForm }) => {
    const timezoneOffset = -new Date().getTimezoneOffset();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    try {
      await registerPatient({
        variables: {
          password: values.password,
          pin: values.pin,
          timezoneOffset,
          timezone,
        },
      });
    } catch (err) {
      resetForm();
    }
  };
  return (
    <AuthLayoutV2>
      <Title>
        <Column modifiers={['col_12']}>
          <Text modifiers={['h2', 'white', 'start', 'block']}>
            Welcome to
            <br />
            Gazuntite Patient
          </Text>
        </Column>
      </Title>
      <Content>
        <Formik
          initialValues={initialValues}
          validationSchema={createPasswordSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <Container>
                <Field
                  name="password"
                  placeholder="Enter your password"
                  label="Password"
                  component={FormPasswordInput}
                />
              </Container>
              <Container>
                <Field
                  name="confirmPassword"
                  placeholder="Confirm your password"
                  label="Confirm Password"
                  component={FormPasswordInput}
                />
              </Container>
              <Container modifiers={['withGutters']}>
                <Field
                  hasLabel
                  placeholder="Create 4 digit code"
                  name="pin"
                  component={FormPinInput}
                  fields={4}
                />
              </Container>
              <Container modifiers={['withGutters']}>
                <Field
                  hasLabel
                  placeholder="Confirm Code"
                  name="confirmPin"
                  component={FormPinInput}
                  fields={4}
                />
              </Container>
              <Container>
                <Row modifiers={['center']}>
                  <Button type="submit">Register</Button>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      </Content>
    </AuthLayoutV2>
  );
}
