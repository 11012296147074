import React, { useEffect, useState, useCallback, useRef } from 'react';
import Picker from '../../Picker/Picker';
import MultiPicker from 'rmc-picker/lib/MultiPicker';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { debounce } from 'lodash';

import 'rmc-picker/assets/index.css';

import FormDateInput from '../FormDateInput';
import Modal from '../FormPicker/Modal';

const moment = extendMoment(Moment);
const locale = 'en';

export const FormBirthdayPicker = (props) => {
  const {
    items,
    valueAsTitle,
    displayFormat = 'MM/DD/Y',
    noOverlay = false,
    children,
    pickerModifiers,
    ...inputProps
  } = props;
  const {
    field: { name, onChange },
    form: { touched },
    placeholder,
  } = inputProps;
  const [showModal, setShowModal] = useState(false);

  const defaultDate = props.field.value
    ? [
        moment(props.field.value).format('MMMM'),
        moment(props.field.value).format('D'),
        moment(props.field.value).format('Y'),
      ]
    : [];

  const [value, setValue] = useState(defaultDate);
  const wheelDeltaData = useRef();

  const createYears = () => {
    const range = moment.range(moment().subtract(99, 'years'), moment());

    return [...range.by('year')]
      .map((momentObject) => {
        return momentObject.format('Y');
      })
      .reverse();
  };
  const createMonths = () => {
    return moment.months(moment.locale(locale));
  };
  const createDays = () => {
    let daysInMonth = moment(value[0] || 'January', 'MMM').daysInMonth();

    if (
      value[2] &&
      moment([value[2]]).isLeapYear() &&
      value[0] === moment.months(moment.locale(locale))[1]
    ) {
      daysInMonth += 1;
    }

    return Array(daysInMonth)
      .fill()
      .map((values, index) => index + 1);
  };

  const convertDate = (date) => {
    if (date.length === 3 && date[0] && date[1] && date[2]) {
      // TODO: verify with Sai if it's possible to send date with differnt formats to api
      return moment(`${date[2]}-${date[0]}-${date[1]}`, 'Y-MMM-DD').format(
        displayFormat
      );
    }

    return '';
  };

  const handleChange = useCallback(
    (e) => {
      if (showModal) {
        setValue(e);
        onChange(name)(convertDate(e));
      }
    },
    [showModal, name, onChange, convertDate]
  );

  const handleToggle = () => {
    setShowModal(!showModal);
  };

  const clearWheelDelta = useCallback(
    debounce(() => {
      if (wheelDeltaData.current) {
        wheelDeltaData.current.delta = 0;
      }
    }, 300),
    []
  );

  const handleWheel = useCallback(
    (event) => {
      const delta = -event.nativeEvent?.wheelDeltaY || 0;
      if (delta === 0) {
        return;
      }
      const picker = event.target.parentElement;
      const multiPicker = picker.parentElement;
      let section;
      let values;
      if (picker === multiPicker.children[0]) {
        section = 0;
        values = createMonths();
      } else if (picker === multiPicker.children[1]) {
        section = 1;
        values = createDays();
      } else if (picker === multiPicker.children[2]) {
        section = 2;
        values = createYears();
      } else {
        return;
      }

      if (
        !wheelDeltaData.current ||
        wheelDeltaData.current.section !== section
      ) {
        wheelDeltaData.current = { delta: 0, section };
      }
      wheelDeltaData.current.delta = wheelDeltaData.current.delta + delta;
      let step = 0;
      if (wheelDeltaData.current.delta >= 120) {
        step = wheelDeltaData.current.delta / 120;
        wheelDeltaData.current.delta = 0;
      } else if (wheelDeltaData.current.delta <= -120) {
        step = -(-wheelDeltaData.current.delta / 120);
        wheelDeltaData.current.delta = 0;
      }

      const wheelStep = Math.max(-3, Math.min(3, Math.round(step)));
      const index = values.indexOf(value[section]);
      const newIndex = Math.min(
        values.length - 1,
        Math.max(0, wheelStep + index)
      );
      if (index !== newIndex) {
        value[section] = values[newIndex];
        setValue([...value]);
      }
      clearWheelDelta();
    },
    [value]
  );

  useEffect(() => {
    if (!touched[name] && value.length === 0 && showModal) {
      handleChange(['January', 1, createYears()[0]]);
    }
  }, [touched, name, showModal, value, handleChange]);

  return (
    <div>
      <FormDateInput
        {...inputProps}
        field={{
          ...inputProps.field,
          value: convertDate(value),
        }}
        onClick={() => setShowModal(true)}
        readOnly
      ></FormDateInput>

      <Modal
        title={valueAsTitle ? convertDate(value) : placeholder}
        show={showModal ? 'yes' : undefined}
        onToggle={handleToggle}
        noMask={noOverlay}
        modifiers={pickerModifiers}
        handleDone={handleToggle}
        onPointerDown={(evt) => {
          evt.target?.setPointerCapture(evt.pointerId);
        }}
        onPointerUp={(evt) => {
          evt.target?.releasePointerCapture(evt.pointerId);
        }}
        onWheel={handleWheel}
      >
        <MultiPicker selectedValue={value} onValueChange={handleChange}>
          <Picker>
            {createMonths().map((value) => {
              return (
                <Picker.Item value={value} key={value}>
                  {value}
                </Picker.Item>
              );
            })}
          </Picker>
          <Picker>
            {createDays().map((value) => {
              return (
                <Picker.Item value={value} key={value}>
                  {value}
                </Picker.Item>
              );
            })}
          </Picker>
          <Picker>
            {createYears().map((value) => {
              return (
                <Picker.Item value={value} key={value}>
                  {value}
                </Picker.Item>
              );
            })}
          </Picker>
        </MultiPicker>
        {children}
      </Modal>
    </div>
  );
};

export default FormBirthdayPicker;
