import { Row, Button, Text, ScrollView } from '@gaz/gaz-components.public';
import styled from 'styled-components';

import { Container } from 'common';
import { buildStyledComponent } from 'style';
import InfoSection from './InfoSection';
import Medication from './Medication';
import Vital from './Vital';
import Activity from './Activity';
import Question from './Question';
import CareTeam from './CareTeam';

const CareplanDetail = buildStyledComponent(
  'CareplanDetail',
  styled(({ careplan, noPatientInfo = false, ...props }) => {
    return (
      <Container
        modifiers={['fluid', 'flexBox', 'fullHeight', 'backgroundWhite']}
        {...props}
      >
        {!noPatientInfo && (
          <Row>
            <InfoSection careplan={careplan} />
          </Row>
        )}
        <ScrollView>
          <Container>
            <Text modifiers={['block', 'medium', 'bold', 'center']}>
              Care Plan
            </Text>
            {/* <CareplanSection type="Appointments" detail={{}} /> */}
            {careplan.content.medication && (
              <Medication medications={careplan.content.medication} />
            )}
            {careplan.content.vital && (
              <Vital vitals={careplan.content.vital} />
            )}
            {careplan.content.activity && (
              <Activity activity={careplan.content.activity} />
            )}
            {careplan.content.wellness && (
              <Question type="WELLNESS" questions={careplan.content.wellness} />
            )}
            {careplan.content.diet && (
              <Question type="DIET" questions={careplan.content.diet} />
            )}
            {careplan.content.careTeam && (
              <CareTeam members={careplan.content.careTeam} />
            )}
          </Container>
        </ScrollView>
      </Container>
    );
  }),
  ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeSmall};
  `
);

export default CareplanDetail;
