import styled from 'styled-components';
import { Container } from '@gaz/gaz-components.public';
import { buildStyledComponent } from 'style';

const styles = ({ theme }) => `
  background-color: #F3F3F4;
  padding: 0;
`;

export default buildStyledComponent(
  'Records_TitleWrapper',
  styled(Container),
  styles
);
