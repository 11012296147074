import React from 'react';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';
import {
  Container,
  Row,
  Column,
  // Button,
  BackButton,
  Text,
} from '@gaz/gaz-components.public';
import { Button } from 'common';

export const HEADER_HEIGHT = 60;

const wrapperStyles = ({ theme }) => `
  box-shadow: 0px -2px 10px rgba(46, 41, 78, 0.08);;
  background-color: ${theme.colors.white};
  height: ${px2rem(HEADER_HEIGHT)};
`;

const HeaderWrapper = buildStyledComponent(
  'Header_Wrapper',
  styled.div,
  wrapperStyles
);

export default function Header({
  showTimer,
  children,
  handleMeet,
  stopAddToNote,
  handleClickBack,
}) {
  // const memberInfo = members?.slice(0, 3).map((member) => ({
  //   avatar: member.photo?.url || assets.images.emptyAvatar,
  //   fullName: `${member.firstName}`,
  // }));
  // const hasMoreMembers = members?.length > 3;
  return (
    <HeaderWrapper>
      <Container modifiers={['fluid', 'noPadding']}>
        <Row modifiers={['middle']}>
          <Column modifiers={['flexBox', 'verticalContentCenter']}>
            <BackButton onClick={handleClickBack} />
          </Column>
          <Column modifiers={['col_7', 'fluid', 'noVerticalPadding']}>
            {children}
          </Column>
          <Column modifiers={['col_3']}>
            <Button
              onClick={handleMeet}
              modifiers={['success_2', 'roundCorner', 'fullWidth']}
            >
              <Text modifiers={['subtitle', 'white']}>Call</Text>
            </Button>
          </Column>
        </Row>
      </Container>
    </HeaderWrapper>
  );
}
