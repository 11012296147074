import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  noPadding: () => `
    padding: 0;
    border-radius: 24px 24px 0px 0px;
  `,
  noJustify: () => `
    justify-content: unset;
  `,
  hasBorder: ({ theme }) => `
    border-bottom: solid ${px2rem(1)} ${theme.colors.gray6};
  `,
};

const styles = () => `
  padding: ${px2rem(15)} ${px2rem(20)};
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

export default buildStyledComponent('Header_SlidingPane', styled.div, styles, {
  modifierConfig,
});
