import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { Column, Row, Tabs, Text } from '@gaz/gaz-components.public';
import { AuthLayoutV2 } from 'layouts';
import urls from 'routes/urls';
import { AuthContext } from 'contexts/auth';

import Login from './Login';
import SignUp from './SignUp';

const { Title, Content } = AuthLayoutV2;

// here we can set up routes like recover password
const PATHS = ['/login', '/sign-up'];

export default function Auth({ history }) {
  const [currentTab, setCurrentTab] = useState(0);
  const { path } = useRouteMatch();
  const { me } = useContext(AuthContext);

  const getPathByTab = useCallback(() => {
    return `${path}${PATHS[currentTab]}`;
  }, [currentTab, path]);

  useEffect(() => {
    localStorage.removeItem('_expiredTime');
  }, []);

  useEffect(() => {
    const route = getPathByTab();
    history.push(route);
  }, [currentTab]);

  if (me) {
    return <Redirect to={urls.DASHBOARD} />;
  }

  return (
    <AuthLayoutV2>
      <Title>
        <Column modifiers={['col_12']}>
          <Text modifiers={['h2', 'white', 'start', 'block']}>
            Welcome to
            <br />
            Gazuntite Patient
          </Text>
        </Column>
      </Title>
      <Content>
        <Row modifiers={['middle', 'center', 'withGutters']}>
          <Tabs
            current={currentTab}
            onChange={(newTab) => setCurrentTab(newTab)}
          >
            <Tabs.Tab
              modifiers={['noTab', 'plain', 'h2', 'small']}
              title="Login"
            />
            <Tabs.Tab
              modifiers={['noTab', 'plain', 'h2', 'small']}
              title="Register"
            />
          </Tabs>
        </Row>
        <Switch>
          <Route exact path={`${path}/login`} component={Login} />
          <Route exact path={`${path}/sign-up`} component={SignUp} />
          <Redirect to={urls.DASHBOARD} />
        </Switch>
      </Content>
    </AuthLayoutV2>
  );
}
