import styled from 'styled-components';
import { buildStyledComponent } from 'style';

import { Text } from '@gaz/gaz-components.public';

import { Container } from 'common';
import Call from './Call';

const Wrapper = ({ errorMessage, appointment, ...props }) => (
  <Container {...props}>
    {errorMessage ? (
      <Text modifiers={['large']}>{errorMessage}</Text>
    ) : (
      appointment && <Call appointment={appointment} />
    )}
  </Container>
);

const styles = ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70%;
`;

export default buildStyledComponent('JoinCallWrapper', styled(Wrapper), styles);
