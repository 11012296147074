import { px2rem } from 'style';

const defaultModifiers = {
  pt1: () => `
    padding-top: ${px2rem(5)};
  `,
  pt2: () => `
    padding-top: ${px2rem(10)};
  `,
  pt3: () => `
    padding-top: ${px2rem(15)};
  `,
  pt4: () => `
    padding-top: ${px2rem(20)};
  `,
  pt5: () => `
    padding-top: ${px2rem(25)};
  `,
  py1: () => `
    padding-top: ${px2rem(5)};
    padding-bottom: ${px2rem(5)};
  `,
  py2: () => `
    padding-top: ${px2rem(10)};
    padding-bottom: ${px2rem(10)};
  `,
  py3: () => `
    padding-top: ${px2rem(15)} !important;
    padding-bottom: ${px2rem(15)} !important;
  `,
  py4: () => `
    padding-top: ${px2rem(20)};
    padding-bottom: ${px2rem(20)};
  `,
  py5: () => `
    padding-top: ${px2rem(25)};
    padding-bottom: ${px2rem(25)};
  `,
  my1: () => ({
    styles: `
      margin-top: ${px2rem(10)};
      margin-bottom: ${px2rem(10)};
    `,
  }),
};

export default defaultModifiers;
