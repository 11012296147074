import React from 'react';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';
import { Container } from '@gaz/gaz-components.public';
export const FOOTER_HEIGHT = 66;

const styles = ({ theme }) => `
  box-shadow: 0px -1px 2px rgba(46, 41, 78, 0.02), 0px -4px 8px rgba(46, 41, 78, 0.08);
  background-color: ${theme.colors.white};
  min-height: ${px2rem(FOOTER_HEIGHT)};
`;

const Wrapper = buildStyledComponent('ChatFooter_Wrapper', styled.div, styles);

export default function Footer({ children }) {
  return (
    <Wrapper>
      <Container modifiers={['fluid']}>{children}</Container>
    </Wrapper>
  );
}
