import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const modifierConfig = {
  whiteBg: ({ theme }) => `
    background-color: ${theme.colors.white};
  `,
};

const styles = ({ theme }) => `
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background: #F3F3F4;
`;

const Wrapper = buildStyledComponent('MainLayout_Wrapper', styled.div, styles, {
  modifierConfig,
});

export default Wrapper;
