import styled from 'styled-components';

import { buildStyledComponent } from 'style';

import { Container } from '@gaz/gaz-components.public';

const styles = ({ theme }) => `
  background-color: ${theme.colors.white};
  border-radius: 12px;
  box-shadow: 0px 1px 2px rgba(46, 41, 78, 0.02), 0px 4px 8px rgba(46, 41, 78, 0.08);
`;

export default buildStyledComponent(
  'NoteItem_Wrapper',
  styled(Container),
  styles
);
