import React from 'react';

import Select from 'common/Select';

import Error from './FormError';

export const FormSelect = (props) => {
  const {
    field: { name, value },
    form: { errors, touched, setFieldValue, setFieldTouched },
    required,
    ...restProps
  } = props;

  const hasError = errors[name] && touched[name];

  return (
    <div>
      <Select
        hasError={hasError}
        value={value}
        onBlur={() => setFieldTouched(name)}
        onChange={(newItem) => {
          setFieldValue(name, newItem);
          setFieldTouched(name);
        }}
        {...restProps}
      />

      {hasError && <Error>{errors[name]}</Error>}
    </div>
  );
};

export default FormSelect;
