import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const styles = () => `
  min-width: ${px2rem(45)};
  text-align: right;
`;

const Right = buildStyledComponent(
  'MainLayout_Header_Right',
  styled.div,
  styles
);

export default Right;
