import { useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';

import {
  Text,
  ContentCard,
  Row,
  Column,
  Button,
} from '@gaz/gaz-components.public';

import { Container } from 'common';
import assets from 'assets';
import { PatientContext } from 'contexts/patient';
import { getMeasureProgress } from 'utils/careplan';
import { PROGRESS_ITEMS } from 'utils/constants';

const Item = ({ type, progress }) => {
  const history = useHistory();
  const isNA = progress === null || progress === undefined;

  const handleClick = () => {
    history.push(`/progress/${PROGRESS_ITEMS[type].url}`);
  };
  return (
    <ContentCard modifiers={['withGutters', 'fluid']}>
      <Row modifiers={['middle']} onClick={handleClick}>
        <Column modifiers={['col', 'flexBox', 'verticalContentCenter']}>
          <img src={PROGRESS_ITEMS[type].icon} />
          <Text modifiers={['darkGrey', 'subtitle', 'semiBold']}>
            {PROGRESS_ITEMS[type].title}
          </Text>
        </Column>
        <Column>
          <Row modifiers={['middle', 'spaceBetween']}>
            <Text
              modifiers={[
                isNA ? 'muted' : progress > 60 ? 'success' : 'danger',
                'subtitle',
                'semiBold',
              ]}
            >
              {!isNA ? `${Math.round(progress)}%` : 'N/A'}
            </Text>
            <Button
              modifiers={['icon', 'transparent']}
              image={assets.icons.icGoNextBig}
              width={32}
              height={32}
            />
          </Row>
        </Column>
      </Row>
    </ContentCard>
  );
};

export default () => {
  const { progress, fetchProgress } = useContext(PatientContext);

  const progressValues = useMemo(() => {
    const measureProgress = {
      vital: null,
      medication: null,
      activity: null,
      wellness: null,
      diet: null,
      appointment: null,
    };
    if (!!progress) {
      Object.keys(measureProgress).map((measure) => {
        measureProgress[measure] = getMeasureProgress(progress, measure);
      });
    }
    return measureProgress;
  }, [progress]);

  useEffect(() => {
    if (progress === null || progress === undefined) {
      fetchProgress();
    }
  }, []);

  return (
    <Container modifiers={['fluid', 'fullHeight', 'backgroundWhite']}>
      <Container modifiers={['backgroundGray']}>
        <Row modifiers={['withGutters']}>
          <Text modifiers={['medium', 'bold', 'darkGrey']}>Progress</Text>
        </Row>
      </Container>
      <Container modifiers={['padding_2']}>
        {Object.keys(progressValues).map((measure) => (
          <Item
            progress={progressValues[measure]}
            type={measure}
            key={measure}
          />
        ))}
      </Container>
    </Container>
  );
};
