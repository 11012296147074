import React from 'react';

import FormInput from './FormInput';
import FormIconInput from './FormIconInput';

const normalizeInput = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (cvLength < 3) return currentValue;
  if (cvLength < 5)
    return `${currentValue.slice(0, 2)}/${currentValue.slice(2)}`;
  return `${currentValue.slice(0, 2)}/${currentValue.slice(
    2,
    4
  )}/${currentValue.slice(4, 8)}`;
};

export const FormDateInput = (props) => {
  const {
    field: { name, onChange },
    variant = 'normal',
  } = props;

  const handleChange = (e) => {
    onChange(name)(normalizeInput(e.target.value));
  };

  const Comp = variant === 'iconInput' ? FormIconInput : FormInput;

  return <Comp {...props} onChange={handleChange} />;
};

export default FormDateInput;
