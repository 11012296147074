import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const styles = () => `
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
  background: rgba(59, 76, 106, 0.6);
  backdrop-filter: blur(10px);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default buildStyledComponent('Popover', styled.div, styles);
