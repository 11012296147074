import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import ImageViewer from 'react-simple-image-viewer';

import { Row, Avatar, Checkbox } from '@gaz/gaz-components.public';
import { Column, ChatBubble, Text, PDFViewer } from 'common';
import { buildStyledComponent, px2rem } from 'style';
import { AuthContext } from 'contexts/auth';
import { displayTime } from 'utils/time';

import assets from 'assets';

const playIconStyles = () => `
  position: absolute;
  width: ${px2rem(30)};
  height: ${px2rem(30)};
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
`;

const PlayIcon = buildStyledComponent(
  'MessageItem_PlayIcon',
  styled.img,
  playIconStyles
);

const MessageItem = React.forwardRef(
  ({ message, isSelecting, isSelected, onClickSelect }, ref) => {
    const { me } = useContext(AuthContext);
    const sender = message?.sender;
    const videoRef = useRef(null);
    const imageRef = useRef(null);
    const [imageViewerIsOpen, setImageViewerIsOpen] = useState(false);
    const [pdfviewerIsOpen, setPDFViewerIsOpen] = useState(false);

    const onClickMedia = () => {
      if (videoRef.current) {
        const element = videoRef.current;
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.webkitEnterFullScreen) {
          element.webkitEnterFullScreen();
        }
      }
    };

    const isMe = sender._id === me._id;

    return (
      <Row
        ref={ref}
        modifiers={[isMe && 'reverse', isMe && 'start', 'withGutters']}
      >
        <Column
          style={{ marginTop: px2rem(10) }}
          modifiers={[
            'col_1',
            'fluid',
            'flexBox',
            'verticalEnd',
            !isMe && 'end',
            'noVerticalPadding',
          ]}
        >
          <Avatar width={28} height={28} modifiers={['round']} user={sender} />
        </Column>
        <Column modifiers={['col_10']}>
          {/* <Row
            style={{ marginBottom: px2rem(10) }}
            modifiers={[isMe && 'end']}
          ></Row> */}
          <Row modifiers={[isMe && 'end']}>
            <ChatBubble.Text isMe={isMe} modifiers={[isMe && 'isMe']}>
              {message.attachment?.type === 'image' && !imageViewerIsOpen && (
                <div
                  style={{ position: 'relative' }}
                  onClick={() => setImageViewerIsOpen(true)}
                >
                  <ChatBubble.Image
                    src={message.attachment?.url}
                    ref={imageRef}
                  />
                </div>
              )}
              {message.attachment?.type === 'image' && imageViewerIsOpen && (
                <ImageViewer
                  src={[message.attachment?.url]}
                  currentIndex={0}
                  disableScroll={false}
                  closeOnClickOutside={true}
                  onClose={() => setImageViewerIsOpen(false)}
                />
              )}
              {message.attachment?.type === 'video' && (
                <div style={{ position: 'relative' }} onClick={onClickMedia}>
                  <PlayIcon src={assets.icons.videoPlayer} alt="" />
                  <ChatBubble.Video ref={videoRef}>
                    <source src={message.attachment?.url.concat('#t=0.001')} />
                  </ChatBubble.Video>
                </div>
              )}
              {message.attachment?.type === 'pdf' && pdfviewerIsOpen && (
                <PDFViewer
                  url={message.attachment?.url}
                  setPDFViewerIsOpen={setPDFViewerIsOpen}
                />
              )}

              {message.attachment?.type === 'pdf' && !pdfviewerIsOpen && (
                <div
                  style={{ position: 'relative' }}
                  onClick={() => setPDFViewerIsOpen(true)}
                >
                  <ChatBubble.PDF url={message.attachment?.url} />
                </div>
              )}

              <div style={{ whiteSpace: 'pre-line' }}>{message.text}</div>

              <Text
                modifiers={[
                  'block',
                  'muted',
                  'topPadding',
                  isMe ? 'white' : 'primary',
                  'xSmall',
                  'light',
                  'right',
                ]}
              >
                {`${displayTime(message.createdAt)}`}
              </Text>
            </ChatBubble.Text>
          </Row>
        </Column>
        {isSelecting && (
          <Column modifiers={['verticalContentCenter', 'flexBox']}>
            <Checkbox
              checked={isSelected}
              onChange={onClickSelect(message._id)}
            />
          </Column>
        )}
      </Row>
    );
  }
);

export default MessageItem;
