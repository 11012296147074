import loader from './home_loader.svg';

import addAttachment from './add_new_attachment.svg';
import addParticipant from './add_new_participants_icon.svg';
import addImage from './add_photo_camera.svg';
import addNote from './add_to_note_new_icon.svg';
import addAppointment from './calendar_appointment_blue.svg';
import addFromPhotoLibrary from './photo_library.svg';

import iconBack from './icon_back.svg';
import iconMenu from './icon_menu.svg';
import iconSearch from './icon_search.svg';
import iconFilter from './IcFilter.svg';
import iconPlusMain from './icon_plus_main.svg';
import logoIcon from './logo_icon.png';

import passwordInput from './password_input.svg';
import emailIcon from './email_icon.svg';

import searchBox from './search_box.svg';
import searchBoxDisabled from './search_box_disabled.svg';

import close from './close.svg';
import close2 from './close2.png';
import decline from './decline.svg';
import blueIks from './blue_iks.svg';
import iks from './iks.svg';
import iks2 from './iks2.svg';

import footerCall from './call.png';
import footerMessages from './message.png';

import user from './user.svg';
import userGroup3 from './user_group_3.png';
import userGroup5 from './user_group_5.png';
import patient from './patient.png';
import personIcon from './person_icon.svg';
import phoneIcon from './phone_icon.svg';

import grid from './grid.svg';
import list from './list.svg';
import list2 from './list2.svg';

import photoCamera from './photo_camera.svg';
import arrowDown from './arrow_down.png';
import arrowLeft from './arrow_left.svg';
import arrowLeftView from './arrow-left.svg';
import arrowRightView from './arrow-right.svg';
import circleBlackMinus from './circle-black-minus.svg';
import circleBlackPlus from './circle-black-plus.svg';

import timerPlay from './timer_play.png';
import timerPause from './timer_pause.png';
import timerClose from './timer_close.png';

import trash from './trash.svg';

import borderedPlus from './plus_bordered.png';
import circlePlus from './circle_plus.svg';
import iconNote from './IcRoundEditNote.svg';
import iconAlert from './alert.png';
import iconCall from './call.png';
import iconHeart from './heart.png';
import iconMeds from './meds.png';
import iconMessage from './message.png';
import iconRecord from './IcRecords.svg';
import iconReport from './report.png';
import iconSchedule from './schedule.png';
import videoPlayer from './video-player.svg';
import icBloodPressure from './blood_pressure.svg';
import icWeight from './weight.svg';
import icEdit from './IcEdit.svg';
import icHeart from './heart_rate.svg';
import icHamburger from './IcHamburger.svg';
import icGoNextRound from './IcGoNextRound.svg';
import icAddUser from './IcAddUser.svg';
import icPlus from './IcPlus.svg';
import icAlertGold from './IcAlertGold.svg';
import icScheduleGold from './IcScheduleGold.svg';
import icContactRed from './IcContactRed.svg';
import icAlertRed from './IcAlertRed.svg';
import icReportGold from './IcReportGold.svg';
import icMessageRed from './IcMessageRed.svg';
import icGoNextBig from './IcGoNextBig.svg';
import icFabMessaging from './IcFabMessaging.svg';
import icChatPlus from './IcChatPlus.svg';
import shapeChatBubbleR from './ShapeChatBubbleRight.svg';
import shapeChatBubbleL from './ShapeChatBubbleLeft.svg';
import icClosePlain from './IcClosePlain.svg';
import icGroupPlus from './IcGroupPlus.svg';
import icAttachmentRed from './IcAttachRed.svg';
import icBloodPressurePrimary from './IcBloodPressurePrimary.svg';
import icHeartRatePrimary from './IcHeartRatePrimary.svg';
import icWeightPrimary from './IcWeightPrimary.svg';
import icCloseGrey from './IcCloseGrey.svg';
import icMedsPrimary from './IcMedsPrimary.svg';
import icWalk from './IcWalk.svg';
import icLeg from './IcLeg.svg';
import icBreath from './IcBreath.svg';
import icSleep from './IcSleep.svg';
import icEnergy from './IcEnergy.svg';
import icRecordsGrey from './IcRecordsGrey.svg';
import icReportsGrey from './IcReportsGrey.svg';
import icAlertsGrey from './IcAlertsGrey.svg';
import icVideoGrey from './IcVideoGrey.svg';
import icCameraGrey from './IcCameraGrey.svg';
import icAttachmentGrey from './IcAttachmentGrey.svg';
import icPictureGrey from './IcPictureGrey.svg';
import icCloseRed from './IcCloseRed.svg';
import icAcceptGreen from './IcAcceptGreen.svg';
import icSendMessage from './IcSendMessage.svg';
import icCallExpand from './IcCallExpand.svg';
import icMore from './IcMore.svg';
import icDownChevron from './IcDownChevron.svg';
import icOxygen from './oxygen.svg';
import icGlucose from './glucose.svg';
import icHeartRate from './heart_rate.svg';
import icBreathRate from './breath_rate.svg';
import icTemperature from './temperature.svg';
import icInvitation from './IcInvitation.svg';
import icCloseWhite from './IcCloseWhite.svg';

import icCallCamera from './icCallCamera.png';
import icCallCameraMuted from './icCallCameraMuted.png';
import icCallMic from './icCallMic.png';
import icCallMicMuted from './icCallMicMuted.png';
import icCallMinimize from './icCallMinimize.png';
import icCallMaximize from './icCallMaximize.png';
import icCallBlue from './IcCallBlue.svg';

import icNoteItem from './icon_note_item.png';

import icMedication from './icon_cp_medication.png';
import icVital from './icon_cp_vital.png';
import icWellness from './icon_cp_wellness.png';
import icDiet from './icon_cp_diet.png';
import icActivity from './icon_cp_activity.png';
import icCareTeam from './icon_cp_careteam.png';
import icEye from './IcEye.svg';
import icShield from './IcShield.svg';

import chevronR from './ChevronR.svg';
import icChevronRight from './IcChevronRight.svg';
import icChevronDown from './icChevronDown.svg';
import icChevronDownGray from './icChevronDownGray.svg';

import icWellnessSleep from './icWellnessSleep.svg';
import icWellnessBreathing from './icWellnessBreathing.svg';
import icWellnessEnergy from './icWellnessEnergy.svg';
import icWellnessMemory from './icWellnessMemory.svg';
import icWellnessDizziness from './icWellnessDizziness.svg';
import icWellnessChestPain from './icWellnessChestPain.svg';
import icWellnessWoundHealing from './icWellnessWoundHealing.svg';
import icWellnessSwelling from './icWellnessSwelling.svg';

import icDietSalt from './icDietSalt.svg';
import icDietGrains from './icDietGrains.svg';
import icDietGrocery from './icDietGrocery.svg';
import icDietCooking from './icDietCooking.svg';
import icDietVegs from './icDietVegs.svg';
import icDietFat from './icDietFat.svg';
import icDietMeal from './icDietMeal.svg';
import icDietNounGrain from './icDietNounGrain.svg';

import icPerson from './IcPerson.svg';
import icDoc from './IcDoc.svg';
import icPrivacy from './IcPrivacy.svg';
import icPaper from './IcPaper.svg';

import icPdf from './IcPdf.svg';
import icPng from './IcPng.svg';

import qrCode from './qr_code.svg';

const icons = {
  loader,
  iconBack,
  iconMenu,
  iconSearch,
  iconPlusMain,
  searchBox,
  searchBoxDisabled,
  footerCall,
  footerMessages,
  close,
  close2,
  decline,
  blueIks,
  iks,
  iks2,
  user,
  userGroup3,
  userGroup5,
  patient,
  list,
  list2,
  grid,
  photoCamera,
  trash,
  arrowDown,
  timerPlay,
  timerPause,
  timerClose,
  borderedPlus,
  circlePlus,
  iconNote,
  iconAlert,
  iconCall,
  iconHeart,
  iconMeds,
  iconMessage,
  iconRecord,
  iconReport,
  iconSchedule,
  qrCode,
  passwordInput,
  emailIcon,
  arrowLeft,
  arrowLeftView,
  arrowRightView,
  circleBlackMinus,
  circleBlackPlus,
  logoIcon,
  personIcon,
  phoneIcon,
  videoPlayer,
  addAttachment,
  addParticipant,
  addImage,
  addNote,
  addAppointment,
  addFromPhotoLibrary,
  icBloodPressure,
  icWeight,
  icEdit,
  iconFilter,
  icHeart,
  icHamburger,
  icGoNextRound,
  icAddUser,
  icPlus,
  icAlertGold,
  icScheduleGold,
  icContactRed,
  icAlertRed,
  icReportGold,
  icMessageRed,
  icGoNextBig,
  icFabMessaging,
  icChatPlus,
  shapeChatBubbleR,
  shapeChatBubbleL,
  icClosePlain,
  icGroupPlus,
  icAttachmentRed,
  icBloodPressurePrimary,
  icHeartRatePrimary,
  icWeightPrimary,
  icCloseGrey,
  icMedsPrimary,
  icWalk,
  icLeg,
  icBreath,
  icSleep,
  icEnergy,
  icRecordsGrey,
  icReportsGrey,
  icAlertsGrey,
  icVideoGrey,
  icCameraGrey,
  icAttachmentGrey,
  icPictureGrey,
  icCloseRed,
  icAcceptGreen,
  icSendMessage,
  icCallExpand,
  icCallCamera,
  icCallCameraMuted,
  icCallMic,
  icCallMicMuted,
  icCallMaximize,
  icCallMinimize,
  icCallBlue,
  icMore,
  icDownChevron,
  icGlucose,
  icOxygen,
  icHeartRate,
  icBreathRate,
  icTemperature,
  icInvitation,
  icCloseWhite,
  icMedication,
  icVital,
  icWellness,
  icDiet,
  icActivity,
  icCareTeam,
  icEye,
  icShield,
  chevronR,
  icChevronRight,
  icChevronDown,
  icChevronDownGray,
  icWellnessSleep,
  icWellnessBreathing,
  icWellnessEnergy,
  icWellnessMemory,
  icWellnessDizziness,
  icWellnessChestPain,
  icWellnessWoundHealing,
  icWellnessSwelling,
  icDietSalt,
  icDietGrains,
  icDietGrocery,
  icDietCooking,
  icDietVegs,
  icDietFat,
  icDietMeal,
  icDietNounGrain,
  icPerson,
  icDoc,
  icPrivacy,
  icPaper,
  icPng,
  icPdf,
  icNoteItem,
};

export default icons;
