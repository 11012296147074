import React, { useState } from 'react';
import { useHistory } from 'react-router';

import {
  Text,
  Container,
  Row,
  Column,
  BackButton,
  Switch,
  HR,
  Button,
} from '@gaz/gaz-components.public';
import { MainLayout } from 'layouts';

const { Header, Content } = MainLayout;

export default function CareTeam() {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const history = useHistory();
  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton />
        </Header.Left>
        <Header.Center>
          <Text>Friend &amp; Family</Text>
        </Header.Center>
      </Header>
      <Content>
        <Container>
          <Row>
            <Column modifiers={['col_12']}>
              <Text>
                Adding friends and family to your support team gives each member
                full access to your personal health records, upcoming tasks and
                appointments. Please set up your friends and family team by
                completing the below.
              </Text>
            </Column>
          </Row>
          <Row modifiers={['withGutters']}>
            <Column modifiers={['col_6']}>
              <Text modifiers={['small']}>
                Grant access to friends &amp; family
              </Text>
            </Column>
            <Column modifiers={['col_4', 'col_offset_2']}>
              <Switch active={acceptedTerms} onChange={setAcceptedTerms} />
            </Column>
          </Row>
          {acceptedTerms && (
            <>
              <HR />
              <Row modifiers={['withGutters']}>
                <Column modifiers={['col_11']}>
                  <Text>
                    You currently have no members of your friends and family
                    support team. Please, press Create Team below to begin
                    setting up your support team.
                  </Text>
                </Column>
                <Column modifiers={['col_3', 'col_offset_7']}>
                  <Button
                    onClick={() =>
                      history.push(
                        `/create-group?callbackRoute=${history.location.pathname}`,
                      )
                    }
                    modifiers={['primary', 'widthSmall']}
                  >
                    Create Team
                  </Button>
                </Column>
              </Row>
            </>
          )}
        </Container>
      </Content>
    </MainLayout>
  );
}
