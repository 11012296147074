import React, { useState, useContext, useMemo } from 'react';

import {
  Container,
  Row,
  Column,
  Text,
  ScrollView,
} from '@gaz/gaz-components.public';
import { Tabs, Loader, PeriodPicker } from 'common';

import ListItem from './ListItem';
import { RecordsContext } from '../Context';

const { Tab } = Tabs;

export default () => {
  const [tab, setTab] = useState(0);
  const {
    careplans,
    careplanPeriod: period,
    updateCareplanPeriod: setPeriod,
  } = useContext(RecordsContext);

  const handleTabChange = (newTab) => setTab(newTab);

  const handleSelectPeriod = (value) => {
    setPeriod(value);
  };

  const activeCP = useMemo(() => {
    if (careplans?.length > 0) {
      return careplans.filter((careplan) => careplan.isActive);
    }
    return [];
  }, [careplans]);

  const expiredCPs = useMemo(() => {
    if (careplans?.length > 0) {
      return careplans.filter(
        (careplan) => !careplan.isDraft && !careplan.isActive
      );
    }
    return [];
  }, [careplans]);

  const cps = tab === 0 ? activeCP : expiredCPs;

  return (
    <Container modifiers={['fluid', 'fullHeight', 'flexBox']}>
      <Container modifiers={['flexFixed']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Text modifiers={['bold', 'medium']}>Care Plans list</Text>
          <Column>
            <PeriodPicker value={period} handleSelect={handleSelectPeriod} />
          </Column>
        </Row>
      </Container>
      <Container modifiers={['flexFixed', 'fluid']}>
        <Tabs current={tab} onChange={handleTabChange} flatTabs>
          <Tab title="Active" />
          <Tab title="Expired" />
        </Tabs>
      </Container>
      <ScrollView>
        <Container>
          {cps?.length > 0 ? (
            cps.map((careplan, i) => <ListItem careplan={careplan} key={i} />)
          ) : (
            <Text modifiers={['muted']}>You don't have any careplans</Text>
          )}
        </Container>
      </ScrollView>
    </Container>
  );
};
