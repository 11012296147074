import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';

import {
  Container,
  Row,
  Button,
  FormInput,
  FormPhoneInput,
  Column,
  Modal,
} from '@gaz/gaz-components.public';
import schema from 'utils/schema';

import assets from 'assets';

const validationSchema = Yup.object().shape({
  firstName: schema.firstName,
  middleName: schema.middleName,
  lastName: schema.lastName,
  email: schema.email,
  phone: schema.phone,
});
const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

export default function Invite({ handleClose, handleAdd }) {
  const handleSubmit = (values) => {
    values.firstName = values.firstName.trim();
    values.middleName = values.middleName?.trim() || '';
    values.lastName = values.lastName.trim();
    values.fullName = `${values.firstName} ${values.lastName}`;
    handleAdd(values);
  };

  return (
    <Modal modifiers={['superPriority', 'contained']} contained large open>
      <Container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form style={{ width: '100%' }}>
              <Row modifiers={['withGutters']}>
                <Column modifiers={['col_12']}>
                  <Field
                    name="firstName"
                    hasLabel
                    placeholder="First Name"
                    component={FormInput}
                  />
                </Column>
              </Row>
              <Row modifiers={['withGutters']}>
                <Column modifiers={['col_12']}>
                  <Field
                    name="middleName"
                    hasLabel
                    placeholder="Middle Name"
                    component={FormInput}
                  />
                </Column>
              </Row>
              <Row modifiers={['withGutters']}>
                <Column modifiers={['col_12']}>
                  <Field
                    name="lastName"
                    hasLabel
                    placeholder="Last Name"
                    component={FormInput}
                  />
                </Column>
              </Row>
              <Row modifiers={['withGutters']}>
                <Column modifiers={['col_12']}>
                  <Field
                    name="email"
                    hasLabel
                    placeholder="Email"
                    component={FormInput}
                  />
                </Column>
              </Row>
              <Row modifiers={['withGutters']}>
                <Column modifiers={['col_12']}>
                  <Field
                    name="phone"
                    hasLabel
                    placeholder="Mobile Phone"
                    component={FormPhoneInput}
                  />
                </Column>
              </Row>
              <Row modifiers={['withGutters', 'middle']}>
                <Column modifiers={['col_3', 'flexBox', 'center']}>
                  <Button
                    onClick={handleClose}
                    image={assets.icons.close}
                    modifiers={['transparent', 'icon']}
                  />
                </Column>
                <Column modifiers={['col_6', 'col_offset_3']}>
                  <Button type="submit">Send Invite</Button>
                </Column>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
    </Modal>
  );
}
