import Observable from 'utils/observable';

export const SOCKET_EVENTS = {
  AUTH: 'AUTH',
  CHAT: 'CHAT',
  MEET_NOW: 'MEET_NOW',
  APPOINTMENT_UPDATE: 'APPOINTMENT_UPDATE',
  APPOINTMENTS: 'APPOINTMENTS',
  MEDICATION_ORDERS: 'MEDICATION_ORDERS',
  PATIENT_NOTES: 'PATIENT_NOTES',
  PATIENT_CAREPLANS: 'PATIENT_CAREPLANS',
  PATIENT_MEDICATION_ORDERS: 'PATIENT_MEDICATION_ORDERS',
  ALERTS_UPDATE: 'ALERTS_UPDATE',
  DEVICE_INPUTS_UPDATE: 'DEVICE_INPUTS_UPDATE',
};

const socketBaseUri = process.env.REACT_APP_SOCKET;

class SocketClient {
  constructor() {
    if (!SocketClient.instance) {
      this.connectionInterval = null;
      this.timeout = 250;
      this.pendingMessages = [];
      this.token = null;
      this.socket = null;
      this.observable = new Observable();
      SocketClient.instance = this;
    }

    return SocketClient.instance;
  }

  connect(token) {
    let needNewConnection = false;
    if (
      this.token !== token ||
      !this.socket ||
      (this.socket.readyState !== WebSocket.OPEN &&
        this.socket.readyState !== WebSocket.CONNECTING)
    ) {
      needNewConnection = true;
    }
    if (!needNewConnection) {
      return;
    }
    this.disconnect();

    this.token = token;
    this._connect();
  }

  disconnect() {
    this.token = null;
    this.pendingMessages = [];
    this._disconnect();
  }

  _disconnect() {
    if (this.socket) {
      this.socket.close();
    }
  }

  subscribe(eventType, listener) {
    const a = this.observable.subscribe(eventType, listener);
    return a;
  }

  send(eventType, data) {
    const payload = JSON.stringify({ type: eventType, data });
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      this.pendingMessages.push(payload);
    } else {
      try {
        this.socket.send(payload);
      } catch (error) {
        console.error('Error sending socket data: ', error.message);
        // this.pendingMessages.push({ type, data });  // in case of pushing fallback message send for next connection
      }
    }
  }

  _connect() {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      if (this.connectionInterval) {
        clearTimeout(this.connectionInterval);
        this.connectionInterval = null;
        this.timeout = 250;
      }
      return;
    }

    try {
      this.socket = new WebSocket(`${socketBaseUri}?token=${this.token}`);

      this.socket.onopen = () => {
        if (this.connectionInterval) {
          clearTimeout(this.connectionInterval);
          this.connectionInterval = null;
        }
      };

      this.socket.onclose = (e) => {
        if (this.connectionInterval) {
          clearTimeout(this.connectionInterval);
          this.connectionInterval = null;
        }
        if (!this.token) {
          this.timeout = 250;
          return;
        }
        if (!this.timeout || this.timeout < 250) {
          this.timeout = 250;
        }
        this.timeout = Math.min(10000, this.timeout * 2);
        this.connectionInterval = setTimeout(
          this._connect.bind(this),
          this.timeout
        );
      };

      this.socket.onerror = (error) => {
        console.error(
          'Socket encountered error: ',
          error.message,
          'Closing socket'
        );
        this.socket.close();
      };

      this.socket.onmessage = ({ data }) => {
        try {
          const payload = JSON.parse(data);

          if (payload.type === SOCKET_EVENTS.AUTH) {
            this.timeout = 250;
            this._sendPendingMessages();
          }

          this.observable.publish(payload.type, payload.data);
        } catch (error) {
          console.error('Error receiving socket message - ', error.message);
        }
      };
    } catch (error) {
      console.error('Socket error - ', error);
    }
  }

  _sendPendingMessages() {
    while (this.pendingMessages && this.pendingMessages.length > 0) {
      const payload = this.pendingMessages[0];
      this.pendingMessages.splice(0, 1);
      try {
        this.socket.send(payload);
      } catch (e) {
        console.error('Error sending pending messages', e.message);
        return;
      }
    }
  }
}

const instance = new SocketClient();

export default instance;
