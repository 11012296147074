import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { useHistory } from 'react-router';

import { MainLayout } from 'layouts';
import {
  BackButton,
  Text,
  Container,
  SearchBar,
} from '@gaz/gaz-components.public';
import { PeriodPicker } from 'common';
import { useFab } from 'contexts/fab';

import assets from 'assets';

import LastMessage from './LastMessage';

const { Header, Content } = MainLayout;

export default function Messaging() {
  const fab = useFab();
  const [period, setPeriod] = useState('all');
  const history = useHistory();

  useEffect(() => {
    if (fab.hidden) {
      fab.showFab();
      fab.setIcon(assets.icons.icFabMessaging);
      // fab.setAction(() =>
      //   history.push('/new-chat'),
      // );
    }
  }, [fab]);

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton />
        </Header.Left>
        <Header.Center>
          <Text modifiers={['semiBold']}>Messaging</Text>
        </Header.Center>
        <Header.Right>
          <PeriodPicker
            value={period}
            handleSelect={(value) => setPeriod(value)}
            buttonWidth="widthXSmall"
            buttonLeftText
            buttonLightPrimary
            icon={assets.icons.iconFilter}
          />
        </Header.Right>
      </Header>
      <Content>
        <Container>
          <SearchBar placeholder="Search" autoFocus={false} />
        </Container>
        <Container>
          <LastMessage />
          <LastMessage />
        </Container>
      </Content>
    </MainLayout>
  );
}
