import styled from 'styled-components';
import { Container } from '@gaz/gaz-components.public';
import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  open: () => `
    transition 0.3s;
    transform: translateY(10%);
  `,
};

const styles = () => `
  transition 0.3s;
  transform: translateY(-100%);
  background: rgba(0, 0, 0, 0.36);
  backdrop-filter: blur(16px);
  border-radius: ${px2rem(12)};
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 4.44%;
  width: 91.22%;
`;

export default buildStyledComponent(
  'IncomingCall_Wrapper',
  styled(Container),
  styles,
  {
    modifierConfig,
  },
);
