import { useEffect, useState, useCallback, useContext } from 'react';
import {
  ScrollView,
  Column,
  Text,
  Button,
  BackButton,
  DatePicker,
  Avatar,
} from '@gaz/gaz-components.public';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';

import { Container, Row, Modal } from 'common';
import { displayTime } from 'utils/time';
import { getFullName } from 'utils/string';
import { loadingVar } from 'graphql/cache';
import { FETCH_AVAILABLE_TIMES } from 'graphql/queries';
import { ProfileRow } from 'pages/Appointments/BookWrapper';
import ProviderSelect from 'pages/Appointments/ProviderSelect';
import assets from 'assets';
import { AuthContext } from 'contexts/auth';
import { formatTitleAndName } from 'utils/string';

export default function BookAppointment({ handleClose, handleDone }) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSlot, setSelectedSlot] = useState();
  const [provider, setProvider] = useState();
  const [showProviderSelect, setShowProviderSelect] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const { me } = useContext(AuthContext);

  const handleSelectProvider = useCallback((provider) => {
    setProvider(provider);
  }, []);

  const handleChangeDate = useCallback((date) => {
    setSelectedDate(date);
  }, []);

  const [fetchAvailableSlots] = useLazyQuery(FETCH_AVAILABLE_TIMES, {
    onCompleted: ({ availableTimes }) => {
      if (!availableTimes) {
        return;
      }
      const slots = availableTimes.map((range) => {
        const start = new Date(range.start);
        const end = new Date(range.end);
        return {
          time: start,
          end,
          text: displayTime(start, 'hh:mm a'),
        };
      });
      setTimeSlots(slots);
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    async function fetchData() {
      setSelectedSlot(null);
      setTimeSlots([]);
      if (!provider || !selectedDate) {
        return;
      }
      loadingVar(true);
      const midnightTimestamp = new Date(
        displayTime(selectedDate, 'date')
      ).getTime();
      const from = new Date(Math.max(midnightTimestamp, new Date().getTime()));
      const to = new Date(midnightTimestamp + 24 * 3600 * 1000);
      const offset = moment().utcOffset();
      await fetchAvailableSlots({
        variables: { provider: provider._id, from, to, offset },
      });
      loadingVar(false);
    }
    fetchData();
  }, [provider, selectedDate, fetchAvailableSlots]);

  const handleAdd = () => {
    if (!provider) {
      Modal.info({ text: 'Please select provider.' });
      return;
    }
    if (!selectedSlot) {
      Modal.info({ text: 'Please select avaiable time' });
      return;
    }
    handleDone(provider, selectedSlot.time);
  };

  return (
    <Modal open>
      <Modal.Header>
        <Row modifiers={['middle']}>
          <BackButton onClick={handleClose} />
          <Avatar user={me} width={40} height={40} modifiers={['squared']} />
          <Column modifiers={['col_8', 'flexBox']}>
            <Text modifiers={['semiBold', 'start']}>{getFullName(me)}</Text>
          </Column>
        </Row>
      </Modal.Header>
      <Modal.Body modifiers={['noMargin', 'textLeft']}>
        <Container modifiers={['fullHeight', 'flexBox', 'fluid']}>
          <Container modifiers={['flexFixed']}>
            <Row modifiers={['smallGutters']}>
              <Text modifiers={['block', 'h2']}>Select Provider</Text>
            </Row>
            <ProfileRow onClick={() => setShowProviderSelect(true)}>
              <Row modifiers={['spaceBetween']}>
                {provider ? (
                  <Text modifiers={['primary', 'medium']}>
                    {formatTitleAndName(provider)}
                  </Text>
                ) : (
                  <Text modifiers={['medium', 'dark']}>
                    Press to select provider
                  </Text>
                )}
                <img src={assets.icons.icChevronDownGray} />
              </Row>
            </ProfileRow>
          </Container>
          <Container modifiers={['flexFixed']}>
            <Row>
              <Text modifiers={['block', 'h2']}>Select Date and Time</Text>
            </Row>
            <DatePicker
              handleChange={handleChangeDate}
              value={selectedDate}
              minDate={new Date()}
            >
              <Row>
                <Column modifiers={['col_4', 'noPadding']}>
                  <Text modifiers={['block', 'medium', 'center']}>Day</Text>
                </Column>
                <Column modifiers={['col_4', 'noPadding']}>
                  <Text modifiers={['block', 'medium', 'center']}>Date</Text>
                </Column>
                <Column modifiers={['col_4', 'noPadding']}>
                  <Text modifiers={['block', 'medium', 'center']}>Start</Text>
                </Column>
              </Row>
              <Row modifiers={['smallGutters', 'borderGray', 'roundCorner']}>
                <Column modifiers={['col_4']}>
                  <Text modifiers={['block', 'medium', 'center']}>
                    {displayTime(selectedDate, 'dddd')}
                  </Text>
                </Column>
                <Column modifiers={['col_4']}>
                  <Text modifiers={['block', 'medium', 'center', 'primary']}>
                    {displayTime(selectedDate, 'MM/DD/YY')}
                  </Text>
                </Column>
                <Column modifiers={['col_4']}>
                  <Text modifiers={['block', 'medium', 'center']}>
                    {selectedSlot
                      ? displayTime(selectedSlot.time, 'hh:mm a')
                      : '-- --'}
                  </Text>
                </Column>
              </Row>
            </DatePicker>
          </Container>
          <Container modifiers={['flexFixed']}>
            <Text modifiers={['block', 'h2']}>Available time slots</Text>
          </Container>
          <ScrollView>
            <Row>
              {timeSlots?.length > 0 ? (
                timeSlots.map((slot, index) => (
                  <Column modifiers={['col_4', 'noPadding']} key={index}>
                    <Row modifiers={['topGutters_1', 'center']}>
                      <Button
                        modifiers={[
                          slot.time === selectedSlot?.time
                            ? null
                            : 'outlinePrimary',
                          'roundCorner',
                          'small',
                        ]}
                        onClick={() => setSelectedSlot(slot)}
                        key={slot.time}
                      >
                        {slot.text}
                      </Button>
                    </Row>
                  </Column>
                ))
              ) : (
                <Text modifiers={['medium']}>No appointment available</Text>
              )}
            </Row>
          </ScrollView>
          <Container modifiers={['flexFixed', 'footer']}>
            <Row modifiers={['spaceBetween']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGrey}
                onClick={handleClose}
              />
              <Button
                modifiers={[
                  'primary',
                  'roundCorner',
                  !selectedSlot && 'disabled',
                ]}
                onClick={handleAdd}
              >
                Done
              </Button>
            </Row>
          </Container>
        </Container>
        {showProviderSelect !== null && (
          <Container modifiers={[!showProviderSelect && 'noDisplay']}>
            <ProviderSelect
              handleClose={() => setShowProviderSelect(false)}
              handleSelect={handleSelectProvider}
            />
          </Container>
        )}
      </Modal.Body>
    </Modal>
  );
}
