import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import urls from 'routes/urls';

const NavMenuContext = createContext();

export function NavMenuProvider({ children }) {
  const location = useLocation();
  const [hidden, setHidden] = useState(true);

  const showMenu = () => setHidden(false);

  const hideMenu = () => setHidden(true);

  useEffect(() => {
    if (location.pathname !== urls.DASHBOARD) {
      hideMenu();
    } else {
      showMenu();
    }
  }, [location.pathname]);

  return (
    <NavMenuContext.Provider
      value={{
        hidden,
        showMenu,
        hideMenu,
      }}
    >
      {children}
    </NavMenuContext.Provider>
  );
}

export function useNavMenu() {
  return useContext(NavMenuContext);
}
