import styled from 'styled-components';

import {
  buildStyledComponent,
  px2rem,
} from '@gaz/gaz-components.styled-builder';

const modifierConfig = {
  noTopMargin: () => `
    margin-top: 0;
  `,
  noMargin: () => `
    margin-top: 0;
    margin-bottom: 0;
  `,
  flex: () => `
    display: flex;
  `,
};

const styles = ({ theme }) => `
  width: 100%;
  flex: 1;
  overflow-y: auto;
  text-align: center;
  margin-top: ${px2rem(15)};
  margin-bottom: ${px2rem(15)};
  font-size: ${theme.dimensions.fontSizeSmall};
`;

export default buildStyledComponent('Modal_Body', styled.div, styles, {
  modifierConfig,
});
