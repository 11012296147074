import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react';
import moment from 'moment';

import { Row, Column, Text, Button } from '@gaz/gaz-components.public';

import { displayTime } from 'utils/time';
import { formatTitleAndName } from 'utils/string';
import { CallServiceContext, CALL_TYPES } from 'contexts/call';
import { AuthContext } from 'contexts/auth';

export default ({ appointment }) => {
  const [canStart, updateCanStart] = useState(false);
  const timeoutRef = useRef();
  const { startCall, hasOngoingCall, callRef } = useContext(CallServiceContext);
  const { me } = useContext(AuthContext);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (appointment.status === 'active') {
      updateCanStart(true);
      return;
    }
    if (appointment.status === 'scheduled') {
      const startTime = moment(appointment.time).subtract(1, 'm');
      const secondsToStart = startTime.diff(moment(), 'seconds');
      if (secondsToStart <= 0) {
        updateCanStart(true);
      } else {
        timeoutRef.current = setTimeout(() => {
          updateCanStart(true);
          timeoutRef.current = null;
        }, secondsToStart * 1000);
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [appointment]);

  const joinCall = useCallback(() => {
    startCall(CALL_TYPES.SCHEDULED, appointment.provider, {
      appointment,
    });
  }, [appointment]);

  return (
    <Row modifiers={['middle']}>
      <Column modifiers={['col_3', 'flexBox', 'center']}>
        <Text modifiers={['primary', 'subtitle', 'normalWeight', 'center']}>
          {displayTime(appointment.time, 'hh:mm a')}
        </Text>
      </Column>
      <Column modifiers={['col_6']}>
        <Text modifiers={['subtitle', 'mediumWeight', 'darkGrey']}>
          {formatTitleAndName(appointment.provider)} &gt;
        </Text>
      </Column>
      {hasOngoingCall && callRef.current?.appointment === appointment._id ? (
        <Column modifiers={['col_3']}>
          <Row modifiers={['end']}>
            <Button
              modifiers={['info', 'small', 'roundCorner']}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              On Call
            </Button>
          </Row>
        </Column>
      ) : (
        canStart && (
          <Column modifiers={['col_3']}>
            <Row modifiers={['end']}>
              {appointment.status === 'scheduled' && (
                <Button
                  modifiers={['success', 'small', 'roundCorner']}
                  onClick={joinCall}
                >
                  Start
                </Button>
              )}
              {appointment.status === 'active' && (
                <Button
                  modifiers={['primary', 'small', 'roundCorner']}
                  onClick={joinCall}
                >
                  {appointment.joined?.includes(me._id) ? 'Rejoin' : 'Join'}
                </Button>
              )}
            </Row>
          </Column>
        )
      )}
    </Row>
  );
};
