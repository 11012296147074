import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  selected: ({ theme }) => `
    border: ${px2rem(0.5)} solid ${theme.colors.white};
    color: ${theme.colors.white};
    background-color: ${theme.colors.primary};
  `,
  blue: ({ theme }) => `
    color: ${theme.colors.white};
    background: #42A5F5;
    border-radius: ${px2rem(4)};
    border: none;
    font-size: 11px;
    text-align: center;
  `,
  success: ({ theme }) => `
    background-color: ${theme.colors.success};
    border-radius: ${px2rem(4)};
    border: none;
    font-size: 11px;
    text-align: center;
    color: ${theme.colors.white};
  `,
  purple: ({ theme }) => `
    background-color: #AC5DD9;
    border-radius: ${px2rem(4)};
    border: none;
    font-size: 11px;
    text-align: center;
    color: ${theme.colors.white};
  `,
  fullWidth: () => `
    width: 100%;
  `,
};

const styles = ({ theme }) => `
  color: ${theme.colors.primary};
  border: ${px2rem(0.5)} solid ${theme.colors.primary};
  border-radius: ${px2rem(10)};
  font-family: ${theme.fonts.secondary};
  padding: ${px2rem(5)};
`;

export const Tag = buildStyledComponent('Tag', styled.div, styles, {
  modifierConfig,
});
