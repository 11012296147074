import React from 'react';
import { useHistory } from 'react-router-dom';

import assets from 'assets';
import { Button } from '@gaz/gaz-components.public';

export const BackButton = ({ onClick }) => {
  const history = useHistory();

  return (
    <Button
      modifiers={['icon', 'transparent']}
      image={assets.icons.iconBack}
      onClick={onClick || history.goBack}
    >
      back
    </Button>
  );
};

export default BackButton;
