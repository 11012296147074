import React, { useEffect, useState, useCallback } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { Row, Column, Button } from '@gaz/gaz-components.public';
import assets from 'assets';
import { elementHasClass } from 'utils/helper';
import Wrapper from './Wrapper';
import Label from './Label';
import Segment from './Segment';
import Dropdown from './Dropdown';

const moment = extendMoment(Moment);
const locale = 'en';

export const BirthdayPicker = (props) => {
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [day, setDay] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const { label, onChange, onClose } = props;

  const createYears = () => {
    const range = moment.range(moment().subtract(99, 'years'), moment());

    return [...range.by('year')]
      .map((momentObject) => {
        return momentObject.format('Y');
      })
      .reverse();
  };
  const createMonths = () => {
    return moment.months(moment.locale(locale));
  };
  const createDays = () => {
    let daysInMonth = moment(month || 'January', 'MMM').daysInMonth();

    if (
      year &&
      moment([year]).isLeapYear() &&
      month === moment.months(moment.locale(locale))[1]
    ) {
      daysInMonth += 1;
    }

    return Array(daysInMonth)
      .fill()
      .map((values, index) => index + 1);
  };

  // const handleSearch = () => {
  //   setShowDropdown(false);
  // };

  const reset = () => {
    setDay(null);
    setMonth(null);
    setYear(null);
    setButtonDisabled(true);
  };

  const updateShowDropdown = useCallback(
    (value) => {
      if (!value && (!day || !month || !year)) {
        reset();
        onChange(null);
        onClose(null);
      } else if (!value) {
        onClose(
          moment(`${year}-${month}-${day}`, 'Y-MMM-DD').format('MM/DD/YYYY'),
        );
      }

      setShowDropdown(value);
    },
    [day, month, year, onChange, onClose],
  );

  const handleReset = () => {
    reset();
    onChange(null);
    onClose(null);
    setShowDropdown(false);
  };

  const handleSelect = (type, value) => {
    if (type === 'day') {
      setDay(value);
    } else if (type === 'month') {
      setMonth(value);
    } else if (type === 'year') {
      setYear(value);
    }

    const date = { day, month, year };
    date[type] = value;
    setButtonDisabled(!date['day'] || !date['month'] || !date['year']);
    if (date['day'] && date['month'] && date['year']) {
      onChange(
        moment(
          `${date['year']}-${date['month']}-${date['day']}`,
          'Y-MMM-DD',
        ).format('DD-MM-Y'),
      );
    } else {
      onChange(null);
    }
  };

  const handleClose = () => {
    updateShowDropdown(false);
  };

  useEffect(() => {
    const handleClick = (event) => {
      const { target } = event;

      if (elementHasClass(target, ['BirthdayPicker_Segment'], 1)) {
        updateShowDropdown(!showDropdown);
      } else if (
        !elementHasClass(target, ['BirthdayPicker_Dropdown'], 4) &&
        showDropdown
      ) {
        updateShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [day, month, year, showDropdown, updateShowDropdown]);

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Segment
        label={'month'}
        value={month && month.slice(0, 3)}
        onClick={() => setShowDropdown(true)}
        active={month && true}
      ></Segment>
      <Segment
        label={'day'}
        value={day}
        onClick={() => setShowDropdown(true)}
        active={day && true}
      ></Segment>
      <Segment
        label={'year'}
        value={year}
        onClick={() => setShowDropdown(true)}
        active={year && true}
      ></Segment>
      {/* <div>
        {!buttonDisabled && (
          <Button
            modifiers={['transparent', 'icon']}
            image={assets.icons.blueIks}
            // imageDisabled={assets.icons.searchBoxDisabled}
            disabled={true}
            imageWidth={10}
            imageHeight={10}
          >reset</Button>
        )}
      </div> */}
      {showDropdown && (
        <Dropdown>
          <Row modifiers={['center']} className="heading">
            <Column modifiers={['col_5']}>Month</Column>
            <Column modifiers={['col_3']}>Day</Column>
            <Column modifiers={['col_3']}>Year</Column>
          </Row>
          <Row modifiers={['center']}>
            <Column modifiers={['col_5']} className="column">
              {createMonths().map((value) => {
                return (
                  <div
                    key={value}
                    onClick={() => handleSelect('month', value)}
                    className={month === value ? 'active' : ''}
                  >
                    {value}
                  </div>
                );
              })}
            </Column>
            <Column modifiers={['col_3']} className="column">
              {createDays().map((value) => {
                return (
                  <div
                    key={value}
                    onClick={() => handleSelect('day', value)}
                    className={day === value ? 'active' : ''}
                  >
                    {value}
                  </div>
                );
              })}
            </Column>
            <Column modifiers={['col_3']} className="column">
              {createYears().map((value) => {
                return (
                  <div
                    key={value}
                    onClick={() => handleSelect('year', value)}
                    className={year === value ? 'active' : ''}
                  >
                    {value}
                  </div>
                );
              })}
            </Column>
          </Row>
          <Row modifiers={['middle', 'center']}>
            <Column modifiers={['col', 'start', 'flexBox']}>
              <Button
                modifiers={['secondary', 'small', buttonDisabled && 'disabled']}
                onClick={handleReset}
                disabled={buttonDisabled}
              >
                reset
              </Button>
            </Column>
            <Column modifiers={['col', 'end', 'flexBox']}>
              <Button
                modifiers={['transparent', 'icon']}
                image={assets.icons.close}
                imageWidth="30"
                imageHeight="30"
                onClick={handleClose}
              ></Button>
            </Column>
            {/* <Column modifiers={['col_6', 'end', 'flexBox']}>
              <Button
                type="button"
                modifiers={['primary', buttonDisabled && 'disabled']}
                onClick={handleSearch}
                disabled={buttonDisabled}
              >
                Search
              </Button>
            </Column> */}
          </Row>
        </Dropdown>
      )}
    </Wrapper>
  );
};

export default BirthdayPicker;
