import { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import { startCase } from 'lodash';

import { MainLayout } from 'layouts';
import {
  Container,
  Row,
  Column,
  BackButton,
  Text,
  Button,
} from '@gaz/gaz-components.public';
import assets from 'assets';
import { TimeLabel } from 'common';
import Rating from './Rating';
import Tabs from '../Tabs';
import IconWrapper from '../IconWrapper';
import { PatientContext } from 'contexts/patient';
import { checkTaskDue, hourToDate, toLocalTime, displayTime } from 'utils/time';
import { WELLNESS_ITEMS, DIET_ITEMS } from 'utils/constants';
import { loadingVar } from 'graphql/cache';

const { Header, Content, Footer } = MainLayout;

const RATING_OPTIONS = {
  badGood: ['veryBad', 'bad', 'medium', 'good', 'veryGood'],
  lowHigh: ['veryLow', 'low', 'medium', 'high', 'veryHigh'],
  highLow: ['veryHigh', 'high', 'medium', 'low', 'veryLow'],
};

export default ({ type, match }) => {
  const [tab, setTab] = useState(null);
  const history = useHistory();
  const { tasks, addResponse, responses } = useContext(PatientContext);
  const hours = tasks ? Object.keys(tasks[type]) : [];
  const [inputs, updateInputs] = useState({});
  const isTaskDue =
    tab !== null && hours.length > 0 ? checkTaskDue(hours[tab]) : null;
  const questions =
    tab !== null && tasks && hours.length > 0 ? tasks[type][hours[tab]] : [];
  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    if (!tasks || tab !== null) {
      return;
    }
    const urlTimeParam = match.params.time;
    if (!urlTimeParam) {
      setTab(0);
      return;
    }
    for (let i = 0; i < hours.length; i++) {
      const hourString = displayTime(hourToDate(hours[i]), 'HHmm');
      if (hourString === urlTimeParam) {
        setTab(i);
        return;
      }
    }
    setTab(0);
  }, [tasks]);

  const addAnswer = async () => {
    if (!tasks) {
      return;
    }

    loadingVar(true);
    await addResponse(type, hourToDate(hours[tab]), inputs);
    loadingVar(false);

    history.replace('/');
  };

  useEffect(() => {
    if (!tasks || !responses || questions.length === 0) {
      updateInputs({});
      return;
    }
    const hour = new Date(hourToDate(hours[tab])).getTime() / 1000;
    const inputs = {};
    let hasResponse = false;
    questions.forEach((question) => {
      const questionResponse = responses.find(
        (response) =>
          response.measure === type &&
          toLocalTime(response.time).unix() === hour &&
          response.response.type === question.id
      );
      if (!questionResponse) {
        return;
      }
      inputs[questionResponse.response.type] = questionResponse.response.value;
      if (!!questionResponse.response.value) {
        hasResponse = true;
      }
    });
    updateInputs({ ...inputs });
    if (isTaskDue && !hasResponse) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
  }, [responses, tab, tasks]);

  const handleSelect = (id, value) => {
    inputs[id] = value;
    updateInputs({ ...inputs });
  };

  const handleEdit = () => {
    setCanEdit(true);
  };

  return (
    <MainLayout modifiers={['whiteBg']}>
      <Header>
        <Header.Left>
          <Row modifiers={['middle']}>
            <BackButton />
            <Text modifiers={['subtitle', 'subtitle', 'semiBold']}>
              {startCase(type)}
            </Text>
          </Row>
        </Header.Left>
        <Header.Center />
        <Header.Right>
          <Text modifiers={['danger', 'subtitle', 'semiBold']}>
            {isTaskDue === false ? (
              <Text modifiers={['danger', 'subtitle', 'semiBold']}>
                Not Due - {hours[tab]}, {moment().format('MM/DD/YYYY')}
              </Text>
            ) : (
              <TimeLabel />
            )}
          </Text>
        </Header.Right>
      </Header>
      <Content>
        <Container>
          <Tabs>
            <Tabs.Header>
              <Text modifiers={['white', 'subtitle']}>{startCase(type)}</Text>
            </Tabs.Header>
            <Row>
              {hours.map((hour, index) => (
                <Tabs.Tab
                  key={index}
                  onClick={() => setTab(index)}
                  modifiers={[tab === index && 'active']}
                >
                  {hour}
                </Tabs.Tab>
              ))}
            </Row>
          </Tabs>
        </Container>
        <Container>
          <Row>
            {questions.map((question, i) => {
              const questionItem =
                type === 'wellness'
                  ? WELLNESS_ITEMS[question.id]
                  : DIET_ITEMS[question.id];
              return (
                <Column modifiers={['col_12']} key={i}>
                  <Row modifiers={['fluid']}>
                    <Column modifiers={['col_2', 'flexBox', 'center']}>
                      <IconWrapper>
                        <img
                          src={questionItem.icon}
                          alt=""
                          width="18"
                          height="18"
                        />
                      </IconWrapper>
                    </Column>
                    <Column modifiers={['col_10', 'fluid']}>
                      <Text modifiers={['left', 'subtitle']}>
                        {questionItem.title}
                      </Text>
                    </Column>
                    <Column modifiers={['fluid', 'col_12']}>
                      <Rating
                        options={RATING_OPTIONS[questionItem.type || 'badGood']}
                        value={inputs[question.id]}
                        disabled={!isTaskDue || !canEdit}
                        handleSelect={(value) =>
                          handleSelect(question.id, value)
                        }
                      />
                    </Column>
                  </Row>
                </Column>
              );
            })}
          </Row>
        </Container>
      </Content>
      <Footer>
        <Column modifiers={['col_6', 'flexBox', 'verticalContentCenter']}>
          <Button
            image={assets.icons.icCloseGrey}
            width={22}
            height={22}
            modifiers={['icon', 'transparent']}
            onClick={() => history.replace('/')}
          />
        </Column>
        <Column modifiers={['col_5', 'col_offset_1', 'flexBox', 'center']}>
          <Button
            modifiers={[
              isTaskDue ? 'primary' : 'disabled',
              'widthSmall',
              'roundCorner',
            ]}
            onClick={isTaskDue && canEdit ? addAnswer : handleEdit}
          >
            <Text modifiers={['subtitle', 'white', 'semiBold']}>
              {!isTaskDue || canEdit ? 'Done' : 'Edit'}
            </Text>
          </Button>
        </Column>
      </Footer>
    </MainLayout>
  );
};
