import { useState, useMemo } from 'react';

import { ContentCard, ScrollView } from 'common';
import { TIMER_TYPE } from 'utils/constants';
import { formatTitleAndName, getFullName } from 'utils/string';
import { displayTime, secondsToDuration } from 'utils/time';
import { PROGRESS_ITEMS } from 'utils/constants';
import Table from './Table';
import ListItem from './ListItem';
import { getQuestionValueString } from 'utils/helper';

const extractData = (provider, record, item) => {
  const renderType = () => {
    switch (item.type) {
      case TIMER_TYPE.CHAT:
        return 'Message';
      case TIMER_TYPE.CALL:
        const prefix = item.isPSTN ? 'Audio ' : item.scheduled ? 'Appt ' : '';
        const type = item.isPSTN ? 'PSTN' : 'Video';
        return `${prefix}${type}`;
      case TIMER_TYPE.REVIEW:
        return 'Chart Review';
      case TIMER_TYPE.NOTE:
        return 'Create Note';
      case TIMER_TYPE.CAREPLAN:
        return 'Create Careplan';
      case TIMER_TYPE.ORDER_MEDS:
        return 'Order Meds';
      default:
        return '';
    }
  };
  const start = new Date(item.startedAt).getTime();
  const end = new Date(item.endedAt).getTime();
  const duration = parseInt((end - start) / 1000);

  let refString;
  let byString;

  if (['chat', 'call'].includes(item.type)) {
    const participantIds = item.participants.map((p) => p._id);
    byString = item.participants.map((p) => formatTitleAndName(p)).join(' / ');

    if (
      item.referredPatient &&
      !participantIds.includes(item.referredPatient._id)
    ) {
      refString = getFullName(item.referredPatient);
    }
  } else {
    byString = formatTitleAndName(provider);
  }

  return [
    displayTime(record.startedAt, 'MM/DD/YY'),
    displayTime(record.startedAt, 'hh:mm A'),
    renderType(),
    refString ? [byString, refString] : byString,
    secondsToDuration(duration),
  ];
};

const MeasureDetail = ({ data }) => {
  const columns = ['Date', 'Time', 'Task', 'Resp.'];
  const datas = useMemo(() => {
    const datas = [];
    const responses = [...data.responses];
    responses.sort(
      (a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()
    );
    responses.forEach((response) => {
      if (!response.addedTime) {
        return;
      }
      let responseText;
      switch (response.measure) {
        case 'medication':
        case 'activity':
          responseText = response.isPositive ? 'Y' : 'N';
          break;
        case 'wellness':
        case 'diet':
          responseText = getQuestionValueString(
            response.response.type,
            response.response.value
          );
          break;
        case 'vital':
          responseText = response.response.value;
          if (response.response.value2) {
            responseText += `/${response.response.value2}`;
          }
          break;
      }
      datas.push([
        displayTime(response.addedTime || response.time, 'MM/DD/YY'),
        displayTime(response.addedTime || response.time, 'hh:mm A'),
        { type: 'list', items: response.content },
        {
          style: response.isPositive ? 'success' : 'danger',
          title: responseText,
        },
      ]);
    });
    return datas;
  });

  return (
    <>
      <ListItem
        icon={data.icon}
        title={data.title}
        positiveTitle={data.positiveTitle}
        readings={data.readings}
        positivePct={data.positivePct}
      />
      <ScrollView>
        <Table columns={columns} datas={datas} />
      </ScrollView>
    </>
  );
};

export default MeasureDetail;
