import React, { useState } from 'react';

import assets from 'assets';

import { FormIconInput } from '.';

export const FormPasswordInput = (props) => {
  const [revealPwd, setRevealPwd] = useState(false);

  return (
    <FormIconInput
      type={revealPwd ? 'text' : 'password'}
      handleClickIcon={() => setRevealPwd(!revealPwd)}
      hasLabel
      largeLabel
      iconPosition="right"
      icon={assets.icons.icEye}
      {...props}
    />
  );
};

export default FormPasswordInput;
