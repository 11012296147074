import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { Container, Row, Column, Text, HR } from '@gaz/gaz-components.public';
import { IconWrapper } from 'common';
import { buildStyledComponent } from 'style';
import { formatTitleAndName } from 'utils/string';
import { displayTime } from 'utils/time';

import assets from 'assets';

const wrapperStyles = ({ theme }) => `
  background-color: ${theme.colors.white};
  border-radius: 12px;
  box-shadow: 0px 1px 2px rgba(46, 41, 78, 0.02), 0px 4px 8px rgba(46, 41, 78, 0.08);
`;

const Wrapper = buildStyledComponent(
  'DiagnosisItem_Wrapper',
  styled(Container),
  wrapperStyles
);

export default ({ careplan }) => {
  const { push, location } = useHistory();
  return (
    <Wrapper
      onClick={() => push(`${location.pathname}/${careplan._id}`)}
      modifiers={['withGutters']}
    >
      <Row>
        <Column
          modifiers={[
            'col_2',
            'fluid',
            'flexBox',
            'verticalContentCenter',
            'center',
          ]}
        >
          <IconWrapper>
            <img src={assets.icons.icHeart} alt="" with="19" height="16" />
          </IconWrapper>
        </Column>
        <Column modifiers={['col_10', 'fluid']}>
          <Text
            modifiers={[
              careplan?._id &&
                !careplan?.isDraft &&
                !careplan?.isSeen &&
                'bold',
            ]}
          >
            {formatTitleAndName(careplan.creator.user)}
          </Text>
        </Column>
        <Column modifiers={['fluid', 'col_12', 'noVerticalPadding']}>
          <HR modifiers={['muted']} />
        </Column>
        <Column modifiers={['col_12']}>
          <Row modifiers={['my1']}>
            <Text
              modifiers={[
                'noPadding',
                careplan?._id &&
                  !careplan?.isDraft &&
                  !careplan?.isSeen &&
                  'bold',
              ]}
            >
              Patient Name:&nbsp;
            </Text>
            <Text
              modifiers={[
                'primary',
                careplan?._id && !careplan?.isDraft && !careplan?.isSeen
                  ? 'bold'
                  : 'light',
                'noPadding',
              ]}
            >
              {formatTitleAndName(careplan.user)}
            </Text>
          </Row>
          <Row modifiers={['spaceBetween', 'bottom']}>
            <Text
              modifiers={[
                'darkGrey',
                'noPadding',
                'topPadding',
                careplan?._id &&
                  !careplan?.isDraft &&
                  !careplan?.isSeen &&
                  'bold',
              ]}
            >
              {displayTime(
                careplan.isDraft ? careplan.updatedAt : careplan.signDate,
                'hh:mm a, MM/DD/yy'
              )}
            </Text>
          </Row>
        </Column>
      </Row>
    </Wrapper>
  );
};
