import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Container, Row, Column, Text, HR } from '@gaz/gaz-components.public';
import Card from 'common/ListView/Card';
import { secondsToDuration } from 'utils/time';
import { getUser } from 'graphql/utils';

import ReportCardItem from './ReportCardItem';

export default function ReportCard({ record, onLongPress = {} }) {
  const [provider, setProvider] = useState({});

  useEffect(() => {
    (async () => {
      if (record?.provider) {
        const user = await getUser(record?.provider);
        setProvider(user);
      }
    })();
  }, [record?.provider]);

  return record.items.map((item) => (
    <Card {...onLongPress} key={item._id}>
      <Container modifiers={['fluid']}>
        <Row id="heading">
          <Column modifiers={['col_3', 'noPadding']}>
            <Text modifiers={['primary', 'xSmall', 'inline']}>
              {moment(item.startedAt).format('MM/DD/YYYY')}
            </Text>
          </Column>
          <Column modifiers={['col_6', 'noPadding', 'center']}>
            <Text modifiers={['primary', 'xSmall', 'inline']}>
              {moment(item.startedAt).format('hh:mm a')}
            </Text>
            <Text modifiers={['xSmall', 'inline', 'noPadding']}>to</Text>
            <Text modifiers={['xSmall', 'primary', 'inline']}>
              {moment(item.endedAt).format('hh:mm a')}
            </Text>
          </Column>
          <Column modifiers={['col_3', 'noPadding']}>
            <Text modifiers={['xSmall', 'inline']}>dur:</Text>
            <Text modifiers={['xSmall', 'inline', 'primary']}>
              {secondsToDuration(item.duration)}
            </Text>
          </Column>
        </Row>
        <HR />
        <ReportCardItem provider={provider} item={item} />
      </Container>
    </Card>
  ));
}
