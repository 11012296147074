import { startCase } from 'lodash';
import { MEMBER_DESIGNATIONS } from './constants';

export const getFullName = (user) => {
  if (!user) {
    return '';
  }

  return startCase(
    [user.firstName, user.lastName].filter((n) => !!n).join(' ')
  );
};

export const formatMemberDesignation = (key) => {
  const value = MEMBER_DESIGNATIONS[key];

  return value || key;
};

export const formatTitleAndName = (userOrProvider) => {
  const contact = userOrProvider.user ? userOrProvider.user : userOrProvider;
  if (!contact) {
    return '';
  }
  if (!contact.role) {
    return contact.name;
  }
  if (contact.role === 'patient') {
    return getFullName(contact);
  }
  return (
    getFullName(contact) +
    (contact.memberDesignation
      ? `, ${formatMemberDesignation(contact.memberDesignation)}`
      : '')
  );
};

export const formatAddress = (address) => {
  if (address) {
    return `${address.addressLine1}, ${address.city}, ${address.state}, ${address.zipcode}`;
  }

  return '';
};

export const chatTitle = (members, me) => {
  const otherMembers = members.filter((m) => m._id !== me._id);

  const title = otherMembers.map((m) => getFullName(m)).join(', ');

  return title;
};

export const MASK_TYPE = {
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  SSN: 'SSN',
};

export const maskString = (value, type) => {
  if (!value) {
    return '';
  }

  switch (type) {
    case MASK_TYPE.PHONE:
      return `******${value.substr(-4)}`;
    case MASK_TYPE.EMAIL:
      const parts = value.split('@');
      return `${parts[0].substr(0, 2)}***@${parts[1]}`;
    case MASK_TYPE.SSN:
      if (!value || value.length < 10) {
        return 'XXX-XX-XXXX';
      }
      return `XXX-XX-${value.substr(-4)}`;
    default:
      return '';
  }
};
