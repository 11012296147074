import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react';
import moment from 'moment';

import { Container, Row, Text, Button, Column } from 'common';

import { displayTime } from 'utils/time';
import { formatTitleAndName } from 'utils/string';
import { CallServiceContext, CALL_TYPES } from 'contexts/call';

export default ({ appointment }) => {
  const [canStart, updateCanStart] = useState(false);
  const timeoutRef = useRef();
  const { startCall } = useContext(CallServiceContext);
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (appointment.status === 'active') {
      updateCanStart(true);
      return;
    }
    if (appointment.status === 'scheduled') {
      const startTime = moment(appointment.time).subtract(1, 'm');
      const secondsToStart = startTime.diff(moment(), 'seconds');
      if (secondsToStart <= 0) {
        updateCanStart(true);
      } else {
        timeoutRef.current = setTimeout(() => {
          updateCanStart(true);
          timeoutRef.current = null;
        }, secondsToStart * 1000);
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [appointment]);

  const joinCall = useCallback(() => {
    startCall(CALL_TYPES.SCHEDULED, appointment.provider, {
      appointment,
    });
  }, [appointment]);

  return (
    <Container>
      <Container modifiers={['withGutters']}>
        <Row modifiers={['center']}>
          <Text modifiers={['block', 'xLarge', 'center']}>Appointment</Text>
        </Row>
        <Row modifiers={['withGutters']}>
          <Column modifiers={['col_2']}></Column>
          <Column modifiers={['col_8']}>
            <Text modifiers={['block', 'large']}>
              {formatTitleAndName(appointment.provider)}
            </Text>
            <Text modifiers={['block', 'large']}>
              {displayTime(appointment.time, 'MM/DD/yy')}
            </Text>
            <Text modifiers={['block', 'large']}>
              {displayTime(appointment.time, 'hh:mm a')}
            </Text>
          </Column>
        </Row>
      </Container>
      {canStart && (
        <Row modifiers={['center']}>
          <Button modifiers={['success']} onClick={joinCall}>
            Start Video
          </Button>
        </Row>
      )}
    </Container>
  );
};
