import React, { useEffect, useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { noop } from 'lodash-es';
import {
  Container,
  Row,
  BackButton,
  Text,
  Column as Col,
  SearchBar,
  ContentCard,
  Avatar,
  Button,
  Checkbox,
} from '@gaz/gaz-components.public';
import { Modal } from 'common';
import { formatMemberDesignation, formatTitleAndName } from 'utils/string';

import { FETCH_CONTACTS } from 'graphql/queries';
import { SEARCH_DEBOUNCE_TIME } from 'utils/constants';
import assets from 'assets';

import SelectedParticipantCard from './SelectedParticipantCard';

export default function AddParticipant({
  existingMemberIds,
  handleDone,
  handleBack,
}) {
  const [fetchContacts, { data: { contacts = [] } = {}, loading }] =
    useLazyQuery(FETCH_CONTACTS);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const memberLeads = contacts.filter(
    (c) => !existingMemberIds.includes(c._id)
  );

  const handleSearch = useCallback(
    debounce((query) => {
      fetchContacts({
        variables: {
          query,
          category: 'providers',
        },
      });
    }, SEARCH_DEBOUNCE_TIME),
    [fetchContacts]
  );

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const handleClickItem = (record) => {
    if (selectedParticipants.find((selected) => selected._id === record._id)) {
      setSelectedParticipants((alreadySelected) => {
        const newState = alreadySelected.filter(
          (selected) => selected._id !== record._id
        );
        return newState;
      });
    } else {
      setSelectedParticipants((alreadySelected) => [
        ...alreadySelected,
        record,
      ]);
    }
  };

  const handleAdd = () => {
    if (selectedParticipants.length > 0) {
      handleDone(selectedParticipants);
    }
  };

  const isChecked = (participant) => {
    const findParticipant = selectedParticipants.findIndex(
      (selected) => selected._id === participant._id
    );
    return findParticipant > -1;
  };

  return (
    <Modal open>
      <Modal.Header
        style={{ boxShadow: '0px -2px 10px rgba(46, 41, 78, 0.08)' }}
      >
        <Container modifiers={['fluid']}>
          <Row modifiers={['middle']}>
            <BackButton onClick={handleBack} />
            <Text modifiers={['subtitle', 'semiBold']}>Add Participants</Text>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body modifiers={['noTopMargin']}>
        <Container>
          <Row>
            <Text modifiers={['h3']}>Search and add participants</Text>
          </Row>
          <Row>
            <Col modifiers={['col_12']}>
              <SearchBar onChange={handleSearch} />
            </Col>
          </Row>
          {!!selectedParticipants.length && (
            <Row>
              <Text modifiers={['h3', 'mediumWeight', 'block', 'start']}>
                Selected Participants
              </Text>
            </Row>
          )}
          <Row modifiers={['withGutters']}>
            {selectedParticipants.map((participant, i) => (
              <Col modifiers={['col_4']}>
                <Row style={{ height: '100%' }}>
                  <SelectedParticipantCard
                    key={`selected-participants-${i}`}
                    modifiers={['col_12']}
                  >
                    <Row modifiers={['spaceBetween']}>
                      <Avatar
                        modifiers={['squared']}
                        width={40}
                        height={40}
                        user={participant}
                      />
                      <Button
                        onClick={() => handleClickItem(participant)}
                        image={assets.icons.icCloseRed}
                        modifiers={['icon', 'noPadding', 'transparent']}
                      />
                    </Row>
                    <Text modifiers={['block', 'start']}>
                      {participant.firstName}
                    </Text>
                    <Text modifiers={['block', 'primary', 'light', 'start']}>
                      {formatMemberDesignation(participant.memberDesignation)}
                    </Text>
                  </SelectedParticipantCard>
                </Row>
              </Col>
            ))}
          </Row>
        </Container>
        <Container>
          <Row modifiers={['withGutters']}>
            <Text modifiers={['h3']}>Search Results</Text>
          </Row>
          <ContentCard modifiers={['fluid']}>
            {memberLeads.map((contact, i) => (
              <Row
                onClick={() => handleClickItem(contact)}
                modifiers={['middle']}
                key={i}
              >
                <Col modifiers={['col_2']}>
                  <Checkbox checked={isChecked(contact)} onChange={noop} />
                </Col>
                <Col modifiers={['col_2', 'fluid', 'flexBox', 'center']}>
                  <Avatar
                    user={contact}
                    width={42}
                    height={42}
                    modifiers={['round']}
                  />
                </Col>
                <Col modifiers={['col_8', 'flexBox']}>
                  <Text
                    modifiers={[
                      'subtitle',
                      'mediumWeight',
                      'darkGrey',
                      'start',
                    ]}
                  >
                    {formatTitleAndName(contact)}
                  </Text>
                </Col>
              </Row>
            ))}
          </ContentCard>
        </Container>
      </Modal.Body>
      <Modal.Footer
        style={{
          boxShadow:
            '0px -1px 2px rgba(46, 41, 78, 0.02), 0px -4px 8px rgba(46, 41, 78, 0.08)',
        }}
      >
        <Container>
          <Row modifiers={['spaceBetween', 'middle']}>
            <Button
              image={assets.icons.icCloseGrey}
              modifiers={['icon', 'transparent']}
              width={22}
              height={22}
              onClick={handleBack}
            />
            <Button
              disabled={selectedParticipants.length < 1}
              modifiers={[
                selectedParticipants.length < 1 && 'disabled',
                'roundCorner',
                'widthSmall',
              ]}
              onClick={handleAdd}
            >
              Add
            </Button>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}
