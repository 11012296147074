import { useContext } from 'react';

import { CallServiceContext } from 'contexts/call';
import { Container, Button, Text } from '@gaz/gaz-components.public';
import { Row } from 'common';
import { formatTitleAndName } from 'utils/string';
import assets from 'assets';
import { AuthContext } from 'contexts/auth';

const Footer = ({ handleMinimize }) => {
  const {
    muted,
    videoMuted,
    disconnect,
    handleMute,
    handleVideoMute,
    participants,
  } = useContext(CallServiceContext);
  const { me } = useContext(AuthContext);

  const handleEndCall = () => {
    disconnect();
  };

  return (
    <Container modifiers={['flexBox', 'padding_2']}>
      <Row>
        <Text modifiers={['block', 'white', 'semiBold', 'medium']}>
          {formatTitleAndName(participants[0])}
        </Text>
      </Row>
      <Row modifiers={['spaceBetween', 'smallGutters']}>
        {!me?.isTempAuth && (
          <Button
            modifiers={['icon', 'transparent']}
            image={assets.icons.icCallMinimize}
            imageWidth={40}
            imageHeight={40}
            onClick={handleMinimize}
          />
        )}
        <Button
          modifiers={['icon', 'transparent']}
          image={muted ? assets.icons.icCallMicMuted : assets.icons.icCallMic}
          imageWidth={40}
          imageHeight={40}
          onClick={handleMute}
        />
        <Button
          modifiers={['icon', 'transparent']}
          image={
            videoMuted
              ? assets.icons.icCallCameraMuted
              : assets.icons.icCallCamera
          }
          imageWidth={40}
          imageHeight={40}
          onClick={handleVideoMute}
        />
        <Button modifiers={['roundCorner', 'danger']} onClick={handleEndCall}>
          End Call
        </Button>
      </Row>
    </Container>
  );
};

export default Footer;
