import { useEffect, useState, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';

import { FETCH_PATIENT_PROGRESS } from 'graphql/queries';
import { AuthContext } from '../auth';

const useProgress = () => {
  const { me } = useContext(AuthContext);
  const [progress, updateProgress] = useState(null);
  const [fetchProgress] = useLazyQuery(FETCH_PATIENT_PROGRESS, {
    onCompleted: (data) => {
      updateProgress(data.patientProgress || {});
    },
    onError: () => {},
  });

  useEffect(() => {
    if (!me?._id) {
      updateProgress(null);
    }
  }, [me?._id]);

  return { progress, fetchProgress };
};

export default useProgress;
