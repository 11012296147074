import styled from 'styled-components';
import { Container } from '@gaz/gaz-components.public';
import { buildStyledComponent } from 'style';

const styles = ({ theme }) => `
  background-color: ${theme.colors.darkGrey};
`;

export default buildStyledComponent(
  'SectionTitle_Wrapper',
  styled(Container),
  styles,
);
