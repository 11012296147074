import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { debounce } from 'lodash';

import assets from 'assets';
import {
  Container,
  Row,
  Column,
  BackButton,
  ContentCard,
  Avatar,
  Text,
  FormInput,
  Button,
} from '@gaz/gaz-components.public';
import { FormFileInput, SelectContact } from 'common';
import { MainLayout } from 'layouts';
import { FETCH_GROUP_DETAILS, FETCH_GROUP_MEMBER_LEADS } from 'graphql/queries';
import {
  INVITE_EXISTING_USERS_TO_GROUP,
  UPDATE_GROUP,
} from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';
import { useFab } from 'contexts/fab';
import { SEARCH_DEBOUNCE_TIME } from 'utils/constants';

import Members from './Members';

const { Header, Content } = MainLayout;

export default function Group() {
  const fab = useFab();
  const [isEditing, setIsEditing] = useState(false);
  const [memberSearchModalVisible, setMemberSearchModalVisible] =
    useState(false);
  const { id } = useParams();
  const formRef = useRef();

  const [
    fetchGroupDetails,
    {
      data: { groupDetails } = {},
      loading: groupDetailsLoading,
      refetch: refetchGroupDetails,
    },
  ] = useLazyQuery(FETCH_GROUP_DETAILS);
  const [fetchGroupMemberLeads, { data: { groupMemberLeads } = {} }] =
    useLazyQuery(FETCH_GROUP_MEMBER_LEADS);
  const [inviteExistingUsersToGroup, { loading: inviting }] = useMutation(
    INVITE_EXISTING_USERS_TO_GROUP,
    {
      onCompleted: () => {
        refetchGroupDetails();
      },
    }
  );
  const [updateGroup] = useMutation(UPDATE_GROUP);
  const data = groupDetails;
  const loading = groupDetailsLoading || inviting;
  loadingVar(loading);

  const handleClickFab = useCallback(() => {
    setMemberSearchModalVisible(true);
  }, [setMemberSearchModalVisible]);

  const handleSearchMember = useCallback(
    debounce((query) => {
      fetchGroupMemberLeads({
        variables: {
          id,
          query,
          isPractice: false,
        },
      });
    }, SEARCH_DEBOUNCE_TIME),
    []
  );

  const handleFetchDetails = useCallback(() => {
    fetchGroupDetails({
      variables: { id },
    });
  }, [id, fetchGroupDetails]);

  const handleInvite = (newMembers) => {
    const inviteeIds = newMembers.map((m) => m._id);
    inviteExistingUsersToGroup({
      variables: {
        inviteeIds,
        groupId: id,
        isPractice: false,
      },
    });
  };

  useEffect(() => {
    if (data?.isAdmin) {
      fab.showFab();
      fab.setIcon(assets.icons.icAddUser);
      fab.setAction(handleClickFab);
    } else {
      fab.hideFab();
    }

    return () => {
      fab.hideFab();
    };
  }, [data, handleClickFab]);

  useEffect(() => {
    handleFetchDetails();
  }, [handleFetchDetails]);

  const handleToggleEdit = () => {
    if (isEditing) {
      formRef.current.submitForm();
    }
    setIsEditing(!isEditing);
  };

  const handleSaveGroup = async (values) => {
    const variables = {
      id,
      group: values,
    };

    await updateGroup({ variables });
  };

  if (!data) {
    return null;
  }

  const { group } = data;
  const initialValues = {
    name: group?.name || '',
    description: group?.description || '',
    image: group?.image,
  };

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton />
        </Header.Left>
        <Header.Center>
          <Row modifiers={['middle', 'center']}>
            <Column>
              <Text modifiers={['secondaryColor']}>Group</Text>
            </Column>
          </Row>
        </Header.Center>
        <Header.Right>
          {data.isAdmin && (
            <Button onClick={handleToggleEdit}>
              {isEditing ? 'Done' : 'Edit'}
            </Button>
          )}
        </Header.Right>
      </Header>
      <Content>
        <Container>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={handleSaveGroup}
          >
            {() => (
              <Form>
                <ContentCard>
                  <Row>
                    <Column modifiers={['col_2']}>
                      <Field
                        required
                        disabled={!isEditing}
                        name="image"
                        component={FormFileInput}
                        s3={{ folder: 'groups' }}
                        modifiers={['round', 'flexColumn', 'noMargin']}
                        accept="image/*"
                        previewWidth={50}
                        previewHeight={50}
                        width={50}
                        height={50}
                      >
                        <Avatar
                          image={assets.images.emptyAvatar}
                          width={50}
                          height={50}
                          modifiers={['round']}
                        />
                      </Field>
                    </Column>
                    <Column
                      modifiers={['col_10', 'flexBox', 'verticalContentCenter']}
                    >
                      <Field
                        name="name"
                        component={FormInput}
                        disabled={!isEditing}
                        hidden={!isEditing}
                      />
                    </Column>
                  </Row>

                  <Row>
                    <Text>description:</Text>
                  </Row>
                  <Row>
                    <Column modifiers={['col_12']}>
                      <Field
                        name="description"
                        component={FormInput}
                        disabled={!isEditing}
                        hidden={!isEditing}
                      />
                    </Column>
                  </Row>
                  <Members data={data} refresh={handleFetchDetails} />
                </ContentCard>
              </Form>
            )}
          </Formik>
        </Container>
      </Content>

      {memberSearchModalVisible && (
        <SelectContact
          open
          users={groupMemberLeads}
          onSearch={handleSearchMember}
          handleClose={() => setMemberSearchModalVisible(false)}
          handleDone={handleInvite}
        />
      )}
    </MainLayout>
  );
}
