import styled from 'styled-components';
import { Row, Button } from '@gaz/gaz-components.public';
import Draggable from 'react-draggable';

import TimeElapsed from './TimeElapsed';
import assets from 'assets';
import { buildStyledComponent, px2rem } from 'style';

const MinIndicator = ({ timeElapsed, handleMaximize, minimized, ...props }) => (
  <Draggable axis="y" bounds="body" cancel="button">
    <Row modifiers={['middle', !minimized && 'noDisplay']} {...props}>
      <Button
        modifiers={['icon', 'transparent']}
        image={assets.icons.icCallMaximize}
        imageWidth={30}
        imageHeight={30}
        onClick={handleMaximize}
      />
      <TimeElapsed />
    </Row>
  </Draggable>
);

const styles = ({ theme, ...props }) => `
  position: absolute;
  right: 0;
  top: 40%;
  z-index: 10000;
  background: rgba(0, 64, 137, 0.7);
  backdrop-filter: blur(6px);
  border-radius: ${px2rem(8)} 0 0 ${px2rem(8)};
  padding: ${px2rem(5)} ${px2rem(20)} ${px2rem(5)} ${px2rem(5)};
  user-select: none;

  > * {
    color: ${theme.colors.white};
  }
`;

export default buildStyledComponent(
  'CallMinIndicator',
  styled(MinIndicator),
  styles
);
