import React from 'react';
import { useHistory } from 'react-router';

import Wrapper from './Wrapper';

const Footer = ({ children, ...props }) => {
  const history = useHistory();

  return (
    <Wrapper modifiers={['middle']} {...props}>
      {children}
    </Wrapper>
  );
};

export default Footer;
