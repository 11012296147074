import React, { useContext, useMemo } from 'react';

import { Row, Column, Text, ScrollView } from '@gaz/gaz-components.public';

import { Container, Loader, PeriodPicker } from 'common';
import Item from './Item';
import { displayTime } from 'utils/time';
import { RecordsContext } from '../Context';

const Diagnosis = () => {
  const {
    diagnosis: pastDiagnoses,
    diagnosisPeriod: period,
    updateDiagnosisPeriod: setPeriod,
  } = useContext(RecordsContext);

  const handleSelectPeriod = (value) => {
    setPeriod(value);
  };

  const diagnoses = useMemo(() => {
    const diagnoses = {};
    if (!pastDiagnoses) {
      return {};
    }
    pastDiagnoses.forEach((diagnosis) => {
      const date = displayTime(diagnosis.date, 'MM/DD/yyyy');
      if (!diagnoses[date]) {
        diagnoses[date] = [];
      }
      diagnoses[date].push({
        value: diagnosis,
        code: diagnosis.code,
      });
    });
    return diagnoses;
  }, [pastDiagnoses]);

  return (
    <Container modifiers={['flexBox', 'fullHeight']}>
      <Container modifiers={['flexFixed', 'fluid']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Text modifiers={['medium', 'bold']}>Diagnosis List</Text>
          <Column>
            <PeriodPicker value={period} handleSelect={handleSelectPeriod} />
          </Column>
        </Row>
      </Container>
      <ScrollView>
        {Object.values(diagnoses).length > 0 ? (
          <Container modifiers={['fluid']}>
            {Object.keys(diagnoses).map((date) => (
              <Item key={date} diagnoses={diagnoses[date]} date={date} />
            ))}
          </Container>
        ) : (
          <Text>No diagnosis found.</Text>
        )}
      </ScrollView>
    </Container>
  );
};

export default Diagnosis;
