import { Container, Row, Column, Text } from 'common';
import { VITALS } from 'utils/constants';
import { getVitalTypeAttr } from 'utils/helper';

export default ({ vitals }) => {
  if (!vitals || Object.keys(vitals).length === 0) {
    return null;
  }
  const vitalTypes = Object.keys(VITALS).filter((item) => !!vitals[item]);
  return (
    <Container modifiers={['fluid', 'withGutters']}>
      <Row modifiers={['smallGutters']}>
        <Text modifiers={['block', 'semiBold', 'secondary', 'large']}>
          Vitals
        </Text>
      </Row>
      {vitalTypes.map((type) => {
        const attrs = getVitalTypeAttr(type);
        return (
          <Row key={type} modifiers={['smallGutters']}>
            <Column modifiers={['col_5', 'noPadding']}>
              <Text modifiers={['secondary', 'medium']}>{attrs.name}</Text>
            </Column>
            <Column modifiers={['col', 'noPadding']}>
              <Text modifiers={['secondary', 'medium']}>
                {type === 'bloodPressure'
                  ? `${vitals[type]} sys / ${vitals['bloodPressure2']} dia`
                  : `${vitals[type]} ${attrs.unitShort || attrs.unit}`}
              </Text>
            </Column>
          </Row>
        );
      })}
    </Container>
  );
};
