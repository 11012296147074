import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const modifierConfig = {
  dark: ({ theme }) => ({
    styles: `
      background-color: ${theme.colors.transparent};
      border-color: ${theme.colors.white};
      color: ${theme.colors.white};
    `,
  }),
  error: ({ theme }) => ({
    styles: `
      border-color: ${theme.colors.danger} !important;
    `,
  }),
  borderBottomOnly: ({ theme }) => ({
    styles: `
      border-width: 0;
      border-bottom-width: 1px;
      border-color: ${theme.colors.grey};
      padding: ${theme.dimensions.padding_1} 0;
    `,
  }),
  valid: ({ theme }) => ({
    styles: `
      border-color: ${theme.colors.primary};
    `,
  }),
  large: () => ({
    styles: ``,
  }),
  asInfo: ({ theme }) => `
    border: 0;
    color: ${theme.colors.info};
    text-align: center;
    font-weight: ${theme.fontWeights.bold};
  `,
  asGrayInfo: ({ theme }) => `
    border: 0;
    color: ${theme.colors.text};
    text-align: center;
    font-weight: ${theme.fontWeights.bold};
  `,
  noBorder: () => `
    border: none;
  `,
  noPadding: () => `
    padding: 0;
  `,
  fullWidth: () => `
    width: 100%;
  `,
  hidden: () => `
    border: none;
    background-color: transparent;
  `,
  disabled: () => `
    border: none;
    background-color: transparent;
  `,
};

const styles = ({ theme }) => `
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.transparent};
  color: ${theme.colors.secondary};
  font-size: ${theme.dimensions.fontSize};
  font-family: ${theme.fonts.secondary} !important;
  outline: none;
  padding: ${theme.dimensions.padding_1};
  width: 100%;
`;

export const Input = buildStyledComponent('Input', styled.input, styles, {
  modifierConfig,
});
