import React, { createContext, useContext, useState } from 'react';

const CallRibbonContext = createContext();

export function CallRibbonProvider({ children }) {
  const [hidden, setHidden] = useState(true);
  const [action, setAction] = useState(() => () => {});

  const showRibbon = () => setHidden(false);

  const hideRibbon = () => setHidden(true);

  const setRibbonAction = (fn) => setAction(() => fn);

  return (
    <CallRibbonContext.Provider
      value={{
        hidden,
        action,
        showRibbon,
        hideRibbon,
        setAction: setRibbonAction,
      }}
    >
      {children}
    </CallRibbonContext.Provider>
  );
}

export function useCallRibbon() {
  return useContext(CallRibbonContext);
}
