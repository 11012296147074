import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router';

import { Row, Column } from '@gaz/gaz-components.public';
import assets from 'assets';
import urls from 'routes/urls';

import Card from './Card';
import { PatientContext } from 'contexts/patient';

const Records = () => {
  const history = useHistory();
  const { noteCount, careplanCount } = useContext(PatientContext);
  const recordsCount = useMemo(
    () => (noteCount || 0) + (careplanCount || 0),
    [noteCount, careplanCount]
  );

  return (
    <Card modifiers={['fluid']} onClick={() => history.push(urls.RECORDS)}>
      <Row modifiers={['fullHeight', 'middle']}>
        <Column modifiers={['col_3', 'flexBox', 'verticalContentCenter']}>
          <Card.Icon src={assets.icons.iconRecord} />
        </Column>
        <Column modifiers={['col', 'fluid']}>
          <Card.Title>Records</Card.Title>
          {recordsCount > 0 && (
            <Card.Subtitle>{recordsCount} New</Card.Subtitle>
          )}
        </Column>
      </Row>
    </Card>
  );
};

export default Records;
