import { gql } from '@apollo/client';

import fragments from './fragments';

export const LOGIN = gql`
  mutation Login(
    $email: String!
    $password: String
    $pin: String
    $role: String
    $timezoneOffset: Int
    $timezone: String
  ) {
    login(
      email: $email
      password: $password
      pin: $pin
      role: $role
      timezoneOffset: $timezoneOffset
      timezone: $timezone
    ) {
      ...AuthFragment
    }
  }
  ${fragments.authFragment}
`;

export const REQUEST_CODE = gql`
  mutation RequestCode($fullName: String!, $email: String!, $phone: String!) {
    requestCode(fullName: $fullName, email: $email, phone: $phone)
  }
`;

export const VERIFY_CODE = gql`
  mutation VerifyCode($email: String!, $code: String!) {
    verifyCode(email: $email, code: $code) {
      ...AuthFragment
    }
  }
  ${fragments.authFragment}
`;

export const SIGN_S3 = gql`
  mutation SignS3($folder: String!, $fileType: String!) {
    signS3(folder: $folder, fileType: $fileType) {
      signedRequest
      url
    }
  }
`;

export const FETCH_OR_CREATE_CHAT = gql`
  mutation FetchOrCreateChat($chat: ChatInput!) {
    fetchOrCreateChat(chat: $chat) {
      _id
      members {
        ...UserFragment
      }
      referredPatient {
        ...UserFragment
      }
      group
      messages {
        _id
        sender {
          ...UserFragment
        }
        text
        createdAt
        attachment {
          url
          originalName
          type
        }
      }
      createdAt
    }
  }
  ${fragments.userFragment}
`;

export const REGISTER_PATIENT = gql`
  mutation RegisterPatient(
    $password: String!
    $pin: String!
    $timezoneOffset: Int
    $timezone: String
  ) {
    registerPatient(
      password: $password
      pin: $pin
      timezoneOffset: $timezoneOffset
      timezone: $timezone
    ) {
      ...AuthFragment
    }
  }
  ${fragments.authFragment}
`;

export const UPDATE_PATIENT = gql`
  mutation UpdatePatient($id: ID!, $patient: PatientInput!) {
    updatePatient(id: $id, patient: $patient) {
      ...UserProfileFragment
    }
  }
  ${fragments.userProfileFragment}
`;

export const UPDATE_SECURITY = gql`
  mutation UpdateSecurity($security: UserSecurityInput!) {
    updateSecurity(security: $security)
  }
`;

export const INVITE_EXISTING_USER = gql`
  mutation InviteExistingUser($inviteeId: ID!) {
    inviteExistingUser(inviteeId: $inviteeId)
  }
`;

export const INVITE_EXISTING_USERS_TO_GROUP = gql`
  mutation InviteExistingUsersToGroup(
    $inviteeIds: [ID]!
    $groupId: ID!
    $isPractice: Boolean!
  ) {
    inviteExistingUsersToGroup(
      inviteeIds: $inviteeIds
      groupId: $groupId
      isPractice: $isPractice
    )
  }
`;

export const REQUEST_TO_JOIN_GROUP = gql`
  mutation RequestToJoinGroup($groupId: ID!, $isPractice: Boolean!) {
    requestToJoinGroup(groupId: $groupId, isPractice: $isPractice)
  }
`;

export const INVITE_NEW_USER = gql`
  mutation InviteNewUser(
    $fullName: String!
    $email: String!
    $phone: String!
    $role: String!
  ) {
    inviteNewUser(
      fullName: $fullName
      email: $email
      phone: $phone
      role: $role
    ) {
      ...UserFragment
    }
  }
  ${fragments.userFragment}
`;

export const ACCEPT_OR_DECLINE_INVITE = gql`
  mutation AcceptOrDeclineInvite($id: ID!, $accepting: Boolean!) {
    acceptOrDeclineInvite(id: $id, accepting: $accepting)
  }
`;

export const CANCEL_INVITE = gql`
  mutation CancelInvite($id: ID!) {
    cancelInvite(id: $id)
  }
`;

export const CREATE_GROUP = gql`
  mutation CreateGroup($group: GroupInput!) {
    createGroup(group: $group) {
      ...GroupFragment
    }
  }
  ${fragments.groupFragment}
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroup($id: ID!, $group: GroupInput!) {
    updateGroup(id: $id, group: $group) {
      ...GroupFragment
    }
  }
  ${fragments.groupFragment}
`;

export const UPDATE_CALL_STATUS = gql`
  mutation UpdateCallStatus($call: String!, $status: String!, $time: Date!) {
    updateCallStatus(call: $call, status: $status, time: $time)
  }
`;

export const ADD_CAREPLAN_RESPONSE = gql`
  mutation AddCareplanResponse($date: Date, $response: CareplanResponseInput!) {
    addResponse(date: $date, response: $response) {
      ...CareplanResponseFragment
    }
  }
  ${fragments.careplanResponseFragment}
`;

export const BOOK_APPOINTMENT = gql`
  mutation BookAppointment($appointment: AppointmentInput!) {
    bookAppointment(appointment: $appointment) {
      ...AppointmentFragment
    }
  }
  ${fragments.appointmentFragment}
`;

export const CANCEL_APPOINTMENT = gql`
  mutation CancelAppointment($appointment: ID!) {
    cancelAppointment(appointment: $appointment)
  }
`;

export const READ_PATIENT_ALERTS = gql`
  mutation readAlerts($measure: String!, $alert: ID!) {
    readAlerts(measure: $measure, alert: $alert)
  }
`;

export const READ_PATIENT_NOTE = gql`
  mutation readNote($note: ID!) {
    readNote(note: $note)
  }
`;

export const READ_PATIENT_CAREPLAN = gql`
  mutation readCareplan($careplan: ID!) {
    readCareplan(careplan: $careplan)
  }
`;

export const SET_DEFAULT_PAYMENT_METHOD = gql`
  mutation setDefaultPaymentMethod($paymentId: ID!) {
    setDefaultPaymentMethod(paymentId: $paymentId)
  }
`;

export const ADD_PAYMENT_METHOD = gql`
  mutation addPaymentMethod(
    $type: String!
    $number: String!
    $exp: String!
    $cvc: String!
  ) {
    addPaymentMethod(type: $type, number: $number, exp: $exp, cvc: $cvc) {
      cardType
      last4Digits
      isDefault
    }
  }
`;

export const REMOVE_PAYMENT_METHOD = gql`
  mutation removePaymentMethod($paymentId: ID!) {
    removePaymentMethod(paymentId: $paymentId)
  }
`;
