import assets from 'assets';
import urls from 'routes/urls';

export const OPTIONS = [
  {
    name: 'My Profile',
    icon: assets.icons.icPerson,
    route: urls.PROFILE,
  },
  {
    name: 'Family & Friends',
    icon: assets.icons.user,
    route: urls.CARE_TEAM,
  },
  /*
  {
    name: 'Devices',
    icon: assets.icons.iconSchedule,
    route: urls.SCHEDULE,
  },
  */
  {
    name: 'Security',
    icon: assets.icons.icShield,
    route: urls.SECURITY,
  },
  {
    name: 'Notifications',
    icon: assets.icons.user,
    route: urls.NOTIFICATION,
  },
  {
    name: 'Payment Methods',
    icon: assets.icons.icPerson,
    route: urls.PAYMENTMETHODS,
  },
  {
    name: 'Help Center',
    icon: assets.icons.icDoc,
    route: urls.HELP_CENTER,
  },
  {
    name: 'Terms and conditions',
    icon: assets.icons.icPaper,
    route: urls.TERMS,
  },
  {
    name: 'Privacy Policy',
    icon: assets.icons.icPrivacy,
    route: urls.PRIVACY_POLICY,
  },
];
