import React, { useState, useCallback } from 'react';
import { debounce, startCase } from 'lodash';
import { useHistory } from 'react-router';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Text,
  Container,
  Row,
  Button,
  Column,
  SearchBar,
  BackButton,
} from '@gaz/gaz-components.public';
import Invite from 'common/SelectContact/Invite';

import { Modal } from 'common';
import { FETCH_LEADS } from 'graphql/queries';
import {
  INVITE_EXISTING_USER,
  INVITE_NEW_USER,
  REQUEST_TO_JOIN_GROUP,
} from 'graphql/mutations';
import { INVITE_ROLE_TYPES, SEARCH_DEBOUNCE_TIME } from 'utils/constants';
import assets from 'assets';
import urls from 'routes/urls';

import Contact from './Contact';

export default function AddContact({
  contactType = INVITE_ROLE_TYPES.PROVIDER,
  showManualAdd = true,
  handleClose = () => {},
  handleSuccess = () => {},
}) {
  const history = useHistory();

  const callback = (successful) => {
    Modal.info({
      render: () => (
        <Modal.Body>
          <Row modifiers={['middle', 'center']}>
            <Column modifiers={['col_12']}>
              <Text modifiers={['secondaryColor', 'bold']}>
                {successful
                  ? `Your request was sent successfully. They will be added to your
                network once they accept your request.`
                  : `Something went wrong. Please try again!`}
              </Text>
            </Column>
            <Column modifiers={['col_12', 'flexBox', 'center']}>
              <Button
                image={assets.icons.icCloseGrey}
                modifiers={['icon', 'transparent']}
              />
            </Column>
          </Row>
        </Modal.Body>
      ),
    });

    if (successful) {
      handleClose();
    }
  };
  const [fetchLeads, { data: { leads } = {} }] = useLazyQuery(FETCH_LEADS);
  const [inviteExistingUser] = useMutation(INVITE_EXISTING_USER, {
    onCompleted: (data) => {
      const successful = data.inviteExistingUser;
      callback(successful);
    },
  });
  const [inviteNewUser] = useMutation(INVITE_NEW_USER, {
    onCompleted: (data) => {
      const successful = data.inviteNewUser;
      callback(successful);
      if (successful) {
        handleSuccess();
      }
    },
  });
  const [requestToJoinGroup] = useMutation(REQUEST_TO_JOIN_GROUP, {
    onCompleted: (data) => {
      const successful = data.requestToJoinGroup;
      callback(successful);
    },
  });
  const [selectedContact, setSelectedContact] = useState({});
  const [showInviteForm, setShowInviteForm] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const handleSearch = useCallback(
    debounce((query) => {
      if (query?.length) setIsSearching(true);
      else setIsSearching(false);
      fetchLeads({
        variables: {
          query,
          category: contactType,
        },
      });
    }, SEARCH_DEBOUNCE_TIME),
    [fetchLeads]
  );

  const handleAddExistingContact = () => {
    if (
      contactType === INVITE_ROLE_TYPES.GROUP ||
      contactType === INVITE_ROLE_TYPES.PRACTICE
    ) {
      requestToJoinGroup({
        variables: {
          groupId: selectedContact._id,
          isPractice: false,
        },
      });
    } else {
      inviteExistingUser({
        variables: {
          inviteeId: selectedContact._id,
        },
      });
    }
  };

  const handleAddNewContact = (values) => {
    inviteNewUser({
      variables: {
        ...values,
        role: contactType,
      },
    });
  };

  const handleSelect = (c) => {
    setSelectedContact(c);
  };

  const handleAddManually = () => {
    if (contactType === INVITE_ROLE_TYPES.GROUP) {
      history.push(urls.CREATE_GROUP);
    } else {
      setShowInviteForm(true);
    }
  };

  return (
    <Modal open>
      {showInviteForm && (
        <Invite
          handleAdd={handleAddNewContact}
          handleClose={() => setShowInviteForm(false)}
        />
      )}
      <Modal.Header>
        <Row>
          <Column modifiers={['col_2']}>
            <BackButton />
          </Column>
          <Column
            modifiers={['col_9', 'center', 'flexBox', 'verticalContentCenter']}
          >
            <Text modifiers={['secondaryColor', 'bold']}>
              {`Add ${contactType}`}
            </Text>
          </Column>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row modifiers={['middle', 'center']}>
            <SearchBar onChange={handleSearch} />
          </Row>
          {isSearching &&
            leads?.map(({ contact }) => (
              <Contact
                key={contact._id}
                contact={contact}
                selected={selectedContact}
                handleSelect={handleSelect}
              />
            ))}
          {!isSearching && showManualAdd && (
            <Row modifiers={['withGutters', 'center', 'flexColumn']}>
              <Column modifiers={['col_12']}>
                <Text modifiers={['xSmall']}>
                  Manually add {startCase(contactType)}
                </Text>
              </Column>
              <Column modifiers={['col_12']}>
                <Button
                  onClick={handleAddManually}
                  modifiers={[
                    'textIcon',
                    'transparent',
                    'roundCorner',
                    'fullWidth',
                  ]}
                >
                  <Row style={{ width: '100%' }}>
                    <Column
                      modifiers={[
                        'col_2',
                        'flexBox',
                        'verticalContentCenter',
                        'center',
                      ]}
                    >
                      <img
                        alt="add"
                        src={assets.icons.icPlus}
                        width="20"
                        height="20"
                      />
                    </Column>
                    <Column
                      modifiers={[
                        'col_6',
                        'col_offset_1',
                        'flexBox',
                        'verticalContentCenter',
                        'center',
                      ]}
                    >
                      <Text modifiers={['secondaryColor']}>
                        Add {startCase(contactType)} Manually
                      </Text>
                    </Column>
                  </Row>
                </Button>
              </Column>
            </Row>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Column modifiers={['col_6']}>
            <Button
              image={assets.icons.icCloseGrey}
              modifiers={['icon', 'transparent']}
              onClick={handleClose}
            />
          </Column>
          <Column modifiers={['col_6', 'flexBox', 'center']}>
            <Button
              modifiers={['primary', 'widthSmall']}
              onClick={handleAddExistingContact}
            >
              Add
            </Button>
          </Column>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}
