import { useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import { MainLayout } from 'layouts';
import { BackButton, Text, Row, Column } from '@gaz/gaz-components.public';
import { Avatar } from 'common';

import Medication from './Medication';
import Vital from './Vital';
import Activity from './Activity';
import Wellness from './Wellness';
import Diet from './Diet';
import Appointment from './Appointment';
import List from './List';
import { AuthContext } from 'contexts/auth';
import { getFullName } from 'utils/string';
import { PatientContext } from 'contexts/patient';
import ProgressProvider, { ProgressContext } from './Context';

const { Header, Content } = MainLayout;

const Progress = () => {
  const { path } = useRouteMatch();
  const { me } = useContext(AuthContext);
  const { fetchResponses } = useContext(PatientContext);
  const { goBack } = useContext(ProgressContext);

  useEffect(() => {
    if (!!me?._id) {
      fetchResponses();
    }
  }, [me?._id]);

  if (!me) {
    return null;
  }

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton onClick={goBack} />
        </Header.Left>
        <Header.Center>
          <Row modifiers={['middle']}>
            <Avatar user={me} width={40} height={40} modifiers={['squared']} />
            <Column modifiers={['col_8', 'flexBox']}>
              <Text modifiers={['semiBold', 'start']}>{getFullName(me)}</Text>
            </Column>
          </Row>
        </Header.Center>
      </Header>
      <Content>
        <Switch>
          <Route exact path={`${path}/medication`} component={Medication} />
          <Route exact path={`${path}/vital`} component={Vital} />
          <Route exact path={`${path}/wellness`} component={Wellness} />
          <Route exact path={`${path}/diet`} component={Diet} />
          <Route exact path={`${path}/activity`} component={Activity} />
          <Route exact path={`${path}/appointment`} component={Appointment} />
          <Route exact path={`${path}`} component={List} />
        </Switch>
      </Content>
    </MainLayout>
  );
};

const ProgressWithProvider = () => {
  return (
    <ProgressProvider>
      <Progress />
    </ProgressProvider>
  );
};

export default ProgressWithProvider;
