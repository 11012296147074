import React from 'react';

import FormIconInput from './FormIconInput';

const normalizeInput = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (cvLength < 4) return currentValue;
  if (cvLength < 7)
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
    3,
    6
  )}-${currentValue.slice(6, 10)}`;
};

export const FormPhoneInput = (props) => {
  const {
    field: { name, onChange },
  } = props;

  const handleChange = (e) => {
    onChange(name)(normalizeInput(e.target.value));
  };

  return <FormIconInput {...props} onChange={handleChange} />;
};

export default FormPhoneInput;
