import { px2rem } from './px2rem';
import colors from './colors';

const dimensions = {
  fontSize: px2rem(16),
  fontSizeMedium: px2rem(14),
  fontSizeLarge: px2rem(16),
  fontSizeXLarge: px2rem(32),
  fontSizeSmall: px2rem(11),
  fontSizeXSmall: px2rem(10),
  fontSizePageTitle: px2rem(20),
  fontSizeNavMenu: px2rem(10),
  fontSizeNormal: px2rem(12),
  padding_1: px2rem(8),
  padding_2: px2rem(16),
  paragraphPadding: px2rem(10),
  gridPad: px2rem(8),
  borderRadius_1: px2rem(6),
  borderRadius_2: px2rem(12),
  borderRadius_3: px2rem(124),
  lightBorder: `${px2rem(0.5)} solid #F5F5F5`,
  highlightBorder: `${px2rem(0.5)} solid ${colors.primary}`,
  borderWidth_1: px2rem(2),
  borderWidth_2: px2rem(4),
  widgetBoxShadow: `0px 1px 2px rgba(46, 41, 78, 0.02), 0px 4px 8px rgba(46, 41, 78, 0.08);`,
  boxShadow: `${px2rem(1)} ${px2rem(1)} ${px2rem(3)} ${colors.darkGrey}`,
  boxShadow_2: `0px ${px2rem(12)} ${px2rem(30)} #00000012`,
  boxShadow_3: `0px ${px2rem(12)} ${px2rem(24)} #0146BE4D`,
  boxShadow_4: `0px ${px2rem(6)} ${px2rem(12)} #98989829`,
  boxShadow_5: `0px 0px ${px2rem(4)} rgba(0, 0, 0, 0.12)`,
  footerBoxShadow: `0 ${px2rem(-1)} ${px2rem(
    2
  )} rgba(46, 41, 78, 0.02), 0 ${px2rem(-4)} ${px2rem(
    8
  )} rgba(46, 41, 78, 0.08)`,
  buttonWidthXSmall: px2rem(100),
  buttonWidthSmall: px2rem(120),
  buttonWidthMedium: px2rem(200),
  gutter: px2rem(10),
};

export default dimensions;
