import React from 'react';
import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'style';

const Component = ({ children, ...props }) => (
  <div {...props}>
    <span>{children}</span>
  </div>
);

const modifierConfig = {
  small: ({ theme }) => `
    width: ${px2rem(18)};
    height: ${px2rem(18)};
    padding: ${px2rem(1)};
    > span {
      line-height: ${px2rem(6)};
      font-size: ${theme.dimensions.fontSizeXSmall};
      color: white;
    }
  `,
  info: ({ theme }) => `
    background-color: ${theme.colors.primary};
    width: ${px2rem(14)};
    height: ${px2rem(14)};
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
      font-size: 8px;
      line-height: 11.2px;
      font-weight: ${theme.fontWeights.bold};
    }
  `,
};

const styles = ({ theme }) => `
  background-color: red;
  border-radius: 50%;
  width: ${px2rem(25)};
  height: ${px2rem(25)};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    line-height: ${px2rem(2)};
    font-size: 12px;
    color: white;
    font-weight: ${theme.fontWeights.bold};
  }
`;

export const Badge = buildStyledComponent('Badge', styled(Component), styles, {
  modifierConfig,
});
