import React, { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { qs } from 'url-parse';
import { useLocation } from 'react-router';
import { useMutation } from '@apollo/client';

import {
  BackButton,
  Text,
  Container,
  Row,
  Column as Col,
  Button,
  Avatar,
} from '@gaz/gaz-components.public';

import { MainLayout } from 'layouts';
import { GROUP_TYPES } from 'utils/constants';
import { ListView, SelectContact } from 'common';
import assets from 'assets';
import { CREATE_GROUP } from 'graphql/mutations';
import { useFab } from 'contexts/fab';
import { AuthContext } from 'contexts/auth';

import Form from './Form';

const { Header, Content, Footer } = MainLayout;

export default function CreateGroup() {
  const location = useLocation();
  const fab = useFab();
  const { me } = useContext(AuthContext);
  // const [isAddingMember, setIsAddingMember] = useState(false);
  // const [members, setMembers] = useState([]);
  const { callbackRoute } = qs.parse(location.search.replace('?', ''));
  const history = useHistory();
  const formRef = useRef();
  const [createGroup] = useMutation(CREATE_GROUP, {
    onCompleted: (data) => {
      history.replace(`/groups/${data.createGroup._id}?type=group`);
    },
    onError: () => {},
  });

  useEffect(() => {
    fab.hideFab();
  }, []);

  const getGroupType = (cbRoute) => {
    switch (cbRoute) {
      case '/care-team':
        return GROUP_TYPES.CARE_TEAM;
      case '/':
      default:
        return GROUP_TYPES.CONTACTS;
    }
  };

  const groupType = getGroupType(callbackRoute);

  // const handleSelectContact = (contacts = []) => {
  //   setMembers((prevState) => [...prevState, ...contacts]);
  //   setIsAddingMember(false);
  // };

  // const handleRemove = (contact) => {
  //   setMembers((prevState) =>
  //     filter(prevState, ({ _id }) => contact._id !== _id),
  //   );
  // };

  const handleDone = async () => {
    await formRef.current.submitForm();

    if (formRef.current.isValid) {
      createGroup({
        variables: {
          group: formRef.current.values,
        },
      });
    }
  };

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton />
        </Header.Left>
        <Header.Center>
          <Text>Create Group</Text>
        </Header.Center>
      </Header>
      <Content>
        <Container>
          <Form ref={formRef} groupType={groupType} />
        </Container>
        {/* {isAddingMember && (
          <SelectContact
            open={isAddingMember}
            handleClose={() => setIsAddingMember(false)}
            handleDone={handleSelectContact}
          />
        )} */}
        <Container>
          <Row>
            <Col modifiers={['col_12']}>
              <Text modifiers={['secondaryColor']}>Members</Text>
            </Col>
            <Col modifiers={['col_2']}>
              <Avatar user={me} width={40} height={40} modifiers={['round']} />
            </Col>
            <Col modifiers={['col_6']}>
              <Text>{`${me.firstName} ${me.lastName} (You)`}</Text>
            </Col>
            <Col modifiers={['col_4']}>
              <Text modifiers={['info']}>Admin</Text>
            </Col>
            {/* <Col modifiers={['col_12']}>
              <ListView
                records={members}
                hideTitle
                extraFields={(record) => (
                  <Button
                    onClick={() => handleRemove(record)}
                    modifiers={['link']}
                  >
                    Remove
                  </Button>
                )}
              />
            </Col> */}
          </Row>
          {callbackRoute === '/care-team' && (
            <Text modifiers={['muted', 'xSmall']}>
              Members will have read only access to your records, tasks and
              appointments as well as the ability to book appointments on your
              behalf.
            </Text>
          )}
        </Container>
      </Content>
      <Footer>
        <Col modifiers={['col']}>
          <Button
            onClick={() => history.goBack()}
            image={assets.icons.close}
            modifiers={['icon', 'transparent']}
          />
        </Col>
        {/* <Col modifiers={['col_5']}>
          <Button
            onClick={() => setIsAddingMember(true)}
            modifiers={['outlinePrimary']}
          >
            Add member
          </Button>
        </Col> */}
        <Col modifiers={['col', 'flexBox', 'end']}>
          <Button onClick={handleDone} modifiers={['primary']}>
            Done
          </Button>
        </Col>
      </Footer>
    </MainLayout>
  );
}
