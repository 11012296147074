import { useContext } from 'react';
import { useHistory } from 'react-router';

import {
  Container,
  Row,
  Column,
  Text,
  ScrollView,
} from '@gaz/gaz-components.public';
import { NoteItem, Loader, PeriodPicker } from 'common';
import { RecordsContext } from '../Context';

export default () => {
  const {
    notePeriod: period,
    updateNotePeriod: setPeriod,
    notes,
  } = useContext(RecordsContext);
  const { push, location } = useHistory();

  const handleSelectPeriod = (value) => {
    setPeriod(value);
  };

  const handleSelectNote = (note) => push(`${location.pathname}/${note._id}`);

  return (
    <Container modifiers={['fluid', 'fullHeight', 'flexBox']}>
      <Container modifiers={['flexFixed']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Text modifiers={['medium', 'bold']}>{`Signed Notes ${
            notes ? `(${notes.length})` : ''
          }`}</Text>
          <Column>
            <PeriodPicker value={period} handleSelect={handleSelectPeriod} />
          </Column>
        </Row>
      </Container>
      <ScrollView>
        <Container>
          {notes?.length > 0 ? (
            notes?.map((note, i) => (
              <NoteItem
                handleClickNote={() => handleSelectNote(note)}
                note={note}
                key={i}
              />
            ))
          ) : (
            <Text modifiers={['muted']}>You don't have any notes</Text>
          )}
        </Container>
      </ScrollView>
    </Container>
  );
};
