import React from 'react';
import { noop } from 'lodash';
import { HR } from '@gaz/gaz-components.public';
import { SlidingPaneContent } from 'common';
import { SHARE_OPTIONS } from './constants';
import assets from 'assets';

export default function ShareOptions({ hasProvider, onClickAction = noop }) {
  return (
    <>
      <SlidingPaneContent
        actionTitle="Add Participants"
        actionDescription="Add more participants to this chat"
        actionIcon={assets.icons.icGroupPlus}
        onClickAction={onClickAction(SHARE_OPTIONS.ADD_PARTICIPANTS)}
      />
      <HR modifiers={['colored']} />
      {/* <SlidingPaneContent
        actionTitle="Add Attachments"
        actionDescription="Add attachments to this conversation"
        actionIcon={assets.icons.icAttachmentRed}
        onClickAction={onClickAction(SHARE_OPTIONS.ADD_ATTACHMENT)}
      />
      <HR modifiers={['colored']} /> */}
      {/* <HR modifiers={['colored']} />
      {hasProvider && (
        <SlidingPaneContent
          actionTitle="Book Appointment"
          actionIcon={assets.icons.icScheduleGold}
          onClickAction={onClickAction(SHARE_OPTIONS.BOOK_APPOINTMENT)}
        />
      )} */}
      {/* <SlidingPaneContent
        actionTitle="Take Photo"
        actionIcon={assets.icons.addImage}
        onClickAction={onClickAction(SHARE_OPTIONS.TAKE_PHOTO)}
      />
      <SlidingPaneContent
        actionTitle="Take Video"
        actionIcon={assets.icons.addImage}
        onClickAction={onClickAction(SHARE_OPTIONS.TAKE_VIDEO)}
      />
      <SlidingPaneContent
        actionTitle="Photo Library"
        actionIcon={assets.icons.addFromPhotoLibrary}
        onClickAction={onClickAction(SHARE_OPTIONS.MEDIA_LIBRARY)}
      /> */}
    </>
  );
}
