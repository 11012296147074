import { Container, Text, Button } from '@gaz/gaz-components.public';
import styled from 'styled-components';

import { Row } from 'common';
import { buildStyledComponent } from 'style';
import { frequencyTitle, alertsTitle } from 'utils/helper';
import MeasureItem from './MeasureItem';
import assets from 'assets';
import { WELLNESS_ITEMS, DIET_ITEMS } from 'utils/constants';

const Question = buildStyledComponent(
  'CareplanQuestion',
  styled(({ type, questions, ...props }) => {
    const questionItems = Object.entries(questions).map(([name, question]) => {
      const detail = { Schedule: frequencyTitle(question.frequency) };
      if (question.alerts) {
        detail.Triggers = `${question.alerts.triggerValue} neg responses`;
        detail.Allowed = alertsTitle(question.alerts);
        detail.Action = 'Book Appointments';
      }

      return {
        name:
          type === 'WELLNESS'
            ? WELLNESS_ITEMS[name].title
            : DIET_ITEMS[name].title,
        icon:
          type === 'WELLNESS'
            ? WELLNESS_ITEMS[name].icon
            : DIET_ITEMS[name].icon,
        goal: '',
        detail,
      };
    });
    return (
      <Container {...props}>
        <Text modifiers={['bold']}>{type}</Text>
        {questionItems.map((question, index) => (
          <Container modifiers={['fluid']} key={index}>
            <Row modifiers={['smallGutters', 'middle']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={question.icon}
                imageWidth={20}
                imageHeight={20}
                width={40}
                height={40}
              />
              <Text>{question.name}</Text>
            </Row>
            <MeasureItem details={question.detail} />
          </Container>
        ))}
      </Container>
    );
  }),
  ({ theme }) => `
    >:not(:first-child) {
      margin-left: ${theme.dimensions.padding_2};
    }
  `
);

export default Question;
