import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const styles = () => `
  min-width: ${px2rem(45)};
  text-align: left;
`;

const Left = buildStyledComponent('MainLayout_Header_Left', styled.div, styles);

export default Left;
