import React from 'react';
import { noop } from 'lodash';

import { Row, Column, Text, HR, Button } from '@gaz/gaz-components.public';
import { IconWrapper } from 'common';

import assets from 'assets';
import { formatTitleAndName } from 'utils/string';
import { displayTime } from 'utils/time';
import Wrapper from './Wrapper';

export function NoteItem({ note = {}, handleClickNote = noop }) {
  return (
    <Wrapper onClick={handleClickNote} modifiers={['withGutters']}>
      <Row>
        <Column
          modifiers={[
            'col_2',
            'fluid',
            'flexBox',
            'verticalContentCenter',
            'center',
          ]}
        >
          <IconWrapper>
            <img
              alt="note"
              src={assets.icons.iconNote}
              width="19"
              height="16"
            />
          </IconWrapper>
        </Column>
        <Column modifiers={['col_10']}>
          <Row modifiers={['spaceBetween']}>
            <Text
              modifiers={[
                !note.isSeen && !note.isDraft && 'bold',
                'secondaryColor',
              ]}
            >
              {formatTitleAndName(note.creator.user)}
            </Text>
            <Text modifiers={[!note.isSeen && !note.isDraft && 'bold']}>
              {note._id.substr(-7)}
            </Text>
          </Row>
        </Column>
        <Column modifiers={['col_12', 'noVerticalPadding']}>
          <HR modifiers={['muted']} />
        </Column>
        <Column modifiers={['col_12']}>
          {/* Provider's name and note ID */}

          <Row modifiers={['my1']}>
            <Text
              modifiers={[!note.isSeen && !note.isDraft && 'bold', 'noPadding']}
            >
              Patient:&nbsp;
            </Text>
            <Text
              modifiers={[
                'primary',
                'xSmall',
                !note.isSeen && !note.isDraft && 'bold',
                'noPadding',
              ]}
            >
              {formatTitleAndName(note.user)}
            </Text>
          </Row>
          <Row modifiers={['spaceBetween', 'bottom']}>
            <Text
              modifiers={[
                'darkGrey',
                'noPadding',
                !note.isSeen && !note.isDraft && 'bold',
                'topPadding',
              ]}
            >
              {displayTime(note.signDate, 'hh:mm a, MM/DD/yy')}
            </Text>
            <Button
              image={assets.icons.icMore}
              width={14}
              height={14}
              modifiers={['icon', 'transparent']}
            />
          </Row>
        </Column>
      </Row>
    </Wrapper>
  );
}
