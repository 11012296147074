import * as Yup from 'yup';

const phoneRegex = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;

const schema = {
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Email is required'),
  password: Yup.string()
    .matches(/\w*[a-z]\w*/, 'Password must have a small letter')
    .matches(/\w*[A-Z]\w*/, 'Password must have a capital letter')
    .matches(/\d/, 'Password must have a number')
    // .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, "Password must have a special character")
    .min(8, ({ min }) => `Password must be at least ${min} characters`)
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords do not match')
    .required('Confirm password is required'),
  fullName: Yup.string()
    .matches(
      /^[A-Za-z]([-']?[A-Za-z]+)*( [A-Za-z]([-']?[A-Za-z]+)*)+$/,
      'Please enter valid full name'
    )
    .required('Full name is required'),
  phone: Yup.string()
    .matches(phoneRegex, 'Please enter valid phone number')
    .required('Phone number is required'),
  phones: Yup.object().shape({
    mobile: Yup.string()
      .matches(phoneRegex, 'Please enter valid mobile number')
      .required('Mobile number is required'),
    home: Yup.string()
      .matches(phoneRegex, 'Please enter valid home number')
      .nullable(),
    work: Yup.string()
      .matches(phoneRegex, 'Please enter valid work number')
      .nullable(),
  }),
  verificationCode: Yup.string()
    .length(5, 'Please enter valid code')
    .required('Please enter invitation code'),
  firstName: Yup.string().required('First name is required'),
  middleName: Yup.string().nullable(true),
  lastName: Yup.string().required('Last name is required'),
  address: Yup.object().shape({
    addressLine1: Yup.string(),
    addressLine2: Yup.string().nullable(),
    city: Yup.string(),
    state: Yup.string(),
    zipcode: Yup.string().matches(
      /^(?!0{3})[0-9]{3,5}$/,
      'Please enter valid zip code'
    ),
  }),
  pin: Yup.string()
    .length(4, 'Please enter valid code')
    .required('Please enter 4 digits code'),
  confirmPin: Yup.string()
    .length(4, 'Please enter valid code')
    .oneOf([Yup.ref('pin')], '4 digits codes do not match')
    .required('Please enter 4 digits code'),
  ssn: Yup.string()
    .matches(/^\d{3}-?\d{2}-?\d{4}$/, 'Please enter valid SSN')
    .required('SSN is required'),
  gender: Yup.string().required('Gender is required'),
  status: Yup.string().required('Status is required'),
  dob: Yup.date().required('DOB is required'),
  file: Yup.mixed(),
  insurances: Yup.array()
    .of(
      Yup.object().shape({
        company: Yup.string().required('Company is required'),
        id: Yup.string().required('Insurance ID is required'),
        groupNumber: Yup.string().required('Group # is required'),
        card: Yup.mixed().required('Card is required'),
      })
    )
    .required('Insurance is required'),
};

export default schema;
