import { useEffect, useState, useCallback, useContext, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';

import { FETCH_APPOINTMENTS } from 'graphql/queries';
import { displayTime } from 'utils/time';
import { EVENTS, SocketContext } from 'contexts/socket';
import { AuthContext } from '../auth';

const useAppointments = () => {
  const { me } = useContext(AuthContext);
  const { subscribe } = useContext(SocketContext);
  const appointmentUpdateSubscription = useRef();
  const appointmentsListUpdateSubscription = useRef();
  const [appointments, updateAppointments] = useState(null);
  const [fetchAppointments] = useLazyQuery(FETCH_APPOINTMENTS, {
    onCompleted: (data) => {
      updateAppointments(data?.appointments || []);
    },
    onError: () => {},
    fetchPolicy: 'no-cache',
  });

  const loadAppointments = useCallback(async () => {
    const appointmentDate = new Date();
    const from = moment(
      displayTime(appointmentDate, 'MM/DD/YYYY'),
      'MM/DD/YYYY'
    );
    const to = from.clone();
    to.add(1, 'day');
    fetchAppointments({
      variables: { from: from.toDate(), to: to.toDate() },
    });
  }, []);

  useEffect(() => {
    appointmentUpdateSubscription.current?.unsubscribe();
    appointmentUpdateSubscription.current = subscribe(
      EVENTS.APPOINTMENT_UPDATE,
      (payload) => {
        const { appointment: appointmentId, payload: updateData } = payload;
        if (!appointments) {
          return;
        }
        const checkAppointment = appointments.find(
          (item) => item._id === appointmentId
        );
        if (checkAppointment) {
          const newAppointments = appointments.map((appointment) => {
            if (appointment._id === appointmentId) {
              if (updateData.joined === me._id) {
                if (!appointment.joined) {
                  appointment.joined = [];
                }
                appointment.joined.push(me._id);
              }
              return { ...appointment, status: updateData.status };
            }
            return appointment;
          });
          updateAppointments([...newAppointments]);
        }
      }
    );

    return () => {
      appointmentUpdateSubscription.current?.unsubscribe();
    };
  }, [appointments]);

  useEffect(() => {
    if (!!me?._id) {
      appointmentsListUpdateSubscription.current?.unsubscribe();
      appointmentsListUpdateSubscription.current = subscribe(
        EVENTS.APPOINTMENTS,
        () => {
          loadAppointments();
        }
      );
      loadAppointments();
    } else {
      appointmentsListUpdateSubscription.current?.unsubscribe();
      updateAppointments(null);
    }
  }, [me?._id]);

  return { appointments, loadAppointments };
};

export default useAppointments;
