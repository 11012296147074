import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const modifierConfig = {
  dark: ({ theme }) => ({
    styles: `
      color: ${theme.colors.white_1};
    `,
  }),
  large: ({ theme }) => ({
    styles: `
      font-size: ${theme.dimensions.fontSize}
    `,
  }),
  small: ({ theme }) => ({
    styles: `
      font-size: ${theme.dimensions.fontSizeXSmall}
    `,
  }),
  center: () => ({
    styles: `
      width: 100%;
      text-align: center;
    `,
  }),
  labelLightWeight: ({ theme }) => `
    font-weight: ${theme.fontWeights.normal}
  `,
};

const styles = ({ theme }) => `
  color: ${theme.colors.text};
  font-size: ${theme.dimensions.fontSizeSmall};
  font-weight: ${theme.fontWeights.bold};
  display: block;
  > span {
    color: ${theme.colors.danger};
    font-size: 1.3em;
  }
`;

export const Label = buildStyledComponent('Form_Label', styled.span, styles, {
  modifierConfig,
});

export default Label;
