import { useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Column, Text, ScrollView, HR } from '@gaz/gaz-components.public';
import { cloneDeep } from 'lodash';

import { Container, Row } from 'common';
import NoteDetail from './Detail';
import { FETCH_NOTE } from 'graphql/queries';
import { loadingVar } from 'graphql/cache';
import { displayTime } from 'utils/time';
import {
  formatTitleAndName,
  formatAddress,
  maskString,
  MASK_TYPE,
} from 'utils/string';

const ViewNote = ({ note }) => {
  const practice = note.creator.practice;
  const provider = note.creator;
  const patient = note.user;
  const [fetchNote] = useLazyQuery(FETCH_NOTE);
  const [fullyLoadedNote, updateFullyLoadedNote] = useState(note);

  useEffect(() => {
    if (note?.content?.attachments) {
      if (note.content.attachments) {
        let attachedNoteId;
        for (let attachment of note.content.attachments) {
          if (attachment.category === 'note' && !attachment.noteContent) {
            attachedNoteId = attachment.note;
            break;
          }
        }
        if (attachedNoteId) {
          loadingVar(true);
          fetchNote({
            variables: {
              id: attachedNoteId,
            },
            onCompleted: ({ note: noteAttachment }) => {
              loadingVar(false);
              if (noteAttachment) {
                const noteClone = cloneDeep(note);
                for (let attachment of noteClone.content.attachments) {
                  if (attachment.category === 'note') {
                    attachment.noteContent = noteAttachment;
                    break;
                  }
                }
                updateFullyLoadedNote({ ...noteClone });
              }
            },
          });
        }
      }
    }
  }, [note]);
  return (
    <Container
      modifiers={['fluid', 'flexBox', 'fullHeight', 'backgroundWhite']}
    >
      <ScrollView>
        <Container>
          {note._id && (
            <Row modifiers={['smallGutters', 'end']}>
              <Text>Note: {note._id.substr(-7)}</Text>
            </Row>
          )}
          <Row modifiers={'smallGutters'}>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['block', 'medium']}>{practice.name}</Text>
              <Text modifiers={['block', 'primary', 'medium']}>
                {formatTitleAndName(provider)}
              </Text>
            </Column>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['block', 'medium']}>
                {formatAddress(practice.address)}
              </Text>
            </Column>
          </Row>
          <Row modifiers={'smallGutters'}>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['medium', 'muted']}>
                NPI{' '}
                <Text modifiers={['primary', 'medium']}>{practice.npi}</Text>
              </Text>
            </Column>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['medium', 'muted']}>
                PH{' '}
                <Text modifiers={['primary', 'medium']}>{practice.phone}</Text>
              </Text>
            </Column>
          </Row>
          <Row modifiers={'smallGutters'}>
            <Text modifiers={['medium', 'muted']}>
              Email:{' '}
              <Text modifiers={['primary', 'medium']}>{practice.email}</Text>
            </Text>
          </Row>
        </Container>
        <HR />
        <Container>
          <Row modifiers={'smallGutters'}>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['block', 'medium']}>
                {formatTitleAndName(patient)}
              </Text>
              <Text modifiers={['medium', 'muted']}>
                DoB{' '}
                <Text modifiers={['primary', 'medium']}>
                  {displayTime(patient.dob, 'MM/DD/YYYY')}
                </Text>
              </Text>
            </Column>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['block', 'medium']}>
                {formatAddress(patient.address)}
              </Text>
            </Column>
          </Row>
          <Row modifiers={'smallGutters'}>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['medium', 'muted']}>
                SS#{' '}
                <Text modifiers={['primary', 'medium']}>
                  {maskString(patient.ssn, MASK_TYPE.SSN)}
                </Text>
              </Text>
            </Column>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['medium', 'muted']}>
                PH{' '}
                <Text modifiers={['primary', 'medium']}>
                  {patient.phones.mobile}
                </Text>
              </Text>
            </Column>
          </Row>
          <Row modifiers={'smallGutters'}>
            <Text modifiers={['medium', 'muted']}>
              Email:{' '}
              <Text modifiers={['primary', 'medium']}>{patient.email}</Text>
            </Text>
          </Row>
        </Container>
        <HR />
        <Container>
          <Text modifiers={['block', 'medium', 'muted']}>
            Visit date/time{' '}
            <Text modifiers={['medium', 'primary']}>
              {note.signDate && displayTime(note.signDate, 'MM/DD/yyyy')}
            </Text>
          </Text>
          <NoteDetail note={fullyLoadedNote} />
        </Container>
      </ScrollView>
    </Container>
  );
};

export default ViewNote;
