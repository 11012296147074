import React from 'react';

import { Row, Text, Button } from '@gaz/gaz-components.public';
import assets from 'assets';

import Wrapper from './Wrapper';

export default function SectionTitle({ children, handleAction, noExpand }) {
  return (
    <Wrapper modifiers={['fluid', 'paddingLeft']}>
      <Row modifiers={['spaceBetween', 'middle']}>
        <Text modifiers={['bold', 'medium', 'white']}>{children}</Text>
        {!noExpand ? (
          <Button
            modifiers={['icon', 'transparent']}
            iconWidth={18}
            iconHeight={18}
            image={assets.icons.icGoNextRound}
            onClick={handleAction}
          />
        ) : (
          <Button
            modifiers={['icon', 'transparent']}
            iconWidth={18}
            iconHeight={18}
            onClick={handleAction}
          />
        )}
      </Row>
    </Wrapper>
  );
}
