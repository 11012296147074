import styled from 'styled-components';

import { Container } from '@gaz/gaz-components.public';
import { buildStyledComponent, px2rem } from 'style';

const styles = ({ theme }) => `
  box-shadow: 0px ${px2rem(-2)} ${px2rem(10)} rgba(46, 41, 78, 0.08);
  background-color: ${theme.colors.white};
  z-index: 1;
`;

export default buildStyledComponent(
  'MainLayout_Wrapper',
  styled(Container),
  styles
);
