import React from 'react';
import { useHistory } from 'react-router';

import { Column, IconButton } from '@gaz/gaz-components.public';
import assets from 'assets';
import urls from 'routes/urls';

import Wrapper from './Wrapper';

const NavMenu = () => {
  const history = useHistory();

  return (
    <Wrapper modifiers={['middle']}>
      <Column modifiers={['col']}>
        <IconButton
          title="Call"
          icon={assets.icons.footerCall}
          onClick={() => history.push(urls.DASHBOARD)}
        />
      </Column>
      <Column modifiers={['col']}>
        <IconButton
          title="Messages"
          icon={assets.icons.footerMessages}
          onClick={() => history.push(urls.CHAT_HISTORY)}
        />
      </Column>
    </Wrapper>
  );
};

export default NavMenu;
