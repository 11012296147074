import React from 'react';
import { noop } from 'lodash';
import { Row, Column, Avatar, Text, Button } from '@gaz/gaz-components.public';
import assets from 'assets';
import Wrapper from './Wrapper';

export default function IncomingCall({
  onJoinCall = noop,
  onRejectCall = noop,
  ...props
}) {
  return (
    <Wrapper {...props}>
      <Row>
        <Column modifiers={['col_3', 'flexBox', 'center']}>
          <Avatar
            modifiers={['round']}
            image={assets.images.emptyAvatar}
            width={54}
            height={54}
          />
        </Column>
        <Column modifiers={['col_9']}>
          <Text modifiers={['h2', 'bold', 'white', 'block']}>
            Diane Jones, PharmD/Rph
          </Text>
          <Text modifiers={['body', 'white', 'block']}>
            would like to join a call
          </Text>
        </Column>
        <Column modifiers={['col_4', 'col_offset_3', 'noVerticalPadding']}>
          <Button
            onClick={onRejectCall}
            modifiers={['danger', 'roundCorner', 'fullWidth']}
          >
            Reject
          </Button>
        </Column>
        <Column modifiers={['col_4', 'noVerticalPadding']}>
          <Button
            onClick={onJoinCall}
            modifiers={['success', 'roundCorner', 'fullWidth']}
          >
            Join
          </Button>
        </Column>
      </Row>
    </Wrapper>
  );
}
