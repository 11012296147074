import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { Button, Row, Text, ScrollView } from '@gaz/gaz-components.public';
import { Container } from 'common';
import { AlertsItem } from './Item';
import Book from './BookAppointment';
import { READ_PATIENT_ALERTS } from 'graphql/mutations';
import { startCase } from 'lodash';
import assets from 'assets';

export default ({
  categoryAlerts,
  handleBook,
  me,
  handleAfterRead,
  goBack,
}) => {
  const [showBookModal, setShowBookModal] = useState(false);
  const [alertsList, updateAlertsList] = useState([]);
  const [readAlerts] = useMutation(READ_PATIENT_ALERTS);

  useEffect(() => {
    const alerts = [];
    categoryAlerts.alerts.forEach((alert) => {
      alerts.push({
        ...alert,
        isSeen: alert.isSeen,
      });
    });
    updateAlertsList(alerts);
  }, []);

  useEffect(async () => {
    if (alertsList?.length > 0) {
      for (let alert of alertsList) {
        if (!alert.isSeen) {
          await readAlerts({
            variables: {
              alert: alert._id,
              measure: categoryAlerts.name,
            },
          });
          handleAfterRead(categoryAlerts.name);
          break;
        }
      }
    }
  }, [alertsList]);

  return (
    <>
      <Container
        modifiers={['fluid', 'fullHeight', 'flexBox', 'backgroundWhite']}
      >
        <Container modifiers={['backgroundGray', 'padding_2']}>
          <Row modifiers={['spaceBetween', 'middle']}>
            <Text modifiers={['large', 'bold']}>
              Alerts - {startCase(categoryAlerts.name)}
            </Text>
          </Row>
        </Container>
        <ScrollView>
          <Container>
            {alertsList.map((alert) => (
              <AlertsItem key={alert._id} alert={alert} />
            ))}
          </Container>
        </ScrollView>
        <Container modifiers={['flexFixed', 'footer']}>
          <Row modifiers={['spaceBetween']}>
            <Button
              modifiers={['icon', 'transparent']}
              image={assets.icons.icCloseGray}
              onClick={goBack}
            />
            <Button
              modifiers={['primary', 'roundCorner']}
              onClick={() => setShowBookModal(true)}
            >
              Book Appointment
            </Button>
          </Row>
        </Container>
      </Container>
      {showBookModal && (
        <Book
          patient={me}
          handleClose={() => setShowBookModal(false)}
          handleDone={handleBook}
        />
      )}
    </>
  );
};
