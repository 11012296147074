import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Vitals from './Vitals';
import Wellness from './Wellness';
import Medications from './Medications';
import Activity from './Activity';
import Diet from './Diet';

export default function Tasks() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/vital/:time`} component={Vitals} />
      <Route path={`${path}/wellness/:time`} component={Wellness} />
      <Route path={`${path}/medication/:time`} component={Medications} />
      <Route path={`${path}/activity/:time`} component={Activity} />
      <Route path={`${path}/diet/:time`} component={Diet} />
    </Switch>
  );
}
