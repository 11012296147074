import React from 'react';

import { Container, Row, Column, Text } from '@gaz/gaz-components.public';
import MenuIcon from './MenuIcon';

export default function MenuItem({ icon, label }) {
  return (
    <Container>
      <Row>
        <Column modifiers={['col_2']}>
          <MenuIcon src={icon} alt="" />
        </Column>
        <Column modifiers={['col_9', 'flexBox', 'verticalCenter']}>
          <Text modifiers={['secondaryColor', 'secondaryFont', 'subtitle']}>
            {label}
          </Text>
        </Column>
      </Row>
    </Container>
  );
}
