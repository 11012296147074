import { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { loadingVar } from 'graphql/cache';
import { useLazyQuery } from '@apollo/client';

import { FETCH_CALL_FROM_TOKEN } from 'graphql/queries';

import { Row, Button } from '@gaz/gaz-components.public';

import { Container } from 'common';
import { AuthContext } from 'contexts/auth';
import { MainLayout } from 'layouts';
import Wrapper from './Wrapper';
import urls from 'routes/urls';

const JoinCall = () => {
  const params = useLocation().search;
  const token = useMemo(() => {
    return new URLSearchParams(params).get('token');
  }, [params]);
  const history = useHistory();
  const { me, updateAuth } = useContext(AuthContext);
  const [errorMessage, updateErrorMessage] = useState(null);
  const [call, updateCall] = useState();
  const [appointment, updateAppointment] = useState();
  const [fetchCall] = useLazyQuery(FETCH_CALL_FROM_TOKEN, {
    variables: { token },
    onCompleted: async (data) => {
      loadingVar(false);
      if (data.callFromToken) {
        const {
          me: authData,
          call: callData,
          appointment: appointmentData,
        } = data.callFromToken;
        if (authData.user.isTempAuth) {
          await updateAuth(authData.user, authData.token);
        } else {
        }
        updateAppointment(appointmentData);
      } else {
        updateErrorMessage('Meeting link is invalid!');
      }
    },
    onError: () => {
      loadingVar(false);
      updateErrorMessage('Oops! Unknown error occured.');
    },
  });

  useEffect(() => {
    if (!(token?.trim().length > 0)) {
      updateErrorMessage('Meeting link is invalid!');
    } else {
      loadingVar(true);
      fetchCall();
    }
  }, []);
  const goHome = useCallback(() => {
    history.replace(urls.DASHBOARD);
  });

  return (
    <MainLayout>
      <MainLayout.Content>
        <Wrapper errorMessage={errorMessage} appointment={appointment} />
        <Container modifiers={['flexFixed']}>
          <Row modifiers={['center', 'withGutters']}>
            <Button onClick={goHome}>Log In</Button>
          </Row>
        </Container>
      </MainLayout.Content>
    </MainLayout>
  );
};

export default JoinCall;
