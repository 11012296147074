import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { Row, Column } from '@gaz/gaz-components.public';
import assets from 'assets';
import urls from 'routes/urls';

import Card from './Card';
import { PatientContext } from 'contexts/patient';

const Reports = () => {
  const history = useHistory();
  const { reportCount } = useContext(PatientContext);

  return (
    <Card modifiers={['fluid']} onClick={() => history.push(urls.REPORTS)}>
      <Row modifiers={['fullHeight', 'middle']}>
        <Column modifiers={['col_3', 'flexBox', 'verticalContentCenter']}>
          <Card.Icon src={assets.icons.icReportGold} />
        </Column>
        <Column modifiers={['col', 'fluid']}>
          <Card.Title>Reports</Card.Title>
          {reportCount > 0 && <Card.Subtitle>{reportCount} New</Card.Subtitle>}
        </Column>
      </Row>
    </Card>
  );
};

export default Reports;
