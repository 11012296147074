import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  fluid: () => ({
    styles: `
      padding: 0;
    `,
  }),
  noDisplay: () => ({
    styles: `
      display: none;
    `,
  }),
  spaceBetween: () => ({
    styles: `
      justify-content: space-between;
    `,
  }),
  flexBox: () => ({
    styles: `
      display: flex;
      flex-direction: column;
    `,
  }),
  fullHeight: () => ({
    styles: `
      height: 100%;
      overflow: hidden;
    `,
  }),
  height_100: () => ({
    styles: `
      height: 100%;
    `,
  }),
  padding_2: ({ theme }) => `
    padding: ${theme.dimensions.padding_2};
  `,
  footer: ({ theme }) => `
    box-shadow: ${theme.dimensions.footerBoxShadow};
    flex: 0;
  `,
  flexFixed: () => `
    flex: 0;
  `,
  flexOne: () => `
    flex: 1;
  `,
  shadow: ({ theme }) => `
    box-shadow: ${theme.dimensions.boxShadow};
  `,
  backgroundWhite: ({ theme }) => `
    background: ${theme.colors.white};
  `,
  backgroundGray: ({ theme }) => `
    background: ${theme.colors.background};
  `,
  backgroundDarkGray: ({ theme }) => `
    background: ${theme.colors.darkGrey};
  `,
  backgroundSuccess: ({ theme }) => `
    background: ${theme.colors.success};
  `,
  backgroundDanger: ({ theme }) => `
    background: ${theme.colors.danger};
  `,
  withGutters: ({ theme }) => `
    margin-top: ${theme.dimensions.gutter};
    margin-bottom: ${theme.dimensions.gutter};
  `,
  topGutters_1: ({ theme }) => `
    margin-top: ${theme.dimensions.padding_1};
  `,
  topGutters_2: ({ theme }) => `
    margin-top: ${theme.dimensions.padding_2};
  `,
  bottomGutters_1: ({ theme }) => `
    margin-bottom: ${theme.dimensions.padding_1};
  `,
  bottomGutters_2: ({ theme }) => `
    margin-bottom: ${theme.dimensions.padding_2};
  `,
  paddingLeft: ({ theme }) => `
    padding-left: ${theme.dimensions.gridPad};
  `,
  borderPrimary: ({ theme }) => `
    border: solid ${px2rem(1)} ${theme.colors.darkGrey};
  `,
  noBottomPadding: () => `
    padding-bottom: 0;
  `,
  section: ({ theme }) => `
    background: ${theme.colors.white};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
    border-radius: ${theme.dimensions.borderRadius_2};
    padding: ${theme.dimensions.padding_2};
    margin: ${theme.dimensions.padding_2} 0;
  `,
  card: ({ theme }) => `
    background: ${theme.colors.white};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
    border-radius: ${theme.dimensions.borderRadius_2};
    padding: ${theme.dimensions.padding_1};
    margin: ${theme.dimensions.padding_1} 0;
  `,
};

const styles = ({ theme }) => `
  box-sizing: border-box;
  padding: ${theme.dimensions.gridPad};
  position: relative;
  width: 100%;
`;

export default buildStyledComponent('Container', styled.div, styles, {
  modifierConfig,
});
