import { useContext } from 'react';
import styled from 'styled-components';

import { Container } from '@gaz/gaz-components.public';
import Measure from './Measure';
import { PatientContext } from 'contexts/patient';
import { buildStyledComponent, px2rem } from 'style';

const TASKS_ITEMS = ['vital', 'medication', 'activity', 'wellness', 'diet'];

const Tasks = ({ ...props }) => {
  const { tasks } = useContext(PatientContext);
  return (
    <Container {...props}>
      {TASKS_ITEMS.map((item) => (
        <Measure
          key={item}
          task={tasks ? tasks[item] : null}
          taskDomain={item}
        />
      ))}
    </Container>
  );
};

export default buildStyledComponent(
  'Dashboard_Tasks',
  styled(Tasks),
  ({ theme }) => `
    padding: ${theme.dimensions.padding_1};

    > * {
      min-height: ${px2rem(40)};
    }

    > :last-child {
      min-height: ${px2rem(24)};
    }
  `
);
