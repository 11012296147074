import { createContext, useState, useEffect, useRef, useCallback } from 'react';
import Video from 'twilio-video';
import { useLazyQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';

import { Modal } from 'common';
import { APPOINTMENT_CALL, INSTANT_CALL } from 'graphql/queries';
import { UPDATE_CALL_STATUS } from 'graphql/mutations';
import { REDUX_ACTIONS } from 'redux/constants';

export const CALL_TYPES = {
  UNSCHEDULED: 'unscheduled',
  SCHEDULED: 'scheduled',
  PSTN: 'pstn',
};

export const CALL_STATUS = {
  CONNECTING: 'connecting',
  WAITING: 'waiting',
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
};

export const CallServiceContext = createContext();

export const CallServiceProvider = ({ children, setTimerData }) => {
  const [status, setStatus] = useState();
  const [hasOngoingCall, setHasOngoingCall] = useState(false);
  const ongoingCallRef = useRef(false);
  const [participants, setParticipants] = useState();
  const [callParticipants, setCallParticipants] = useState([]);
  const [room, setRoom] = useState();
  const [muted, setMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);
  const callRef = useRef();
  const [initUnscheduledCall] = useLazyQuery(INSTANT_CALL, {
    onCompleted: (data) => {
      callRef.current = data.instantCall;
      connectRoom();
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });
  const [initScheduledCall] = useLazyQuery(APPOINTMENT_CALL, {
    onCompleted: (data) => {
      callRef.current = data.appointmentCall;
      connectRoom();
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });
  const [updateCallStatus] = useMutation(UPDATE_CALL_STATUS);
  const callTimeElapsedRef = useRef(0);
  const callTimerRef = useRef(null);

  const joinCall = useCallback(() => {}, []);

  const startCall = useCallback(
    (type, participant, info) => {
      if (ongoingCallRef.current === true) {
        Modal.info({
          text: 'There is a call ongoing.',
        });
        return false;
      }
      if (callTimerRef.current) {
        clearInterval(callTimerRef.current);
        callTimerRef.current = null;
      }
      setHasOngoingCall(true);
      ongoingCallRef.current = true;
      setMuted(false);
      setVideoMuted(false);
      setParticipants([participant]);
      setStatus(CALL_STATUS.CONNECTING);
      setRoom(null);
      callTimeElapsedRef.current = 0;
      setTimerData({ time: 0 });
      if (type === CALL_TYPES.UNSCHEDULED) {
        initUnscheduledCall({
          variables: {
            user: participant._id,
          },
        });
      } else if (type === CALL_TYPES.SCHEDULED) {
        initScheduledCall({
          variables: {
            appointment: info.appointment._id,
          },
        });
      }
    },
    [initScheduledCall, initUnscheduledCall, setTimerData]
  );

  const disconnect = useCallback(() => {
    if (callTimerRef.current) {
      clearInterval(callTimerRef.current);
      callTimerRef.current = null;
    }
    callTimeElapsedRef.current = 0;
    setTimerData({ time: 0 });

    setStatus(CALL_STATUS.DISCONNECTED);
    setHasOngoingCall(false);
    ongoingCallRef.current = false;
    if (!!room) {
      room.disconnect();
    }
    setCallParticipants([]);
    setParticipants([]);
    setRoom(null);
    if (callRef.current) {
      updateCallStatus({
        variables: {
          call: callRef.current._id,
          status: 'ended',
          time: new Date(),
        },
      });
    }
  }, [room, setTimerData, updateCallStatus]);

  const connectRoom = useCallback(() => {
    if (!ongoingCallRef.current) {
      disconnect();
      return;
    }
    const call = callRef.current;
    if (!call || !call.token) {
      disconnect();
      return;
    }
    const roomOptions = {
      name: call._id,
      video: true,
      audio: true,
    };
    Video.connect(call.token, roomOptions)
      .then((room) => {
        setStatus(CALL_STATUS.WAITING);
        setRoom(room);
      })
      .catch((err) => {
        roomOptions.video = false;
        Video.connect(call.token, roomOptions)
          .then((room) => {
            setStatus(CALL_STATUS.WAITING);
            setRoom(room);
          })
          .catch((err1) => {
            console.error(err1);
            disconnect();
          });
      });
  }, [disconnect]);

  const startTimer = useCallback(() => {
    if (!callTimerRef.current) {
      callTimerRef.current = setInterval(() => {
        callTimeElapsedRef.current = callTimeElapsedRef.current + 1;
        setTimerData({ time: callTimeElapsedRef.current });
      }, 1000);
    }
  }, [setTimerData]);

  const enableAudio = useCallback(
    (enable) => {
      if (room?.localParticipant) {
        room.localParticipant.audioTracks.forEach((publication) => {
          if (enable) {
            publication.track.enable();
          } else {
            publication.track.disable();
          }
        });
      }
    },
    [room]
  );

  const enableVideo = useCallback(
    (enable) => {
      if (room?.localParticipant) {
        room.localParticipant.videoTracks.forEach((publication) => {
          if (enable) {
            publication.track.enable();
          } else {
            publication.track.disable();
          }
        });
      }
    },
    [room]
  );

  const handleMute = useCallback(() => {
    enableAudio(muted);
    setMuted(!muted);
  }, [enableAudio, setMuted, muted]);

  const handleVideoMute = useCallback(() => {
    enableVideo(videoMuted);
    setVideoMuted(!videoMuted);
  }, [enableVideo, setVideoMuted, videoMuted]);

  useEffect(() => {
    if (!!room) {
      const participantConnected = (participant) => {
        if (status === CALL_STATUS.WAITING) {
          startTimer();
          setStatus(CALL_STATUS.CONNECTED);
          updateCallStatus({
            variables: {
              call: callRef.current._id,
              status: 'started',
              time: new Date(),
            },
          });
        }
        setCallParticipants((prevParticipants) => [
          ...prevParticipants,
          participant,
        ]);
      };

      const participantDisconnected = (participant) => {
        // setCallParticipants((prevParticipants) =>
        //   prevParticipants.filter((p) => p !== participant)
        // );
        disconnect();
      };

      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.participants.forEach(participantConnected);
      return () => {
        room.off('participantConnected', participantConnected);
        room.off('participantDisconnected', participantDisconnected);
      };
    }
  }, [room]);

  useEffect(() => {
    if (!!room) {
      enableAudio(!muted);
      enableVideo(!videoMuted);
    }
  }, [room, enableAudio, enableVideo, muted, videoMuted]);

  return (
    <CallServiceContext.Provider
      value={{
        callStatus: status,
        callRef,
        hasOngoingCall,
        room,
        participants,
        callParticipants,
        startCall,
        joinCall,
        disconnect,
        muted,
        handleMute,
        videoMuted,
        handleVideoMute,
      }}
    >
      {children}
    </CallServiceContext.Provider>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTimerData: (payload) =>
      dispatch({ type: REDUX_ACTIONS.CALL_TIMER_SET, payload }),
  };
};

export default connect(null, mapDispatchToProps)(CallServiceProvider);
