import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const styles = ({ theme }) => `
  color: ${theme.colors.primary};
  height: 100%;
  background-color: ${theme.colors.grey};
  overflow-y: auto;
`;

const Wrapper = buildStyledComponent(
  'DefaultLayout_Wrapper',
  styled.div,
  styles
);

export default Wrapper;
