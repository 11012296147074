import React, { useRef, useEffect } from 'react';
import { noop } from 'lodash';
import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const mediaInput = () => `
  display: none;
`;

const MediaInput = buildStyledComponent(
  'AddMedia_MediaInput',
  styled.input,
  mediaInput
);

export default function AddMedia({ mediaType = 'image/*', handleDone = noop }) {
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [fileInputRef]);

  const onChange = ({ target }) => {
    handleDone(target.files[0], target.files[0].type);
  };

  return (
    <div>
      <MediaInput
        type="file"
        ref={fileInputRef}
        accept={mediaType}
        capture="user"
        onChange={onChange}
      />
    </div>
  );
}
