import styled from 'styled-components';

import { buildStyledComponent } from 'style';
import { DESKTOP_VIEWPORT_WIDTH } from 'utils/constants';

const modifierConfig = {
  superPriority: () => `
    z-index: 1000;
  `,
  contained: ({ theme }) => `
    position: static;
    z-index: 1000;
    background-color: #FFFF;
    border-radius: 12px;
    height: fit-content;
    width: fit-content;
    padding: 15px;
    display: flex;
    flex-direction: column;
    margin: auto;
    left: 15px;
    right: 15px;
  `,
  large: () => `
    width: 90%;
  `,
  medium: () => `
    width: 65%;
  `,
};

const styles = ({ theme }) => `
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
  background-color: #FFF;

  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 769px) {
    max-width: ${DESKTOP_VIEWPORT_WIDTH};
    margin: auto;
  }

  * {
    touch-action: auto;
  }
`;

export default buildStyledComponent('Modal', styled.div, styles, {
  modifierConfig,
});
