import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'style';

const styles = ({ theme }) => `
  box-shadow: ${theme.dimensions.boxShadow_2};
  padding: ${px2rem(5)} ${px2rem(8)};
`;

export const TimerWrapper = buildStyledComponent(
  'Wrapper_Timer',
  styled.div,
  styles
);
