import React, { useContext, useState, useRef } from 'react';
import _ from 'lodash';
import {
  Tabs,
  BackButton,
  Button,
  Container,
} from '@gaz/gaz-components.public';

import { MainLayout } from 'layouts';
import { AuthContext } from 'contexts/auth';
import { UPDATE_PATIENT } from 'graphql/mutations';

import Info from './Info';
import Devices from './Devices';
import Contact from './Contact';
import { useMutation } from '@apollo/client';
import { loadingVar } from 'graphql/cache';

const { Content, Header } = MainLayout;

const Profile = () => {
  const { me, fetchMe } = useContext(AuthContext);
  const [updatePatient, { loading }] = useMutation(UPDATE_PATIENT, {
    onCompleted: () => {
      fetchMe();
    },
    onError: () => {},
  });

  const [tab, setTab] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const profileRef = useRef();
  const devicesRef = useRef();
  const contactRef = useRef();

  loadingVar(loading);

  const handleClick = () => {
    if (isEditing) {
      update();
    } else {
      setIsEditing(true);
    }
  };

  const handleTabChange = (newTab) => {
    setTab(newTab);
  };

  const update = async () => {
    const refs = [profileRef, devicesRef, contactRef];
    let formData = {};

    for (let i = 0; i < 3; i++) {
      await refs[i].current.submitForm();
      if (!refs[i].current.isValid) {
        setTab(i);
        return;
      }

      formData = {
        ...formData,
        ...refs[i].current.values,
      };
    }

    const userKeys = [
      'firstName',
      'lastName',
      'middleName',
      'email',
      'phones',
      'photo',
      'address',
    ];
    updatePatient({
      variables: {
        id: me._id,
        patient: {
          ..._.omit(formData, userKeys),
          user: _.pick(formData, userKeys),
        },
      },
    });

    setIsEditing(false);
  };

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton />
        </Header.Left>
        <Header.Center>
          <Header.Title modifiers={['secondaryColor']}>Profile</Header.Title>
        </Header.Center>
        <Header.Right>
          <Button
            onClick={handleClick}
            modifiers={['primary', isEditing && 'outlinePrimary', 'noShadow']}
          >
            {isEditing ? 'Done' : 'Edit'}
          </Button>
        </Header.Right>
      </Header>
      <Content>
        <Tabs current={tab} onChange={handleTabChange}>
          <Tabs.Tab title="Profile" />
          <Tabs.Tab title="Devices" />
          <Tabs.Tab title="Contact" />
        </Tabs>
        <Container modifiers="flexOne" style={{ overflowY: 'auto' }}>
          <Info
            ref={profileRef}
            me={me}
            isEditing={isEditing}
            show={tab === 0}
          />
          <Devices
            ref={devicesRef}
            me={me}
            isEditing={isEditing}
            show={tab === 1}
          />
          <Contact
            ref={contactRef}
            me={me}
            isEditing={isEditing}
            show={tab === 2}
          />
        </Container>
      </Content>
    </MainLayout>
  );
};

export default Profile;
