import {
  createContext,
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
} from 'react';
import { useLazyQuery } from '@apollo/client';

import {
  FETCH_CAREPLANS,
  FETCH_PATIENT_ORDERS,
  FETCH_NOTES,
  FETCH_PATIENT_DIAGNOSES,
} from 'graphql/queries';

import { EVENTS, SocketContext } from 'contexts/socket';

export const RecordsContext = createContext();

const RecordsProvider = ({ children }) => {
  const [careplans, setCareplans] = useState(null);
  const [orders, setOrders] = useState(null);
  const [notes, setNotes] = useState(null);
  const [diagnosis, setDiagnosis] = useState(null);
  const [careplanPeriod, updateCareplanPeriod] = useState('3m');
  const [notePeriod, updateNotePeriod] = useState('3m');
  const [orderPeriod, updateOrderPeriod] = useState('3m');
  const [diagnosisPeriod, updateDiagonsisPeriod] = useState('3m');
  const { subscribe } = useContext(SocketContext);
  const notesListUpdateSubscription = useRef();
  const careplanListUpdateSubscription = useRef();
  const ordersListUpdateSubscription = useRef();
  const diagnosisListUpdateSubscription = useRef();

  const [queryCareplans] = useLazyQuery(FETCH_CAREPLANS, {
    variables: {
      period: careplanPeriod,
    },
    onCompleted: (data) => {
      setCareplans(data.patientCareplans);
    },
    onError: () => {
      setCareplans([]);
    },
    fetchPolicy: 'no-cache',
  });

  const fetchCareplans = useCallback(() => {
    setCareplans(null);
    queryCareplans();
  }, []);

  useEffect(() => {
    if (!!careplanPeriod) {
      fetchCareplans();
      careplanListUpdateSubscription.current?.unsubscribe();
      careplanListUpdateSubscription.current = subscribe(
        EVENTS.PATIENT_CAREPLANS,
        () => {
          fetchCareplans();
        }
      );
    }
    return () => {
      careplanListUpdateSubscription.current?.unsubscribe();
    };
  }, [careplanPeriod, fetchCareplans]);

  const [queryOrders] = useLazyQuery(FETCH_PATIENT_ORDERS, {
    variables: {
      period: orderPeriod,
    },
    onCompleted: (data) => {
      setOrders(data.patientMedicationOrders);
    },
    onError: () => {
      setOrders([]);
    },
    fetchPolicy: 'no-cache',
  });

  const fetchOrders = useCallback(() => {
    setOrders(null);
    queryOrders();
  }, []);

  useEffect(() => {
    if (!!orderPeriod) {
      fetchOrders();
      ordersListUpdateSubscription.current?.unsubscribe();
      ordersListUpdateSubscription.current = subscribe(
        EVENTS.PATIENT_MEDICATION_ORDERS,
        () => {
          fetchOrders();
        }
      );
    }
    return () => {
      ordersListUpdateSubscription.current?.unsubscribe();
    };
  }, [orderPeriod, fetchOrders]);

  const [queryNotes] = useLazyQuery(FETCH_NOTES, {
    variables: { period: notePeriod },
    onCompleted: (data) => {
      setNotes(data.notes);
    },
    onError: () => {
      setNotes([]);
    },
    fetchPolicy: 'no-cache',
  });

  const fetchNotes = useCallback(() => {
    setNotes(null);
    queryNotes();
  }, []);

  useEffect(() => {
    if (!!notePeriod) {
      fetchNotes();
      notesListUpdateSubscription.current?.unsubscribe();
      notesListUpdateSubscription.current = subscribe(
        EVENTS.PATIENT_NOTES,
        () => {
          fetchNotes();
        }
      );
    }
    return () => {
      notesListUpdateSubscription.current?.unsubscribe();
    };
  }, [notePeriod, fetchNotes]);

  const [queryDiagnosis] = useLazyQuery(FETCH_PATIENT_DIAGNOSES, {
    variables: { period: diagnosisPeriod },
    onCompleted: ({ patientDiagnoses }) => {
      setDiagnosis(patientDiagnoses);
    },
    onError: () => {
      setDiagnosis(null);
    },
    fetchPolicy: 'no-cache',
  });

  const fetchDiagnosis = useCallback(() => {
    setDiagnosis(null);
    queryDiagnosis();
  }, []);

  useEffect(() => {
    if (!!diagnosisPeriod) {
      fetchDiagnosis();
      diagnosisListUpdateSubscription.current?.unsubscribe();
      diagnosisListUpdateSubscription.current = subscribe(
        EVENTS.PATIENT_NOTES,
        () => {
          fetchDiagnosis();
        }
      );
    }
    return () => {
      diagnosisListUpdateSubscription.current?.unsubscribe();
    };
  }, [diagnosisPeriod, fetchDiagnosis]);

  return (
    <RecordsContext.Provider
      value={{
        careplans,
        fetchCareplans,
        careplanPeriod,
        updateCareplanPeriod,
        orders,
        fetchOrders,
        orderPeriod,
        updateOrderPeriod,
        notes,
        fetchNotes,
        notePeriod,
        updateNotePeriod,
        diagnosis,
        fetchDiagnosis,
        diagnosisPeriod,
        updateDiagonsisPeriod,
      }}
    >
      {children}
    </RecordsContext.Provider>
  );
};

export default RecordsProvider;
