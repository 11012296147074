import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const modifierConfig = {
  alert: ({ theme }) => `
    color: ${theme.colors.success};
    font-weight: ${theme.fontWeights.bold};
  `,
};

const styles = ({ theme }) => `
  color: ${theme.colors.text};
  font-size: 10px;
`;

export default buildStyledComponent(
  'Dashboard_Card_Subtitle',
  styled.div,
  styles,
  { modifierConfig }
);
