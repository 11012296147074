import React from 'react';

import assets from 'assets';
import { Button } from '@gaz/gaz-components.public';

export const MenuButton = ({ handleClick }) => {
  return (
    <Button
      onClick={handleClick}
      modifiers={['icon', 'transparent']}
      image={assets.icons.icHamburger}
      imageWidth={16}
      imageHeight={16}
    />
  );
};

export default MenuButton;
