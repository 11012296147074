import icons from './icons';
import images from './images';
import fonts from './fonts';

const assets = {
  icons,
  images,
  fonts,
};

export default assets;
