import { useEffect, useRef, useCallback, useContext } from 'react';

import {
  Container,
  Row,
  Column,
  Text,
  ScrollView,
} from '@gaz/gaz-components.public';
import { Loader, PeriodPicker } from 'common';
import Item from './Item';
import { EVENTS, SocketContext } from 'contexts/socket';
import { RecordsContext } from '../Context';

export default () => {
  const {
    orderPeriod: period,
    updateOrderPeriod: setPeriod,
    orders: meds,
    fetchOrders,
  } = useContext(RecordsContext);

  const { subscribe } = useContext(SocketContext);
  const orderSubscription = useRef();

  const handleSelectPeriod = (value) => {
    setPeriod(value);
  };

  const ordersUpdateEventHandler = useCallback(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    orderSubscription.current?.unsubscribe();
    orderSubscription.current = subscribe(
      EVENTS.MEDICATION_ORDERS,
      ordersUpdateEventHandler
    );
    return () => {
      orderSubscription.current?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!period) {
      setPeriod('3m');
    }
  }, []);

  return (
    <Container modifiers={['flexBox', 'fullHeight']}>
      <Container modifiers={['flexFixed', 'fluid']}>
        <Row modifiers={['middle', 'spaceBetween']}>
          <Text modifiers={['medium', 'bold']}>
            Ordered Medications {meds?.length > 0 && `(${meds.length})`}
          </Text>
          <Column>
            <PeriodPicker value={period} handleSelect={handleSelectPeriod} />
          </Column>
        </Row>
      </Container>
      <ScrollView>
        <Container>
          {meds?.length > 0 ? (
            meds?.map((order, index) => <Item key={index} order={order} />)
          ) : (
            <Text modifiers={['muted']}>You don't have any medications.</Text>
          )}
        </Container>
      </ScrollView>
    </Container>
  );
};
