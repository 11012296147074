import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const styles = () => `
  width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
`;

const Video = buildStyledComponent('MediaViewer_Video', styled.video, styles);

export default Video;
