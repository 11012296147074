import React, { useState } from 'react';
import { get, noop } from 'lodash';

import { Input } from '@gaz/gaz-components.public';

import Wrapper from './Wrapper';
import Image from './Image';

import Error from '../FormError';
import Label from '../FormLabel';

export function FormIconInput(props) {
  const [focused, setFocused] = useState(false);
  const {
    field: { name, onBlur, onChange, value },
    form: { errors, touched, setFieldTouched },
    hasLabel,
    hasBorderBottom,
    asInfo,
    asGrayInfo,
    labelLightWeight,
    dark,
    smallFeedback,
    placeholder,
    required,
    innerRef,
    icon,
    handleClickIcon = noop,
    noBorder,
    noPadding,
    iconModifiers,
    iconWidth,
    iconHeight,
    iconPosition = 'left',
    hidden,
    round,
    modifiers = [],
    label = '',
    largeLabel,
    wrapperModifiers = [],
    ...inputProps
  } = props;

  const hasError = get(errors, name) && get(touched, name);
  const valid = !get(errors, name) && value;

  return (
    <div>
      {hasLabel && (
        <Label
          modifiers={[
            dark && 'dark',
            labelLightWeight && 'labelLightWeight',
            largeLabel && 'large',
            largeLabel && 'bottomSpacing',
          ]}
        >
          {label} {required && <span>*</span>}
        </Label>
      )}
      <Wrapper
        modifiers={[
          modifiers.includes('squared') && 'squared',
          round && 'round',
          ...wrapperModifiers,
        ]}
      >
        {iconPosition === 'left' && (
          <Image
            src={icon}
            alt=""
            modifiers={iconModifiers}
            iconWidth={iconWidth}
            iconHeight={iconHeight}
            onClick={handleClickIcon}
          />
        )}
        <Input
          modifiers={[
            dark && 'dark',
            hasError && 'error',
            hasBorderBottom && 'borderBottomOnly',
            asInfo && 'asInfo',
            asGrayInfo && 'asGrayInfo',
            (valid || focused) && 'valid',
            hidden && 'hidden',
            'noBorder',
            ...modifiers,
          ]}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(name)(e)}
          onBlur={() => {
            setFieldTouched(name);
            onBlur(name);
            setFocused(false);
          }}
          onFocus={() => {
            setFocused(true);
          }}
          ref={innerRef}
          {...inputProps}
        />
        {iconPosition === 'right' && (
          <Image
            src={icon}
            alt=""
            modifiers={iconModifiers}
            iconWidth={iconWidth}
            iconHeight={iconHeight}
            onClick={handleClickIcon}
          />
        )}
      </Wrapper>
      {hasError && (
        <Error modifiers={[smallFeedback && 'small']}>
          {get(errors, name)}
        </Error>
      )}
    </div>
  );
}

export default FormIconInput;
