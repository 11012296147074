import { ApolloProvider } from '@apollo/client';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { BaseLayout } from 'layouts';
import Terms from 'pages/Terms';
import NotFound from 'pages/NotFound';
import Auth from 'pages/Auth';
import Contacts from 'pages/Contacts';
import Chat from 'pages/Chat';
import ChatHistory from 'pages/ChatHistory';
import Onboard from 'pages/Onboard';
import Dashboard from 'pages/Dashboard';
import Profile from 'pages/Profile';
import CareTeam from 'pages/CareTeam';
import Records from 'pages/Records';
import CreateGroup from 'pages/CreateGroup';
import Messaging from 'pages/Messaging';
import Invitations from 'pages/Invitations';
import Reports from 'pages/Reports';
import Tasks from 'pages/Tasks';
import Group from 'pages/Group';
import Progress from 'pages/Progress';
import Appointments from 'pages/Appointments';
import ContactProfile from 'pages/ContactProfile';
import Security from 'pages/Security';
import Notification from 'pages/Notification';
import Alerts from 'pages/Alerts';
import JoinCall from 'pages/JoinCall';
import PaymentMethod from 'pages/PaymentMethod';

import ProtectedRoute from 'routes/Protected';
import urls from 'routes/urls';
import { client } from 'graphql/client';
import AuthRedirectProvider from 'contexts/authRedirect';
import AuthProvider from 'contexts/auth';
import PatientContextProvider from 'contexts/patient';
import SocketProvider from 'contexts/socket';
import CallServiceProvider from 'contexts/call';
import { FabProvider } from 'contexts/fab';
import { NavMenuProvider } from 'contexts/navMenu';
import { CallRibbonProvider } from 'contexts/callRibbon';

import theme from './style/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <Router>
          <AuthRedirectProvider>
            <SocketProvider>
              <AuthProvider>
                <PatientContextProvider>
                  <CallServiceProvider>
                    <NavMenuProvider>
                      <FabProvider>
                        <CallRibbonProvider>
                          <BaseLayout>
                            <Switch>
                              <Route path={urls.AUTH} component={Auth} />
                              <Route
                                exact
                                path={urls.TERMS}
                                component={Terms}
                              />
                              <Route
                                path={urls.JOIN_CALL}
                                component={JoinCall}
                              />
                              <ProtectedRoute
                                exact
                                path={urls.CHAT}
                                component={Chat}
                              />
                              <ProtectedRoute
                                exact
                                path={urls.CHAT_HISTORY}
                                component={ChatHistory}
                              />
                              <ProtectedRoute
                                exact
                                path={urls.CONTACTS}
                                component={Contacts}
                              />
                              <ProtectedRoute
                                exact
                                path={urls.CONTACT}
                                component={ContactProfile}
                              />
                              <ProtectedRoute
                                exact
                                path={urls.ONBOARD}
                                component={Onboard}
                              />
                              <ProtectedRoute
                                exact
                                path={urls.DASHBOARD}
                                component={Dashboard}
                              />
                              <ProtectedRoute
                                exact
                                path={urls.PROFILE}
                                component={Profile}
                              />
                              <ProtectedRoute
                                exact
                                path={urls.CARE_TEAM}
                                component={CareTeam}
                              />
                              <ProtectedRoute
                                exact
                                path={urls.CREATE_GROUP}
                                component={CreateGroup}
                              />
                              <ProtectedRoute
                                exact
                                path={urls.GROUP}
                                component={Group}
                              />
                              <ProtectedRoute
                                exact
                                path={urls.MESSAGING}
                                component={Messaging}
                              />
                              <ProtectedRoute
                                exact
                                path={urls.INVITATIONS}
                                component={Invitations}
                              />
                              <ProtectedRoute
                                path={urls.RECORDS}
                                component={Records}
                              />
                              <ProtectedRoute
                                path={urls.REPORTS}
                                component={Reports}
                              />
                              <ProtectedRoute
                                path={urls.TASKS}
                                component={Tasks}
                              />
                              <ProtectedRoute
                                path={urls.PROGRESS}
                                component={Progress}
                              />
                              <ProtectedRoute
                                path={urls.APPOINTMENTS}
                                component={Appointments}
                              />
                              <ProtectedRoute
                                exact
                                path={urls.SECURITY}
                                component={Security}
                              />
                              <ProtectedRoute
                                exact
                                path={urls.NOTIFICATION}
                                component={Notification}
                              />
                              <ProtectedRoute
                                exact
                                path={urls.ALERTS}
                                component={Alerts}
                              />
                              <ProtectedRoute
                                exact
                                path={urls.PAYMENTMETHODS}
                                component={PaymentMethod}
                              />
                              <Redirect to={urls.AUTH} />
                            </Switch>
                          </BaseLayout>
                        </CallRibbonProvider>
                      </FabProvider>
                    </NavMenuProvider>
                  </CallServiceProvider>
                </PatientContextProvider>
              </AuthProvider>
            </SocketProvider>
          </AuthRedirectProvider>
        </Router>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
