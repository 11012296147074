import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  horizontal: ({ theme }) => `
    overflow-x: auto;
    overflow-y: hidden;
  `,
  both: ({ theme }) => `
    overflow: auto;
  `,
  noPadding: ({ theme }) => `
    padding: 0;
    margin: 0;
  `,
  backgroundDark: ({ theme }) => `
    background: ${theme.colors.grey};
  `,
  flexWeight_2: () => `
    flex: 2 1;
  `,
};

const styles = ({ theme }) => `
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  margin: ${theme.dimensions.padding_1};
`;

export const ScrollView = buildStyledComponent(
  'ScrollView',
  styled.div,
  styles,
  {
    modifierConfig,
  }
);

export default ScrollView;
