import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { buildStyledComponent } from 'style';
import Notification from './Notification';
import { EVENTS, SocketContext } from 'contexts/socket';
import { CALL_TYPES, CallServiceContext } from 'contexts/call';
import { getUser } from 'graphql/utils';
import urls from 'routes/urls';

const generateNotificationId = () =>
  `${Date.now()}-${Math.floor(Math.random() * 1000)}`;

const Notifications = ({ ...props }) => {
  const [notifications, updateNotifications] = useState([]);
  const { subscribe } = useContext(SocketContext);
  const { callRef, participants } = useContext(CallServiceContext);
  const meetNowSubscription = useRef();
  const history = useHistory();

  const meetNowEventHandler = useCallback(
    async (payload) => {
      const callerId = payload.caller;
      const referredPatientId = payload.referredPatient;

      if (!callerId) {
        return;
      }

      if (
        callRef?.current?.callType === CALL_TYPES.UNSCHEDULED &&
        participants?.length > 0 &&
        participants[0]._id === callerId
      ) {
        return;
      }

      const sender = await getUser(callerId);
      const referredPatient = !!referredPatientId
        ? await getUser(referredPatientId)
        : null;
      updateNotifications((notifications) => [
        ...notifications,
        {
          id: generateNotificationId(),
          type: EVENTS.MEET_NOW,
          sender,
          referredPatient,
        },
      ]);
    },
    [callRef, participants]
  );

  const handleMessageReceive = useCallback(async (payload) => {
    if (![urls.CHAT, urls.CHAT_HISTORY].includes(history.location.pathname)) {
      const chatNotification = {
        id: generateNotificationId(),
        type: EVENTS.CHAT,
        sender: payload.sender,
        payload,
      };

      updateNotifications((notifications) => [
        ...notifications,
        chatNotification,
      ]);
    }
  }, []);

  const handleDismiss = (notification) => {
    updateNotifications(notifications.filter((elem) => elem !== notification));
  };

  useEffect(() => {
    meetNowSubscription.current?.unsubscribe();
    meetNowSubscription.current = subscribe(
      EVENTS.MEET_NOW,
      meetNowEventHandler
    );

    return () => {
      meetNowSubscription.current?.unsubscribe();
    };
  }, [callRef, participants]);

  useEffect(() => {
    const subscription = subscribe(EVENTS.CHAT, handleMessageReceive);

    return () => {
      subscription.unsubscribe();
    };
  }, [subscribe, handleMessageReceive]);

  return (
    <div>
      {notifications.map((notification) => (
        <Notification
          key={notification.id}
          notification={notification}
          handleDismiss={() => handleDismiss(notification)}
        />
      ))}
    </div>
  );
};

const styles = ({}) => `
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 10000;
  padding: 0;
`;

export default buildStyledComponent(
  'NotificationPopUps',
  styled(Notifications),
  styles
);
