import React from 'react';
import ReactSelect from 'react-select';
import { withTheme } from 'styled-components';

const customStyles = {
  control: (provided, { selectProps: { hasError, customTheme } }) => ({
    ...provided,
    borderColor: hasError
      ? customTheme.colors.danger
      : customTheme.colors.transparent,
  }),
};

export const Select = ({ hasError, theme, ...rest }) => {
  return (
    <ReactSelect
      hasError={hasError}
      customTheme={theme}
      styles={customStyles}
      {...rest}
    />
  );
};

export default withTheme(Select);
