import { Row, Column, Text } from '@gaz/gaz-components.public';

import { formatTitleAndName, getFullName } from 'utils/string';

const ReportCardItem = ({ provider, item }) => {
  const renderType = () => {
    switch (item.type) {
      case 'chat':
        return 'Message';
      case 'call':
        if (item.isPSTN) {
          return 'Audio';
        }
        return 'Video';
      case 'review':
        return 'Chart Review';
      case 'note':
        return 'Create Note';
      case 'careplan':
        return 'Create Careplan';
      default:
        return '';
    }
  };

  const renderParticipants = () => {
    if (['chat', 'call'].includes(item.type)) {
      return (
        <>
          <Row>
            <Column modifiers={['col_4']}>
              <Text modifiers={['xSmall']}>Participants:</Text>
            </Column>
            <Column modifiers={['col']}>
              {item.participants?.map((p) => (
                <Text modifiers={['xSmall', 'primary']} key={p._id}>
                  {formatTitleAndName(p)}
                </Text>
              ))}
            </Column>
          </Row>
          {item.referredPatient && (
            <Row>
              <Column modifiers={['col_4']}>
                <Text modifiers={['xSmall']}>Reference:</Text>
              </Column>
              <Column modifiers={['col']}>
                <Text modifiers={['xSmall', 'primary']}>
                  {getFullName(item.referredPatient)}
                </Text>
              </Column>
            </Row>
          )}
        </>
      );
    }

    return (
      <Row>
        <Column modifiers={['col_4']}>
          <Text modifiers={['xSmall']}>By:</Text>
        </Column>
        <Column modifiers={['col']}>
          <Text modifiers={['xSmall', 'primary']}>{getFullName(provider)}</Text>
        </Column>
      </Row>
    );
  };
  return (
    <>
      <Row>
        <Text modifiers={['small']} style={{ marginBottom: 5, marginTop: 5 }}>
          {renderType()}:
        </Text>
      </Row>
      {renderParticipants()}
    </>
  );
};

export default ReportCardItem;
