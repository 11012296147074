import styled from 'styled-components';
import { buildStyledComponent } from 'style';
import { Column as Col } from '@gaz/gaz-components.public';

const selectedCardStyles = () => `
  box-shadow: 0px 1px 2px rgba(46, 41, 78, 0.02), 0px 4px 8px rgba(46, 41, 78, 0.08);
  border-radius: 4px;
  height: 100%;
`;

export default buildStyledComponent(
  'SelectedParticipantCard',
  styled(Col),
  selectedCardStyles,
);
