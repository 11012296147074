import { get } from 'lodash';
import React, { useState } from 'react';

import { Input } from 'common';

import Label from '../FormLabel';
import Error from '../FormError';

export const FormInput = (props) => {
  const [focused, setFocused] = useState(false);
  const {
    field: { name, onBlur, onChange, value },
    form: { errors, touched, setFieldTouched },
    hasLabel,
    hasBorderBottom,
    asInfo,
    asGrayInfo,
    labelLightWeight,
    dark,
    smallFeedback,
    placeholder,
    required,
    innerRef,
    icon,
    noBorder,
    noPadding,
    fullWidth,
    hidden,
    ...inputProps
  } = props;

  const hasError = get(errors, name) && get(touched, name);
  const valid = !get(errors, name) && value;

  return (
    <div>
      {hasLabel && (
        <Label
          modifiers={[dark && 'dark', labelLightWeight && 'labelLightWeight']}
        >
          {placeholder} {required && <span>*</span>}
        </Label>
      )}
      <Input
        modifiers={[
          dark && 'dark',
          hasError && 'error',
          hasBorderBottom && 'borderBottomOnly',
          asInfo && 'asInfo',
          asGrayInfo && 'asGrayInfo',
          (valid || focused) && 'valid',
          noBorder && 'noBorder',
          noPadding && 'noPadding',
          hidden && 'hidden',
          props.disabled && 'disabled',
        ]}
        placeholder={hasLabel || props.disabled ? '' : placeholder}
        value={value}
        onChange={(e) => onChange(name)(e)}
        onBlur={() => {
          setFieldTouched(name);
          onBlur(name);
          setFocused(false);
        }}
        onFocus={() => {
          setFocused(true);
        }}
        ref={innerRef}
        {...inputProps}
      />
      {hasError && (
        <Error modifiers={[smallFeedback && 'small']}>
          {get(errors, name)}
        </Error>
      )}
    </div>
  );
};

export default FormInput;
