import styled from 'styled-components';
import { px2rem } from 'style';

import { buildStyledComponent } from 'style';

const styles = ({ theme }) => `
  height: ${px2rem(30)};
  border-radius: ${px2rem(15)};
  display: inline-block;
  background-color: ${theme.colors.white};
  border: solid ${px2rem(1)} rgb(223 223 223);
  box-shadow: rgb(223 223 223) 0 0 0 0 inset;
  position: relative;
  overflow: hidden;

  >div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 ${px2rem(8)};
    font-size: ${theme.dimensions.fontSizeSmall};

    >span:first-child {
      margin-right: ${px2rem(10)};
      visibility: hidden;
    }
  }

  >span {
    background-color: ${theme.colors.white};
    transition: all 200ms ease;
    width: ${px2rem(28)};
    height ${px2rem(28)};
    border-radius: 100%;
    box-shadow: rgb(0 0 0 / 40%) 0 ${px2rem(1)} ${px2rem(3)};
    display: block;
    position: absolute;
    left: ${px2rem(1)};
    top: 0;
  }
`;

const modifierConfig = {
  active: ({ theme }) => `
    >div {
      >span:first-child {
        visibility: visible;
      }
      >span:last-child {
        visibility: hidden;
      }
    }
    >span {
      background-color: ${theme.colors.blue};
      margin-left: calc(100% - ${px2rem(29)});
    }
  `,
};

const Wrapper = buildStyledComponent('Slider_Wrapper', styled.span, styles, {
  modifierConfig,
});

export default Wrapper;
