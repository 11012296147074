const urls = {
  AUTH: '/auth',
  ONBOARD: '/onboard',
  DASHBOARD: '/',
  TERMS: '/terms',
  CHAT: '/chat',
  CONTACTS: '/contacts',
  CONTACT: '/contacts/:id',
  PROFILE: '/profile',
  CARE_TEAM: '/care-team',
  CREATE_GROUP: '/create-group',
  RECORDS: '/records',
  MESSAGING: '/messaging',
  APPOINTMENTS: '/appointments',
  CHAT_HISTORY: '/chat-history',
  INVITATIONS: '/invitations',
  REPORTS: '/reports',
  TASKS: '/tasks',
  GROUP: '/groups/:id',
  PROGRESS: '/progress',
  SECURITY: '/security',
  NOTIFICATION: '/notification',
  PAYMENTMETHODS: '/payment-methods',
  ALERTS: '/alerts',
  JOIN_CALL: '/join-call',
};

export default urls;
