import assets from 'assets';
export const STATES = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'District of Columbia', abbreviation: 'DC' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
];

export const GENDERS = ['Male', 'Female', 'Unknown'];

export const STATUSES = ['Single', 'Married'];

export const SEARCH_DEBOUNCE_TIME = 500; // ms

export const GROUP_TYPES = {
  CARE_TEAM: 'CARE_TEAM',
  CONTACTS: 'CONTACTS',
};

export const VITALS = {
  heartRate: 'Heart Rate',
  bloodPressure: 'Blood Pressure',
  weight: 'Weight',
  glucose: 'Glucose',
  respiratory: 'Respiratory',
  bloodOxygen: 'Blood Oxygen',
  temperature: 'Temperature',
};

export const WELLNESS_ITEMS = {
  sleep: {
    title: 'Sleep',
    type: 'badGood',
    icon: assets.icons.icWellnessSleep,
  },
  breathing: {
    title: 'Breathing',
    type: 'badGood',
    icon: assets.icons.icWellnessBreathing,
  },
  energy: {
    title: 'Energy',
    type: 'badGood',
    icon: assets.icons.icWellnessEnergy,
  },
  memory: {
    title: 'Memory',
    type: 'badGood',
    icon: assets.icons.icWellnessMemory,
  },
  dizziness: {
    title: 'Dizziness',
    type: 'badGood',
    icon: assets.icons.icWellnessDizziness,
  },
  chestPain: {
    title: 'Chest Pain',
    type: 'badGood',
    icon: assets.icons.icWellnessChestPain,
  },
  woundHealing: {
    title: 'Wound Healing',
    type: 'badGood',
    icon: assets.icons.icWellnessWoundHealing,
  },
  swelling: {
    title: 'Swelling',
    type: 'badGood',
    icon: assets.icons.icWellnessSwelling,
  },
};

export const DIET_ITEMS = {
  salt: {
    title: 'Salt in Diet',
    type: 'highLow',
    icon: assets.icons.icDietSalt,
  },
  grains: {
    title: 'Grains in Diet',
    type: 'lowHigh',
    icon: assets.icons.icDietGrains,
  },
  grocery: {
    title: 'Grocery Shopping',
    type: 'lowHigh',
    icon: assets.icons.icDietGrocery,
  },
  cooking: {
    title: 'Home Cooking',
    type: 'lowHigh',
    icon: assets.icons.icDietCooking,
  },
  fruitsVeg: {
    title: 'Fruits and Vegetables',
    type: 'lowHigh',
    icon: assets.icons.icDietVegs,
  },
  fats: {
    title: 'Fats in meals',
    type: 'highLow',
    icon: assets.icons.icDietFat,
  },
  mealPortions: {
    title: 'Meal Portions',
    type: 'highLow',
    icon: assets.icons.icDietMeal,
  },
};

export const SCALE_TYPES = {
  badGood: 'Very bad to Very good',
  lowHigh: 'Very low to Very high',
  highLow: 'Very high to Very low',
};

export const CARE_PLANS_LABEL = {
  MEDICATION: {
    label: 'Medication',
    icon: assets.icons.iconMeds,
  },
  VITAL: {
    label: 'Vital',
    icon: '',
  },
  ACTIVITY: {
    label: 'Activity',
    icon: '',
  },
  WELLNESS: {
    label: 'Wellness',
    icon: '',
  },
  DIET: {
    label: 'Diet',
    icon: '',
  },
  FOLLOW_UP: {
    label: 'Follow up',
    icon: '',
  },
};

export const INVITE_ROLE_TYPES = {
  PROVIDER: 'providers',
  PATIENT: 'patients',
  GROUP: 'groups',
  PRACTICE: 'practices',
};

export const PROGRESS_ITEMS = {
  medication: {
    icon: assets.icons.icMedication,
    url: 'medication',
    title: 'Medication',
  },
  vital: { icon: assets.icons.icVital, url: 'vital', title: 'Vitals' },
  activity: {
    icon: assets.icons.icActivity,
    url: 'activity',
    title: 'Activity',
  },
  wellness: {
    icon: assets.icons.icWellness,
    url: 'wellness',
    title: 'Wellness',
  },
  diet: { icon: assets.icons.icDiet, url: 'diet', title: 'Diet' },
  appointment: {
    icon: assets.icons.icCareTeam,
    url: 'appointment',
    title: 'Appointments',
  },
};

export const MEMBER_DESIGNATIONS = {
  doctor: 'MD',
  do: 'DO',
  nursePractioner: 'NP/CNM/DNP',
  pa: 'PA',
  crna: 'CRNA',
  rn: 'RN',
  pharmacist: 'PharmD/RPh',
  other: 'Other Healthcare Professional',
};

export const DESKTOP_VIEWPORT_RATIO = 375 / 667;
export const DESKTOP_VIEWPORT_WIDTH = 100 * DESKTOP_VIEWPORT_RATIO + 'vh';

export const TIMER_TYPE = {
  MANUAL_PSTN: 'manualAudio',
  MANUAL_VIDEO: 'manualVideo',
  CALL: 'call',
  CHAT: 'chat',
  NOTE: 'note',
  CAREPLAN: 'careplan',
  ORDER_MEDS: 'orderMeds',
  REVIEW: 'review',
};
