import React from 'react';
import styled from 'styled-components';

import {
  buildStyledComponent,
  px2rem,
} from '@gaz/gaz-components.styled-builder';

const Component = ({
  image,
  imageDisabled,
  direction,
  children,
  width,
  height,
  imageWidth,
  imageHeight,
  type,
  disabled,
  ...inputProps
}) => {
  return (
    <button type={type || 'button'} {...inputProps} disabled={disabled}>
      {image && <img src={image} alt={inputProps.name} />}
      {children}
    </button>
  );
};

const modifierConfig = {
  primary: ({ theme }) => `
    background: ${theme.colors.primary};
    border-color: ${theme.colors.transparent};
    color: ${theme.colors.white};
    box-shadow: ${theme.dimensions.boxShadow_3};
    border-radius: ${px2rem(8)};
  `,
  normalFontSize: ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeNormal};
  `,
  secondary: ({ theme }) => `
    background: ${theme.colors.secondary};
    border-color: ${theme.colors.transparent};
    color: ${theme.colors.white};
  `,
  success: ({ theme }) => `
    background: ${theme.colors.success};
    border-color: ${theme.colors.transparent};
    color: ${theme.colors.white};
  `,
  lightBorder: () => `
    border: 1px solid #F3F3F4;
  `,
  roundCorner: ({ theme }) => `
    border-radius: ${theme.dimensions.borderRadius_3};
  `,
  success_2: ({ theme }) => `
    background: ${theme.colors.success_2};
    border-color: ${theme.colors.transparent};
    color: ${theme.colors.white};
  `,
  info: ({ theme }) => `
    background: ${theme.colors.info};
    border-color: ${theme.colors.transparent};
    color: ${theme.colors.white};
  `,
  danger: ({ theme }) => `
    background: ${theme.colors.danger};
    border-color: ${theme.colors.transparent};
    color: ${theme.colors.white};
  `,
  warning: ({ theme }) => `
    background: ${theme.colors.warning};
    border-color: ${theme.colors.transparent};
    color: ${theme.colors.text};
  `,
  light: ({ theme }) => `
    background: ${theme.colors.grey};
    border-color: ${theme.colors.transparent};
    color: ${theme.colors.darkGrey};
  `,
  textIcon: ({ theme }) => `
    >img {
      margin-right: ${px2rem(4)};
    }
  `,
  white: ({ theme }) => `
    background: ${theme.colors.white};
    border-color: ${theme.colors.transparent};
    color: ${theme.colors.info};
  `,
  outlinePrimary: ({ theme }) => `
    background: ${theme.colors.transparent};
    border-color: ${theme.colors.primary};
    color: ${theme.colors.primary};
  `,
  outlineSecondary: ({ theme }) => `
    background: ${theme.colors.transparent};
    border-color: ${theme.colors.secondary};
    color: ${theme.colors.secondary};
  `,
  outlineSuccess: ({ theme }) => `
    background: ${theme.colors.transparent};
    border-color: ${theme.colors.success};
    color: ${theme.colors.success};
  `,
  outlineInfo: ({ theme }) => `
    background: ${theme.colors.transparent};
    border-color: ${theme.colors.info};
    color: ${theme.colors.info};
  `,
  outlineDanger: ({ theme }) => `
    background: ${theme.colors.transparent};
    border-color: ${theme.colors.danger};
    color: ${theme.colors.danger};
  `,
  outlineWarning: ({ theme }) => `
    background: ${theme.colors.transparent};
    border-color: ${theme.colors.warning};
    color: ${theme.colors.warning};
  `,
  outlineLight: ({ theme }) => `
    background: ${theme.colors.transparent};
    border-color: ${theme.colors.darkGrey};
    color: ${theme.colors.darkGrey};
  `,
  small: ({ theme }) => `
    padding: .25rem .5rem;
    font-size: ${theme.dimensions.fontSizeSmall};
    line-height: 1.5;
  `,
  large: ({ theme }) => `
    padding: .5rem 1rem;
    font-size: ${theme.dimensions.fontSizeLarge};
    line-height: 1.5;
  `,
  block: () => `
    width: 100%;
  `,
  disabled: () => `
    opacity: 0.5;
    pointer-events: none;
  `,
  transparent: ({ theme }) => `
    border: none;
    background: ${theme.colors.transparent};
  `,
  icon: ({ theme, ...props }) => `
    padding: 0;
    flex-direction: ${props.direction || 'column'};
    font-size: ${theme.dimensions.fontSizeXSmall};
    color: ${theme.colors.text};
    width: ${px2rem(props.width || 42)};
    height: ${px2rem(props.height || 42)};
    box-shadow: none;

    >img {
      width: ${px2rem(props.imageWidth || 20)};
      height: ${px2rem(props.imageHeight || 20)};
    }
  `,
  round: ({ theme }) => `
    border-radius: 50%;
  `,
  topLeft: () => `
    display: block;
    position: absolute;
    left: 0;
  `,
  noShadow: () => `
    box-shadow: none;
  `,
  noPadding: () => `
    padding: 0;
  `,
  noCaps: () => `
    text-transform: unset;
  `,
  widthXSmall: ({ theme }) => `
    width: ${theme.dimensions.buttonWidthXSmall};
  `,
  widthSmall: ({ theme }) => `
    width: ${theme.dimensions.buttonWidthSmall};
  `,
  widthMedium: ({ theme }) => `
    width: ${theme.dimensions.buttonWidthMedium};
  `,
  fullWidth: () => `
    width: 100%;
  `,
  link: ({ theme }) => `
    background: ${theme.colors.transparent};
    font-size: ${px2rem(12)};
    color: ${theme.colors.link};
    box-shadow: none;
    border: none;
  `,
  colorPrimary: ({ theme }) => `
    color: ${theme.colors.primary};
  `,
  lightPrimary: ({ theme }) => `
    background-color: rgba(21, 101, 192, 0.1);
    color: ${theme.colors.text};
    border: none;
  `,
  textLeft: () => `
    text-align: left;
  `,
};

const styles = ({ theme }) => `
  cursor: pointer;
  display: flex;
  font-size: ${theme.dimensions.fontSize};
  font-weight: ${theme.fontWeights.bold};
  text-align: center;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 10px 13px -15px ${theme.colors.black};
  padding: .375rem .75rem;
  line-height: 1.5;
  border-radius: ${theme.dimensions.borderRadius_1};
  border: 1px solid ${theme.colors.transparent};
  white-space: nowrap;
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
`;

export const Button = buildStyledComponent(
  'Button',
  styled(Component),
  styles,
  {
    modifierConfig,
  }
);
