import styled from 'styled-components';

import { Row } from '@gaz/gaz-components.public';
import { buildStyledComponent } from 'style';

const styles = ({ theme }) => `
  background-color: ${theme.colors.white};
`;

export default buildStyledComponent('MainLayout_NavMenu', styled(Row), styles);
