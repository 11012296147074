import {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useHistory } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';

import {
  Text,
  Container,
  Column,
  Row,
  Button,
  BackButton,
} from '@gaz/gaz-components.public';

import { Avatar, DatePicker, Tabs } from 'common';
import { MainLayout } from 'layouts';
import { useLongPress } from 'utils/hooks';
import assets from 'assets';
import {
  FETCH_PATIENT_REPORT,
  FETCH_PATIENT_RESPONSES,
  FETCH_MONTHLY_PATIENT_READINGS,
} from 'graphql/queries';
import { loadingVar, reportPeriodVar } from 'graphql/cache';
import { displayTime, secondsToDuration } from 'utils/time';
import { AuthContext } from 'contexts/auth';

import ReportCard from './ReportCard';
import SharePane from './SharePane';
import Physiologics from './Physiologics';
import Therapeutics from './Therapeutics';
import TimeTable from './TimeTable';
import { getFullName } from 'utils/string';
import urls from 'routes/urls';

const { Header, Content } = MainLayout;

export default function Reports() {
  // const [sharePane, setSharePane] = useState(false);
  const history = useHistory();
  const { me } = useContext(AuthContext);
  const [selectedDate, updateSelectedDate] = useState(reportPeriodVar());
  const [tab, setTab] = useState(0);
  const backHandler = useRef();
  // const longPressCallback = () => setSharePane(true);
  // const handleCardLongPress = useLongPress({
  //   onLongPress: longPressCallback,
  //   ms: 400,
  // });
  const [fetchPatientReport, { data: { patientReport } = {} }] = useLazyQuery(
    FETCH_PATIENT_REPORT,
    {
      variables: {
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth() + 1,
      },
      fetchPolicy: 'no-cache',
    }
  );

  const [fetchReadings, { data: { monthlyPatientReadings } = {} }] =
    useLazyQuery(FETCH_MONTHLY_PATIENT_READINGS, {
      variables: {
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth() + 1,
      },
    });

  const [fetchPatientResponses, { data: { patientResponses } = {} }] =
    useLazyQuery(FETCH_PATIENT_RESPONSES);

  const handleChangeDate = useCallback((date) => {
    reportPeriodVar(date);
    updateSelectedDate(date);
  }, []);

  const goBack = () => {
    if (backHandler.current) {
      backHandler.current();
      backHandler.current = null;
      return;
    } else {
      history.replace(urls.DASHBOARD);
    }
  };

  useEffect(() => {
    if (me) {
      const startDate = moment(
        `${
          selectedDate.getMonth() + 1
        }/01/${selectedDate.getFullYear()} 00:00:00`,
        'MM/DD/YYYY hh:mm:ss'
      ).utcOffset(0, true);
      const endDate = startDate.clone().add(1, 'month');
      fetchReadings();
      fetchPatientReport();
      fetchPatientResponses({
        variables: {
          from: startDate.toDate(),
          to: endDate.toDate(),
        },
      });
    }
  }, [me, selectedDate]);

  const careplanList = useMemo(() => {
    const list = {};
    if (patientResponses?.careplans?.length > 0) {
      patientResponses.careplans.forEach(
        (careplan) => (list[careplan._id] = careplan)
      );
    }
    return list;
  }, [patientResponses]);

  return (
    <MainLayout hasFooter={false}>
      <Header>
        <Header.Left>
          <Row modifiers="middle">
            <BackButton onClick={goBack} />
          </Row>
        </Header.Left>
        <Header.Center>
          <Row modifiers={['middle']}>
            <Avatar user={me} width={40} height={40} modifiers={['squared']} />
            <Column modifiers={['col_8', 'flexBox']}>
              <Text modifiers={['semiBold', 'start']}>{getFullName(me)}</Text>
            </Column>
          </Row>
        </Header.Center>
      </Header>
      <Container modifiers={['backgroundWhite']}>
        <Row modifiers={['center', 'withSmallGutters']}>
          <DatePicker
            hideDates
            handleChange={handleChangeDate}
            value={selectedDate}
            maxDate={new Date()}
          >
            <Text modifiers={['primary']}>
              Period: {displayTime(selectedDate, 'MMM YYYY')}
            </Text>
          </DatePicker>
        </Row>
        <Tabs flatTabs multiLine current={tab} onChange={setTab}>
          <Tabs.Tab>
            <Row modifiers={['spaceBetween', 'center']}>
              <Text
                modifiers={[
                  'block',
                  'center',
                  'medium',
                  'mediumWeight',
                  'dark',
                ]}
              >
                Provider Time
              </Text>
              <Text modifiers={['center', 'large', 'block']}>
                {secondsToDuration(patientReport?.total || 0)}
              </Text>
            </Row>
          </Tabs.Tab>
          <Tabs.Tab>
            <Text
              modifiers={['block', 'center', 'medium', 'mediumWeight', 'dark']}
            >
              Therapeutic Readings
            </Text>
            <Text modifiers={['center', 'large', 'block']}>
              {monthlyPatientReadings?.therapeutic || 0}
            </Text>
          </Tabs.Tab>
          <Tabs.Tab>
            <Text
              modifiers={['block', 'center', 'medium', 'mediumWeight', 'dark']}
            >
              Physiologic Readings
            </Text>
            <Text modifiers={['center', 'large', 'block']}>
              {monthlyPatientReadings?.physiologic || 0}
            </Text>
          </Tabs.Tab>
        </Tabs>
      </Container>
      <Content style={{ marginBottom: 50, padding: 8 }}>
        {tab === 0 && <TimeTable reports={patientReport} />}
        {tab === 1 && (
          <Therapeutics
            responses={patientResponses?.responses}
            careplans={careplanList}
            backHandler={backHandler}
          />
        )}
        {tab === 2 && (
          <Physiologics
            responses={patientResponses?.responses}
            careplans={careplanList}
          />
        )}
      </Content>
      {/*
      <Content style={sharePane ? { marginBottom: 50 } : {}}>
        <Container>
          {patientReport?.records?.map((record) => (
            <ReportCard
              key={record._id}
              record={record}
              onLongPress={handleCardLongPress}
            />
          ))}
        </Container>
      </Content>
      <SharePane modifiers={[sharePane && 'show']}>
        <img
          onClick={() => setSharePane(false)}
          src={assets.icons.close}
          alt=""
          width="40"
          height="40"
        />
        <Button style={{ marginLeft: 'auto' }} modifiers={['primary', 'small']}>
          share
        </Button>
      </SharePane> */}
    </MainLayout>
  );
}
