import {
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { useHistory } from 'react-router';
import { useMutation, useLazyQuery } from '@apollo/client';

import { MainLayout } from 'layouts';
import {
  BackButton,
  Avatar,
  Row,
  Column,
  Text,
} from '@gaz/gaz-components.public';
import AlertsCategories from './PatientAlertsCategories';
import CategoryAlerts from './PatientCategoryAlerts';
import { FETCH_PATIENT_ALERTS } from 'graphql/queries';
import { BOOK_APPOINTMENT } from 'graphql/mutations';
import { EVENTS, SocketContext } from 'contexts/socket';
import { AuthContext } from 'contexts/auth';
import { getFullName } from 'utils/string';
import { loadingVar } from 'graphql/cache';
import urls from 'routes/urls';

const { Header, Content } = MainLayout;

export default () => {
  const history = useHistory();
  const { me } = useContext(AuthContext);
  const [alertsList, updateAlertsList] = useState(null);
  const [period, setPeriod] = useState('3m');
  const alertsListUpdateSubscription = useRef();
  const { subscribe } = useContext(SocketContext);
  const [selectedCategory, updateSelectedCategory] = useState(null);
  const [queryAlerts] = useLazyQuery(FETCH_PATIENT_ALERTS, {
    variables: { patient: me._id, period },
    onCompleted: (data) => {
      updateAlertsList(data.patientAlerts);
    },
    onError: () => {
      updateAlertsList([]);
    },
    fetchPolicy: 'no-cache',
  });

  const fetchAlerts = useCallback(() => {
    updateAlertsList(null);
    queryAlerts();
  }, [queryAlerts]);

  useEffect(() => {
    if (!!period) {
      fetchAlerts();
      alertsListUpdateSubscription.current?.unsubscribe();
      alertsListUpdateSubscription.current = subscribe(
        EVENTS.ALERTS_UPDATE,
        () => {
          fetchAlerts();
        }
      );
    }
    return () => {
      alertsListUpdateSubscription.current?.unsubscribe();
    };
  }, [me, period, fetchAlerts, subscribe]);

  const alertsCategories = useMemo(() => {
    const titles = ['vital', 'medication', 'activity', 'wellness', 'diet'];
    const categories = {};
    titles.forEach((title) => {
      categories[title] = {
        name: title,
        lastAlert: null,
        alerts: [],
        unseenCount: 0,
      };
    });
    alertsList?.forEach((alert) => {
      categories[alert.measure]?.alerts.push(alert);
      if (!categories[alert.measure].lastAlert) {
        categories[alert.measure].lastAlert = alert.triggerTime;
      }
      if (!alert.isSeen) {
        categories[alert.measure].unseenCount++;
      }
    });
    return Object.values(categories);
  }, [alertsList]);

  const [bookAppointment] = useMutation(BOOK_APPOINTMENT, {
    onCompleted: (appointment) => {
      goBack();
    },
  });

  const goBack = useCallback(() => {
    if (selectedCategory) {
      updateSelectedCategory(null);
    } else {
      history.replace(urls.DASHBOARD);
    }
  }, [selectedCategory]);

  const handleBook = async (provider, time) => {
    loadingVar(true);
    await bookAppointment({
      variables: {
        appointment: {
          provider: provider._id,
          patient: me._id,
          time,
        },
      },
    });
    loadingVar(false);
  };

  const handleAfterRead = (category) => {
    for (let alert of alertsList) {
      if (alert.measure !== category) {
        continue;
      }
      alert.isSeen = true;
    }
    updateAlertsList([...alertsList]);
  };

  if (!me) {
    return null;
  }

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton onClick={goBack} />
        </Header.Left>
        <Header.Center>
          <Row modifiers={['middle']}>
            <Avatar user={me} width={40} height={40} modifiers={['squared']} />
            <Column modifiers={['col_8', 'flexBox']}>
              <Text modifiers={['semiBold', 'start']}>{getFullName(me)}</Text>
            </Column>
          </Row>
        </Header.Center>
      </Header>
      <Content>
        {selectedCategory ? (
          <CategoryAlerts
            categoryAlerts={selectedCategory}
            handleBook={handleBook}
            me={me}
            handleAfterRead={handleAfterRead}
            goBack={goBack}
          />
        ) : (
          <AlertsCategories
            handleBook={handleBook}
            handleCategoryClick={(category) =>
              category.alerts?.length > 0 && updateSelectedCategory(category)
            }
            categories={alertsCategories}
            me={me}
            period={period}
            setPeriod={setPeriod}
            goBack={goBack}
          />
        )}
      </Content>
    </MainLayout>
  );
};
