import React from 'react';
import { useHistory } from 'react-router';

import { Row, Column, Container } from '@gaz/gaz-components.public';

import Alerts from './Alerts';
import Book from './Book';
import Contacts from './Contacts';
import Messaging from './Messaging';
import Records from './Records';
import Reports from './Reports';

export default function Shortcuts() {
  const history = useHistory();

  return (
    <Container style={{ padding: '0 0.25rem' }} modifiers={['fluid']}>
      <Row>
        <Column style={{ padding: '0.5rem 0.25rem' }} modifiers={['col_4']}>
          <Records />
        </Column>
        <Column style={{ padding: '0.5rem 0.25rem' }} modifiers={['col_4']}>
          <Reports />
        </Column>
        <Column style={{ padding: '0.5rem 0.25rem' }} modifiers={['col_4']}>
          <Alerts />
        </Column>
        <Column style={{ padding: '0.5rem 0.25rem' }} modifiers={['col_4']}>
          <Messaging />
        </Column>
        <Column style={{ padding: '0.5rem 0.25rem' }} modifiers={['col_4']}>
          <Book />
        </Column>
        <Column style={{ padding: '0.5rem 0.25rem' }} modifiers={['col_4']}>
          <Contacts />
        </Column>
      </Row>
    </Container>
  );
}
