import React from 'react';

import { Container, Row } from '@gaz/gaz-components.public';

import Wrapper from './Wrapper';
import Title from './Title';
import MenuButton from './MenuButton';
import Left from './Left';
import Center from './Center';
import Right from './Right';
import Bottom from './Bottom';

const Header = ({ children }) => {
  return (
    <Wrapper>
      <Row modifiers="middle">{children}</Row>
    </Wrapper>
  );
};

export default Header;

Header.MenuButton = MenuButton;
Header.Title = Title;
Header.Left = Left;
Header.Center = Center;
Header.Right = Right;
Header.Bottom = Bottom;
