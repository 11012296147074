import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';

import { Button, Column, Text, ScrollView } from '@gaz/gaz-components.public';
import styled from 'styled-components';

import { MedicationItem } from './Item';
import { Row, Container } from 'common';
import { displayTime } from 'utils/time';
import { formatTitleAndName, maskString, MASK_TYPE } from 'utils/string';
import assets from 'assets';
import { FETCH_ORDER } from 'graphql/queries';

import { loadingVar } from 'graphql/cache';
import { buildStyledComponent, px2rem } from 'style';

const InfoRow = ({ title, value, large }) => (
  <Row>
    <Column modifiers={[large ? 'col_6' : 'col_3', 'noPadding']}>
      <Text modifiers={['medium']}>{title}</Text>
    </Column>
    <Column modifiers={['col', 'noPadding']}>
      <Text modifiers={['medium', 'primary']}>{value}</Text>
    </Column>
  </Row>
);

const StatusButton = buildStyledComponent(
  'OrderStatusSelectButton',
  styled(({ status, handleShowModal, ...props }) => (
    <Container {...props} onClick={handleShowModal}>
      <Button modifiers={['roundCorner']}>{status}</Button>
      <img src={assets.icons.icChevronDown} />
    </Container>
  )),
  ({ theme, status }) => `
    width: fit-content;
    position: relative;
    padding: 0;

    > :first-child {
      width: ${px2rem(150)};
      text-transform: capitalize;
      background: ${theme.colors.orange};
      color: ${theme.colors.text};
    }

    > :last-child {
      position: absolute;
      right: ${px2rem(8)};
      top: ${px2rem(8)};
    }
  `
);

const OrderDetail = ({}) => {
  const { id } = useParams();
  const { data: { medicationOrder: order } = {}, loading } = useQuery(
    FETCH_ORDER,
    {
      variables: { id },
    }
  );

  loadingVar(loading);

  if (loading || !order) {
    return null;
  }

  const practice = order.provider.activeProviderPractice.practice;
  const provider = order.provider;
  const patient = order.patient;

  return (
    <Container modifiers={['fluid', 'withGutters', 'flexBox', 'fullHeight']}>
      <ScrollView modifiers={['noPadding']}>
        {order.status !== 'placed' && (
          <Container modifiers={['backgroundDarkGray']}>
            <Text modifiers={['white', 'bold', 'medium']}>
              {order.status === 'sent'
                ? `Sent to PMS: ${displayTime(
                    order.updatedAt,
                    'MM/DD/YY @ hh:mm a'
                  )}`
                : 'Status: shipped'}
            </Text>
          </Container>
        )}
        <Container modifiers={['padding_2']}>
          <Container modifiers={['section']}>
            <Text modifiers={['medium']}>Ordering Provider</Text>
            <Row modifiers={['topGutters_2']}>
              <Text modifiers={['block', 'medium']}>
                {formatTitleAndName(provider)}
              </Text>
            </Row>
            <Row>
              <Text modifiers={['medium']}>{practice.name}</Text>
            </Row>
            <Row>
              <Text
                modifiers={['primary', 'block', 'medium', 'preserveLineBreak']}
              >
                {`${practice.address.addressLine1}\n${practice.address.city}, ${practice.address.state} ${practice.address.zipcode}`}
              </Text>
            </Row>
            <InfoRow title="NPI:" value={provider.npi} />
            <InfoRow
              title="Phone:"
              value={provider.phones.mobile || provider.phones.work}
            />
            <InfoRow title="Fax:" value={provider.fax} />
            <InfoRow title="Email:" value={provider.email} />
          </Container>
          <Container modifiers={['section']}>
            <Text modifiers={['medium']}>Patient</Text>
            <Row modifiers={['topGutters_2']}>
              <Text modifiers={['block', 'medium']}>
                {formatTitleAndName(patient)}
              </Text>
            </Row>
            <Row>
              <Text
                modifiers={['primary', 'block', 'medium', 'preserveLineBreak']}
              >
                {`${patient.address.addressLine1}\n${patient.address.city}, ${patient.address.state} ${patient.address.zipcode}`}
              </Text>
            </Row>
            <InfoRow
              title="DoB:"
              value={displayTime(patient.dob, 'MM/DD/YYYY')}
            />
            <InfoRow
              title="SS#:"
              value={maskString(patient.ssn, MASK_TYPE.SSN)}
            />
            <InfoRow title="C:" value={patient.phones.mobile} />
            <InfoRow title="H:" value={patient.phones.home} />
            <InfoRow title="Email:" value={patient.email} />
          </Container>
        </Container>
        <Container modifiers={['backgroundDarkGray']}>
          <Text modifiers={['white', 'bold', 'medium']}>Order details</Text>
        </Container>
        <Container modifiers={['padding_2']}>
          <Container modifiers={['section']}>
            <InfoRow
              large
              title="Order date / time:"
              value={displayTime(order.createdAt, 'MM/DD/YY hh:mm a')}
            />
            <InfoRow
              large
              title="Order quantity:"
              value={Object.values(order.medications).length}
            />
            <InfoRow
              large
              title="Order#"
              value={order.orderNumber.toUpperCase()}
            />
            <Row modifiers={['withGutters']}>
              <Text modifiers={['block', 'medium']}>Order List</Text>
            </Row>
            {Object.values(order.medications).map((medication, index) => (
              <MedicationItem
                medication={medication}
                index={index + 1}
                key={index}
              />
            ))}
          </Container>
        </Container>
      </ScrollView>
    </Container>
  );
};

export default OrderDetail;
