import React from 'react';
import assets from 'assets';
import { Formik, Field, Form } from 'formik';
import { Row, Column, Container } from '@gaz/gaz-components.public';
import { TextArea, IconButton } from 'common';

import AttachmentPreview from './AttachmentPreview';

export default function ChatFooter({
  hasAttachment = false,
  attachment = {},
  onRemoveAttachment,
  handleOpenShareOptions,
  handleSubmit,
}) {
  const initialValues = {
    message: '',
  };

  const renderPreview = () => {
    if (hasAttachment) {
      return (
        <Row>
          <AttachmentPreview
            attachment={attachment}
            handleRemove={onRemoveAttachment}
            onSubmit={handleSubmit}
          />
        </Row>
      );
    }
    return null;
  };

  const handleKeyPress = (event, values, resetForm) => {
    if (!values.message && !attachment.url) return;

    if (event.key === 'Enter' && !event.shiftKey && values.message !== `\n`) {
      handleSubmit(values, { resetForm });
    }
  };

  const renderContent = () => {
    if (attachment.type !== 'application/pdf') {
      return (
        <Row>
          <Column modifiers={['col_2', 'flexBox', 'verticalCenter']}>
            <IconButton
              theme="light"
              flat
              icon={assets.icons.icChatPlus}
              size={32}
              onClick={handleOpenShareOptions}
            />
          </Column>
          <Column modifiers={['col_10', 'noPadding']}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({ values, handleSubmit, resetForm }) => (
                <Form>
                  <Container modifiers={['fluid']}>
                    <Row>
                      <Column modifiers={['col_10', 'noPadding']}>
                        <Field
                          modifiers={['smallContainer', 'lightBorder']}
                          name="message"
                          placeholder="Type a Message"
                          component={TextArea}
                          onKeyUp={(e) => handleKeyPress(e, values, resetForm)}
                        />
                      </Column>
                      <Column
                        modifiers={[
                          'col_2',
                          'flexBox',
                          'verticalContentCenter',
                        ]}
                      >
                        <IconButton
                          theme="light"
                          flat
                          disabled={!values.message && !attachment.url}
                          icon={assets.icons.icSendMessage}
                          size={32}
                          onClick={handleSubmit}
                        />
                      </Column>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          </Column>
        </Row>
      );
    }

    return null;
  };

  return (
    <>
      {renderPreview()}
      {renderContent()}
    </>
  );
}
