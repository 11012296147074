import React, { useEffect, useContext } from 'react';
import { useQuery } from '@apollo/client';
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

import { GET_LOADING, GET_ERROR, GET_SPLASH_LOADING } from 'graphql/queries';
import { AuthContext } from 'contexts/auth';
import { errorVar } from 'graphql/cache';
import { Loader, Popover } from '@gaz/gaz-components.public';
import ErrorPage from 'pages/ErrorPage';
import Call from 'pages/Call';
import Splash from 'pages/Splash';
import { saveData } from 'utils/storage';

import Wrapper from './Wrapper';

export const BaseLayout = ({ children }) => {
  const { fetchMe } = useContext(AuthContext);
  const { data: loadingData } = useQuery(GET_LOADING);
  const { data: splashLoadingData } = useQuery(GET_SPLASH_LOADING);
  const { data: errorData } = useQuery(GET_ERROR);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = qs.parse(location.search);
    const { invitationToken, email, ...restParams } = params;
    if (invitationToken) {
      saveData('token', invitationToken);
      fetchMe();
    }

    if (email) {
      saveData('email', email);
    }

    if (invitationToken || email) {
      const search = qs.stringify(restParams);
      location.search = search;
      history.replace(location);
    }
  }, [fetchMe, history, location]);

  const handleClearError = () => {
    errorVar(null);
  };

  if (errorData?.error?.status === 404) {
    return <ErrorPage />;
  }

  return (
    <Wrapper>
      <Wrapper.Notifications />
      {children}
      {loadingData?.loading && <Loader />}
      <Call />
      <Popover
        open={errorData?.error}
        title="Error"
        footer
        onClose={handleClearError}
      >
        {errorData?.error?.message}
      </Popover>
      {splashLoadingData?.splashLoading && <Splash />}
    </Wrapper>
  );
};

export default BaseLayout;
