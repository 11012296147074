import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import qs from 'query-string';

import { AuthContext } from 'contexts/auth';
import { uriFromPractice } from 'utils/helper';
import { loadData } from 'utils/storage';

import urls from './urls';

const AuthComp = ({ location, children }) => {
  const { me, loading, updateAuth } = useContext(AuthContext);

  if (!me || me.isTempAuth) {
    if (me?.isTempAuth) {
      updateAuth(null, null);
    }
    return (
      <Redirect
        to={{
          pathname: urls.AUTH,
          state: {
            from: location,
          },
        }}
      />
    );
  }

  if (!window.location.href.includes('localhost') && me.patientPractice) {
    const fulllUrl = window.location.href.replace(/(^\w+:|^)\/\//, '');
    const practiceUrl = uriFromPractice(me.patientPractice.name);
    const params = qs.parse(location.search);
    params['authToken'] = loadData('token');
    if (practiceUrl?.length > 0) {
      if (
        !fulllUrl.startsWith(`${practiceUrl}.${process.env.REACT_APP_BASE_URI}`)
      ) {
        window.location.replace(
          qs.stringifyUrl({
            url: `https://${practiceUrl}.${process.env.REACT_APP_BASE_URI}${location.pathname}`,
            query: params,
          })
        );
        return null;
      }
    } else if (!fulllUrl.startsWith(process.env.REACT_APP_BASE_URI)) {
      window.location.replace(
        qs.stringifyUrl({
          url: `https://${process.env.REACT_APP_BASE_URI}${location.pathname}`,
          query: params,
        })
      );
      return null;
    }
  }

  switch (me.status) {
    case 'new':
      if (location.pathname === urls.ONBOARD) {
        return children;
      } else {
        return <Redirect to={urls.ONBOARD} />;
      }
    case 'onboarded':
      if ([urls.ONBOARD].includes(location.pathname)) {
        return <Redirect to="/" />;
      }
      break;
    default:
  }

  return children;
};

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <AuthComp location={props.location}>
            <Component {...props} />
          </AuthComp>
        );
      }}
    />
  );
};

export default ProtectedRoute;
