import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import {
  Container,
  Row,
  Column,
  ScrollView,
  Button,
  Text,
  Checkbox,
  BackButton,
} from '@gaz/gaz-components.public';
import assets from 'assets';
import { buildStyledComponent, px2rem } from 'style';
import { AuthContext } from 'contexts/auth';
import { MainLayout } from 'layouts';
import { UPDATE_PATIENT } from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';

const { Header, Content } = MainLayout;

const Option = buildStyledComponent(
  'NotificationOption',
  styled(({ icon, text, onClick, selected, ...props }) => (
    <Row onClick={onClick} modifiers={['spaceBetween']} {...props}>
      <Column modifiers={'col'}>
        <Row>
          <img src={icon} alt="" />
          <Column modifiers={['col', 'noPadding']}>
            <Text modifiers={'block'}>{text}</Text>
          </Column>
        </Row>
      </Column>
      <Checkbox
        dark
        modifiers={['small', 'margin_1']}
        checked={selected}
        onChange={() => {}}
      />
    </Row>
  )),
  ({ theme }) => `
    padding: 0 ${px2rem(10)};
  `
);

const Notification = ({ history }) => {
  const { me: patient } = useContext(AuthContext);
  const [updatePatient, { loading }] = useMutation(UPDATE_PATIENT, {
    onCompleted: () => {
      history.goBack();
    },
  });
  const [notificationSelection, updateNotificationSelection] = useState({
    text: {},
    email: {},
    voice: {},
  });
  useEffect(() => {
    const { notifications } = patient;
    if (notifications) {
      const options = JSON.parse(JSON.stringify(notifications)) || {};
      if (!options.email) {
        options.email = {};
      }
      if (!options.text) {
        options.text = {};
      }
      if (!options.voice) {
        options.voice = {};
      }
      updateNotificationSelection(options);
    }
  }, [patient]);

  loadingVar(loading);

  const toggleOption = (key, option) => {
    const newNotification = { ...notificationSelection };
    newNotification[key][option] = !newNotification[key][option];
    updateNotificationSelection(newNotification);
  };

  const handleSave = () => {
    updatePatient({
      variables: {
        id: patient._id,
        patient: {
          notifications: notificationSelection,
        },
      },
    });
  };

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton />
        </Header.Left>
        <Header.Center>
          <Text modifiers={['semiBold']}>Notification</Text>
        </Header.Center>
        <Header.Right />
      </Header>
      <Content>
        <Container modifiers={['flexBox', 'fullHeight', 'fluid']}>
          <Container modifiers={['flexFixed']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Column modifiers={['col']}>
                <Row modifiers="smallGutters">
                  <Text modifiers={['muted', 'medium', 'block']}>
                    Select methods to notify patient
                  </Text>
                </Row>
              </Column>
            </Row>
          </Container>
          <Container
            modifiers={[
              'fullHeight',
              'flexOne',
              'flexBox',
              'fluid',
              'backgroundWhite',
            ]}
          >
            <ScrollView>
              <Container>
                <Text modifiers={['block', 'bold']}>SMS(text)</Text>
                <Option
                  icon={assets.icons.icSMS}
                  text={`mobile: ${patient.phones.mobile}`}
                  onClick={() => toggleOption('text', 'mobile')}
                  selected={!!notificationSelection.text?.mobile}
                />
                <Container modifiers={['fluid', 'withGutters']}>
                  <Text modifiers={['block', 'bold']}>Email</Text>
                  <Option
                    icon={assets.icons.icMail}
                    text={`email: ${patient.email}`}
                    onClick={() => toggleOption('email', 'email')}
                    selected={!!notificationSelection.email?.email}
                  />
                </Container>
                <Container modifiers={['fluid', 'withGutters']}>
                  <Text modifiers={['block', 'bold']}>Voice(Automated)</Text>
                  {patient.phones.home && (
                    <Option
                      icon={assets.icons.icPhone}
                      text={`home: ${patient.phones.home}`}
                      onClick={() => toggleOption('voice', 'home')}
                      selected={!!notificationSelection.voice?.home}
                    />
                  )}
                  {patient.phones.work && (
                    <Option
                      icon={assets.icons.icPhone}
                      text={`office: ${patient.phones.work}`}
                      onClick={() => toggleOption('voice', 'office')}
                      selected={!!notificationSelection.voice?.office}
                    />
                  )}
                  <Option
                    icon={assets.icons.icPhone}
                    text={`mobile: ${patient.phones.mobile}`}
                    onClick={() => toggleOption('voice', 'mobile')}
                    selected={!!notificationSelection.voice?.mobile}
                  />
                </Container>
              </Container>
            </ScrollView>
          </Container>
          <Container modifiers={['flexFixed', 'footer', 'backgroundWhite']}>
            <Row modifiers={['center']}>
              <Button
                modifiers={['widthMedium', 'roundCorner']}
                onClick={handleSave}
              >
                Save
              </Button>
            </Row>
          </Container>
        </Container>
      </Content>
    </MainLayout>
  );
};

export default Notification;
