import { useEffect, useState, useContext } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import { ADD_CAREPLAN_RESPONSE } from 'graphql/mutations';
import { FETCH_CAREPLAN_RESPONSE } from 'graphql/queries';
import { toGMT } from 'utils/time';
import { AuthContext } from '../auth';

const useResponse = () => {
  const { me } = useContext(AuthContext);
  const [callAddResponseApi] = useMutation(ADD_CAREPLAN_RESPONSE, {
    onCompleted: (data) => {
      if (data.addResponse?.responses) {
        updateResponses(data.addResponse.responses);
      }
    },
    onError: () => {},
  });
  const [loadResponseApi] = useLazyQuery(FETCH_CAREPLAN_RESPONSE, {
    onCompleted: (data) => {
      updateResponses(data.todayResponse?.responses);
    },
    onError: () => {},
  });
  const [responses, updateResponses] = useState([]);

  const addResponse = async (type, time, response) => {
    const responseData = {
      measure: type,
      time: toGMT(time),
      response,
    };
    await callAddResponseApi({
      variables: {
        date: toGMT(new Date(), true),
        response: responseData,
      },
    });
  };

  const fetchResponses = async () => {
    await loadResponseApi({
      variables: {
        date: toGMT(new Date(), true),
      },
    });
  };

  useEffect(() => {
    if (!me?._id) {
      updateResponses(null);
    } else {
      fetchResponses();
    }
  }, [me?._id]);

  return { responses, addResponse, fetchResponses };
};

export default useResponse;
