import React, { createContext } from 'react';
import { useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import qs from 'query-string';

import { saveData } from 'utils/storage';

export const AuthRedirectContext = createContext();

export const AuthRedirectProvider = ({ children }) => {
  const location = useLocation();
  const params = qs.parse(location.search);

  if (params?.authToken?.length > 0) {
    const authToken = params.authToken;
    delete params['authToken'];
    saveData('token', authToken);
    const searchString =
      Object.keys(params).length > 0 ? qs.stringify(params) : null;

    return (
      <AuthRedirectContext.Provider value={{}}>
        <Redirect to={{ pathname: location.pathname, search: searchString }} />
      </AuthRedirectContext.Provider>
    );
  }

  return (
    <AuthRedirectContext.Provider value={{}}>
      {children}
    </AuthRedirectContext.Provider>
  );
};

export default AuthRedirectProvider;
