import { useContext } from 'react';
import styled from 'styled-components';

import { Row, Container, Text, HR, Loader } from '@gaz/gaz-components.public';
import Appointment from './ListItem';
import { buildStyledComponent } from 'style';
import { PatientContext } from 'contexts/patient';

const Appointments = ({ ...props }) => {
  const { appointments } = useContext(PatientContext);

  return (
    <Container {...props}>
      {appointments?.length > 0 ? (
        appointments.map((appointment) => (
          <Container modifiers={['fluid']} key={appointment._id}>
            <Appointment appointment={appointment} />
            <HR modifiers={['colored', 'width_95%']} />
          </Container>
        ))
      ) : (
        <Row modifiers={['center']}>
          <Text modifiers={['large']}>No appointments</Text>
        </Row>
      )}
    </Container>
  );
};

export default buildStyledComponent(
  'Dashboard_Appointments',
  styled(Appointments),
  ({ theme }) => `
    > :last-child {
      > :last-child:not(:first-child) {
        display: none;
      }
    }
  `
);
