import styled from 'styled-components';
import { Container } from '@gaz/gaz-components.public';
import { buildStyledComponent } from '@gaz/gaz-components.styled-builder';

const styles = ({}) => `
  background-color: #F3F3F4;
  border-radius: 6px;
  max-height: 100%;
  overflow-y: auto;

  > * > *:not(:last-child) {
    border-right: 1px dashed #D2D2D2;
  }
`;

export default buildStyledComponent('Wrapper_Table', styled(Container), styles);
