import styled from 'styled-components';

import { buildStyledComponent } from 'style';
import { DESKTOP_VIEWPORT_WIDTH } from 'utils/constants';

const modifierConfig = {
  open: () => `
    transition 0.3s;
    transform: translateY(0%);
  `,
  noPadding: () => `
    padding: 0;
  `,
  fillHeight: () => `
    max-height: 95%;
  `,
  unsetHeight: () => `
    min-height: unset;
  `,
};

const styles = ({ theme }) => `
  transition 0.3s;
  transform: translateY(100%);
  border-radius: 24px 24px 0px 0px;
  background: ${theme.colors.white};
  width: 100%;
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  @media only screen and (min-width: 769px) {
    max-width: ${DESKTOP_VIEWPORT_WIDTH};
    margin: 0 auto;
  }
`;

export default buildStyledComponent('Wrapper_SlidingPane', styled.div, styles, {
  modifierConfig,
});
