import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';
import { Avatar } from '@gaz/gaz-components.public';
import assets from 'assets';

const modifierConfig = {
  bold: ({ theme }) => `
    font-weight: ${theme.fontWeights.bold};
  `,
  noPadding: ({ theme }) => `
    padding: 0;
  `,
  small: ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeSmall};
  `,
  medium: ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeMedium};
  `,
  borderTransparent: ({ theme }) => `
    border-color: ${theme.colors.transparent};
    outline: none;
  `,
  smallContainer: () => `
    max-height: ${px2rem(60)};
    min-height: ${px2rem(44)};
    padding-top: ${px2rem(8)};
    padding-bottom: ${px2rem(8)};
  `,
  lightBorder: ({ theme }) => `
    border: ${px2rem(1)} solid ${theme.colors.grey};
    border-radius: 50rem;
    display: flex;
    align-items: center;
    outline: none;
    padding-left: 20px;
    line-height: 1.2;
    &:focus {
      border-color: ${theme.colors.primary};
    }
  `,
  height_100: () => `
    height: ${px2rem(100)};
  `,
};

const styles = ({ theme }) => `
  padding: ${theme.dimensions.padding_1};
  color: ${theme.colors.text};
  border-radius: ${theme.dimensions.borderRadius_2};
  width: 100%;
  // height: ${px2rem(100)};
  border: solid ${px2rem(2)} ${theme.colors.grey};
  &:focus {
    border-color: ${theme.colors.primary};
  }
  margin: ${theme.dimensions.padding_1} 0;
  resize: none;
  font-size: ${px2rem(16)};
`;

const SimpleComponent = ({ field, value, form, innerRef, ...props }) => (
  <textarea
    value={field ? field.value : value}
    onChange={(e) => field && field.onChange(field.name)(e)}
    onBlur={() => {
      if (!field) {
        return;
      }
      form.setFieldTouched(field.name);
      field.onBlur(field.name);
    }}
    ref={innerRef}
    {...props}
  />
);

const AttachmentWrapper = buildStyledComponent(
  'TextArea_AttachmentWrapper',
  styled.div,
  ({ theme }) => `
  padding: ${theme.dimensions.padding_1};
  color: ${theme.colors.text};
  width: 100%;
  height: ${px2rem(100)};
  max-height: ${px2rem(44)};
  margin: ${theme.dimensions.padding_1} 0;
  resize: none;
  border: ${px2rem(1)} solid ${theme.colors.grey};
  border-radius: 50rem;
  display: flex;
  align-items: center;
  outline: none;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  line-height: 10px;
  &:focus {
    border-color: ${theme.colors.primary};
  }
  textarea {
    border: none;
    outline: none;
    padding: 0;
    flex: 1;
    resize: none;
    color: ${theme.colors.text};
  }
`
);

const HasAttachmentComp = ({ field, form, innerRef, ...props }) => (
  <AttachmentWrapper>
    <textarea
      value={field.value}
      onChange={(e) => field.onChange(field.name)(e)}
      onBlur={() => {
        form.setFieldTouched(field.name);
        field.onBlur(field.name);
      }}
      ref={innerRef}
    />
    <Avatar
      modifiers={['round']}
      image={assets.images.emptyAvatar}
      width={32}
      height={32}
    />
  </AttachmentWrapper>
);

const FinalComp = ({ hasAttachment, ...props }) =>
  hasAttachment ? (
    <HasAttachmentComp {...props} />
  ) : (
    <SimpleComponent {...props} />
  );

export const TextArea = buildStyledComponent(
  'TextArea',
  styled(FinalComp),
  styles,
  {
    modifierConfig,
  }
);

export default TextArea;
